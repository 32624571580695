import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { useNavigate } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './MainBanner.css';

const bannerData = [
  {
    id: 1,
    image: 'https://us-cal-sansara-restaurant.s3.us-west-1.amazonaws.com/hotel7/f45f665b-906b-4f84-a4fa-79b1b2339669_panna_cotta_recipe-41-of-43-1-1200x1200.webp',
    title: 'Panna Cotta',
    describe: 'Silky Italian dessert made from sweetened cream, set with gelatin...',
    style: {
      background: 'linear-gradient(135deg, #5D9AEA, #FF1480)',
      color: '#FFF'
    },
  },
  {
    id: 2,
    image: 'https://us-cal-sansara-restaurant.s3.us-west-1.amazonaws.com/hotel7/341ea6c4-5d76-45e7-9878-16b52a891064_Classic-Cob-Salad-Square-Featured-Photo-720x720.jpeg',
    title: 'Cobb Salad',
    describe: 'Mixed greens with chicken, bacon, avocado, eggs, and blue cheese',
    style: {
      background: 'linear-gradient(135deg,  #F98957,#FFA500)',
      color: '#FFF'
    },
  },
  {
    id: 3,
    image: 'https://us-cal-sansara-restaurant.s3.us-west-1.amazonaws.com/hotel7/140eeecb-5cc6-40fb-8e40-fb1debd3192c_The-Perfect-Rib-Eye-Steak-square.jpg',
    title: 'Grilled Ribeye Steak',
    describe: 'Juicy ribeye steak, grilled to perfection, served with garlic butter.',
    style: {
      background: 'linear-gradient(135deg, #5D9AEA, #69DEE5)',
      color: '#FFF'
    },
    route: '/basket'
  },
  {
    id: 4,
    image: 'https://us-cal-sansara-restaurant.s3.us-west-1.amazonaws.com/hotel7/ac4c4478-e641-4df8-881f-849cff720512_Mojito-Square-scaled.jpg',
    title: 'Mojito',
    describe: 'Rum, fresh mint, lime, sugar, and soda water, served over ice.',
    style: {
      background: 'linear-gradient(135deg, #F98957, #F4D291)',
      color: '#FFF'
    },
    route: '/basket'
  },
];

const MainBanner = () => {
  const navigate = useNavigate();

  return (
    <Swiper
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={15}
      slidesPerView={1.15}
      centeredSlides={true}
      loop={true}
      pagination={{ clickable: true }}
      className="banner-container"
      breakpoints={{
        355: {
          slidesPerView: 1.14,
        },
        360: {
          slidesPerView: 1.15,
        },
        480: {
          slidesPerView: 1.2,
        },
        768: {
          slidesPerView: 1.15,
        },
        820: {
          slidesPerView: 1.3,
        },
      }}
    >
      {bannerData.map((item) => (
        <SwiperSlide key={item.id}>
          {item.id === 1 ? (
            <div
              key={item.id}
              className="banner-item"
              style={item.style}
              onClick={() => navigate('/search?q=Panna+Cotta')}
            >
              <img src={item.image} alt={item.title} />
              <div className="banner-content">
                <h2>{item.title}</h2>
                <p>{item.describe}</p>
              </div>
            </div>
          ) : item.id === 2 ? (
            <div
              key={item.id}
              className="banner-item"
              style={item.style}
              onClick={() => navigate('/search?q=Cobb+Salad')}
            >
              <img src={item.image} alt={item.title} />
              <div className="banner-content">
                <h2>{item.title}</h2>
                <p>{item.describe}</p>
              </div>
            </div>
          ) : item.id === 3 ? (
            <div
              key={item.id}
              className="banner-item"
              style={item.style}
              onClick={() => navigate('/search?q=Grilled+Ribeye+Steak')}
            >
              <img src={item.image} alt={item.title} />
              <div className="banner-content">
                <h2>{item.title}</h2>
                <p>{item.describe}</p>
              </div>
            </div>
          ) : item.id === 4 ? (
            <div
              key={item.id}
              className="banner-item"
              style={item.style}
              onClick={() => navigate('/search?q=Mojito')}
            >
              <img src={item.image} alt={item.title} />
              <div className="banner-content">
                <h2>{item.title}</h2>
                <p>{item.describe}</p>
              </div>
            </div>
          ) : (
            <div
              key={item.id}
              className="banner-item"
              style={item.style}
              onClick={() => navigate(item.route)}
            >
              <img src={item.image} alt={item.title} />
              <div className="banner-content">
                <h2>{item.title}</h2>
                <p>{item.describe}</p>
              </div>
            </div>
          )}
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default MainBanner;
