import React from 'react'
import './ResB.css'
import { Link } from 'react-router-dom'
import Menucategory from '../MenuCatgory/Menucategory.jsx'
const ResB = () => {

  return (
    <div className='resb'>
      <Menucategory category='appetizer' />
      <Link to='/basket'>

      </Link>
    </div>
  )
}

export default ResB