import React, { useState, useEffect } from 'react';
import EditRestaurant from './EditRestaurant';
import AdminWakeUpCall from './AdminWakeUpCall';
import AdminSpaBooking from './AdminSpaBooking';
import AdminBed from './AdminBed';
import AdminCleaningHours from './AdminCleaningHours';
import AdminLaundry from './AdminLaundry';
const EditAdminService = ({ serviceData, onClose, handleSubmit, handleChange }) => {
  const [formData, setFormData] = useState(serviceData || {});

  useEffect(() => {
    setFormData(serviceData || {});
  }, [serviceData]);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    handleChange(name, value);
  };

  const handleClick = (inputName) => {
    document.getElementsByName(inputName)[0].focus();
  };

  const renderDynamicFields = () => {
    switch (formData.type) {
      case '1':
        return (
          <AdminBed
            itemsData={formData.items || []}
            onItemsChange={(items) => setFormData({ ...formData, items })}
            onClose={onClose}
          />
        );
      case '2':
        return (
          <AdminCleaningHours
            hoursData={formData.hours || []}
            onHoursChange={(hours) => setFormData({ ...formData, hours })}
            onClose={onClose}
          />
        );
      case '3':
        return (
          <AdminLaundry
            typesWeightsPricesData={{ types: formData.types || [], weights: formData.weights || [] }}
            onTypesWeightsPricesChange={(data) => setFormData({ ...formData, ...data })}
            onClose={onClose}
          />
        );
      case '4':
        return (
          <EditRestaurant serviceData={serviceData} onSubmit={handleSubmit} onClose={onClose} />

        );
      case '5':
        return (
          <AdminSpaBooking
            spaData={serviceData.spaData || {}}
            onSpaChange={handleSubmit}
            onClose={onClose}
          />

        );
      case '6':
        return (
          <AdminWakeUpCall
            wakeUpCallData={serviceData.wakeUpCallData || {}}
            onWakeUpCallChange={handleSubmit}
            onClose={onClose}
          />

        );
      default:
        return null;
    }
  };

  return (
    <div className="main-room">
      <div className="add-room-options">
        <div className='added-box'>
          <h3>Edit Service {formData.title}</h3>
          <button className="close" onClick={onClose}>×</button>
        </div>
        <div className="room-form edit">
          <form onSubmit={(e) => handleSubmit(e, formData)}>

            <div className="room-input-container cap1">
              <input
                type="text"
                name="notice"
                value={formData.notice || ''}
                onChange={handleInputChange}
                style={{ width: '100%' }}
                required
              />
              <label className={formData.notice ? 'floating' : ''}>
                Notice Box
              </label>
            </div>
            <div className="room-input-container">

              <select name="status" value={formData.status || 'ON'} onChange={handleInputChange} style={{ width: '100%' }}>
                <option value="ON">ON</option>
                <option value="OFF">OFF</option>
              </select>
              <label className={formData.status ? 'floating' : ''} onClick={() => handleClick('')}>
                Status
              </label>
            </div>

            {renderDynamicFields()}


          </form>
        </div>
      </div>
    </div>

  );
};

export default EditAdminService;
