import React, { useState, useRef, useMemo } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash/debounce';

const AddChat = ({ onClose }) => {
  const [lastName, setLastName] = useState('');
  const [room, setRoom] = useState('');
  const [roomOptions, setRoomOptions] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [guestName, setGuestName] = useState('');
  const [sending, setSending] = useState(false);
  const initialClickElement = useRef(null);
  const token = localStorage.getItem('hotelToken');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!roomOptions.some(option => option.roomNumber === room)) {
      setErrorMessage("This room doesn't exist");
      return;
    }

    try {
      setSending(true);

      const selectedRoom = roomOptions.find(option => option.roomNumber === room);
      const guestId = selectedRoom.id;

      const sendMessageDto = {
        hotelId: parseInt(localStorage.getItem('hotelId')),
        guestId: guestId,
        content: lastName,
        userType: 'HOTEL',
      };

      await axios.post(process.env.REACT_APP_API_BASE_URL + '/chat/send', sendMessageDto, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      navigate(`/chat/${guestId}`);
      onClose();
    } catch (error) {
      console.error('Failed to send message', error);
      setSending(false);
    }
  };

  const handleInputChange = (e, setState) => {
    setState(e.target.value);
  };

  const handleBackgroundMouseDown = (e) => {
    initialClickElement.current = e.target;
  };

  const handleBackgroundMouseUp = (e) => {
    if (e.target.className === 'main-room' && initialClickElement.current === e.target) {
      onClose();
    }
    initialClickElement.current = null;
  };

  const fetchRoomOptions = useMemo(() => debounce(async (value) => {
    if (value.length > 0) {
      try {
        const response = await axios.get(process.env.REACT_APP_API_BASE_URL + '/room/search', {
          headers: {
            Authorization: `Bearer ${token}`,
            hotelId: parseInt(localStorage.getItem('hotelId')),
          },
          params: {
            search: value,
          },
        });
        setRoomOptions(response.data);
        setShowDropdown(true);
      } catch (error) {
        console.error('Failed to fetch room options', error);
      }
    } else {
      setShowDropdown(false);
    }
  }, 500), [token, setRoomOptions, setShowDropdown]); 

  const handleRoomNumberChange = (e) => {
    const { value } = e.target;
    setRoom(value);
    fetchRoomOptions(value);
    setErrorMessage('');
  };

  const handleRoomSelect = async (selectedRoom) => {
    setRoom(selectedRoom.roomNumber);
    setShowDropdown(false);
    setErrorMessage('');

    try {
      const response = await axios.get(process.env.REACT_APP_API_BASE_URL + `/guests/search/room/${selectedRoom.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setGuestName(`${response.data.firstName} ${response.data.lastName}`);
    } catch (error) {
      console.error('Failed to fetch guest name', error);
    }
  };

  return (
    <div className="main-room" onMouseDown={handleBackgroundMouseDown} onMouseUp={handleBackgroundMouseUp}>
      <div className="add-room-options">
        <div className="added-box">
          <h3>Create New Chat</h3>
          <button className="close" onClick={onClose}>×</button>
        </div>
        <div className="room-form" style={{ paddingTop: '10px' }}>
          <form onSubmit={handleSubmit}>
            <div className="room-input-container cap1" style={{ position: 'relative' }}>
              <input
                type="text"
                name="room"
                value={room}
                onChange={handleRoomNumberChange}
                style={{ width: '100%' }}
                required
                onFocus={() => setShowDropdown(true)}
              />
              <label className={room ? 'floating' : ''}>Room Number</label>
              {showDropdown && roomOptions.length > 0 && (
                <ul className="dropdown-menu">
                  {roomOptions.map((room) => (
                    <li key={room.id} onClick={() => handleRoomSelect(room)}>
                      {room.roomNumber}
                    </li>
                  ))}
                </ul>
              )}
              {errorMessage && <p className="error-message">{errorMessage}</p>}
            </div>
            {guestName && (
              <div className="room-input-container">
                <input
                  type="text"
                  name="guestName"
                  value={guestName}
                  readOnly
                />
                <label className={guestName ? 'floating' : ''}>Guest Name</label>
              </div>
            )}
            <div className="room-input-container">
              <textarea
                type="text"
                name="lastName"
                value={lastName}
                onChange={(e) => handleInputChange(e, setLastName)}
                required
              />
              <label className={lastName ? 'floating' : ''}>Message</label>
            </div>

            <hr />
            <div className='button-bar'>
              <button type="submit" className='save-btn' disabled={sending}>
                {sending ? 'Sending...' : 'Send Message'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddChat;