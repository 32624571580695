import React from 'react'
import './Near.css'
import { FaStar } from "react-icons/fa";
import Arrow from '../../ArrowB/Arrow';
import { Link } from 'react-router-dom';
import aat from './aat.png'
import att from '../../Assets/All pics/attraction.png'
import todo from '../../Assets/All pics/todo.png'
import park from '../../Assets/All pics/park.png'
import others from '../../Assets/All pics/others.png'
import res from '../../Assets/All pics/resnear.png'
const Near = () => {
  const locations = [
    { name: 'Coliseum', rating: 4.8, distance: '3.2km' },
    { name: 'Location 2', rating: 4.5, distance: '5.0km' },
    { name: 'Location 3', rating: 4.2, distance: '2.5km' },
    { name: 'Location 4', rating: 4.7, distance: '4.0km' },
    { name: 'Location 5', rating: 4.9, distance: '1.8km' },
    { name: 'Location 5', rating: 4.9, distance: '1.8km' }
  ];
  return (
    <div className='near'>
      <Link to='/home' style={{ textDecoration: 'none', color: 'var(--primaryColor)' }} ><Arrow /></Link>
      <div className='main-row'>
        <div className='bottom-b'>
          <div className='row-a'>
            <img src={att} alt='attractions' />
            <h2>Attractions</h2>
          </div>
          <div className='row'>
            {locations.map((location, index) => (
              <div key={index} className='map-b'>
                <h3>{location.name}</h3>
                <div className='row-b'>
                  <p><FaStar className='star' />{location.rating}</p>
                  <p>{location.distance}</p>
                </div>
              </div>

            ))}
            <div></div>
          </div>
        </div>

        <div className='bottom-b'>
          <div className='row-a'>
            <img src={todo} alt='attractions' />
            <h2>Things To Do</h2>
          </div>
          <div className='row'>
            {locations.map((location, index) => (
              <div key={index} className='map-b'>
                <h3>{location.name}</h3>
                <div className='row-b'>
                  <p><FaStar className='star' />{location.rating}</p>
                  <p>{location.distance}</p>
                </div>
              </div>

            ))}
            <div></div>
          </div>
        </div>

        <div className='bottom-b'>
          <div className='row-a'>
            <img src={res} alt='attractions' />
            <h2>Restaurants</h2>
          </div>
          <div className='row'>
            {locations.map((location, index) => (
              <div key={index} className='map-b'>
                <h3>{location.name}</h3>
                <div className='row-b'>
                  <p><FaStar className='star' />{location.rating}</p>
                  <p>{location.distance}</p>
                </div>
              </div>

            ))}
            <div></div>
          </div>
        </div>

        <div className='bottom-b'>
          <div className='row-a'>
            <img src={park} alt='attractions' />
            <h2>Parks</h2>
          </div>
          <div className='row'>
            {locations.map((location, index) => (
              <div key={index} className='map-b'>
                <h3>{location.name}</h3>
                <div className='row-b'>
                  <p><FaStar className='star' />{location.rating}</p>
                  <p>{location.distance}</p>
                </div>
              </div>

            ))}
            <div></div>
          </div>
        </div>

        <div className='bottom-b'>
          <div className='row-a'>
            <img src={aat} alt='attractions' />
            <h2>Attractions</h2>
          </div>
          <div className='row'>
            {locations.map((location, index) => (
              <div key={index} className='map-b'>
                <h3>{location.name}</h3>
                <div className='row-b'>
                  <p><FaStar className='star' />{location.rating}</p>
                  <p>{location.distance}</p>
                </div>
              </div>

            ))}
            <div></div>
          </div>
        </div>

        <div className='bottom-b'>
          <div className='row-a'>
            <img src={others} alt='attractions' />
            <h2>Others</h2>
          </div>
          <div className='row'>
            {locations.map((location, index) => (
              <div key={index} className='map-b'>
                <h3>{location.name}</h3>
                <div className='row-b'>
                  <p><FaStar className='star' />{location.rating}</p>
                  <p>{location.distance}</p>
                </div>
              </div>

            ))}
            <div></div>
          </div>
          <div className='extra'></div>
      <div className='extra'></div>
      <div className='extra1'></div>
      
        </div>

      </div>
      

    </div>
  )
}

export default Near