import React, { useState } from 'react';
const AdminBed = ({ itemsData, onItemsChange, onClose }) => {
  const [items, setItems] = useState(itemsData || []);

  const handleItemChange = (e, index) => {
    const { name, value } = e.target;
    const newItems = items.slice();
    newItems[index] = { ...newItems[index], [name]: value };
    setItems(newItems);
    onItemsChange(newItems);
  };

  const handleAddItem = () => {
    const newItems = [...items, { name: '' }];
    setItems(newItems);
    onItemsChange(newItems);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    onItemsChange(items);
  };
  const handleClick = (inputName) => {
    document.getElementsByName(inputName)[0].focus();
  };

  return (


    <form onSubmit={handleFormSubmit} style={{ width: '100%', marginTop: '10px' }}>
      <div className='row-box1 ' >
        {items.map((item, index) => (

          <div key={index} className="room-input-container">
            <input
              type="text"
              name="name"
              value={item.name}
              onChange={(e) => handleItemChange(e, index)}
              style={{ width: '100%' }}
              required
            />
            <label
              htmlFor={`item${index}`}
              className={item.name ? 'floating' : ''}
              onClick={() => handleClick(`item${index}`)}
            >
              Types
            </label>

          </div>
        ))}
        <button type="button" onClick={handleAddItem}>+</button>
      </div>


      <hr />
      <div className="button-bar">
        <button type="submit" onClick={handleFormSubmit} className="save-btn">Save</button>
        <button type="button" onClick={onClose} className="delete-button">Cancel</button>
      </div>


    </form>

  );
};

export default AdminBed