import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import ImageCropper from './ImageCropper';
const placeholderImage = 'https://us-cal-sansara-restaurant.s3.us-west-1.amazonaws.com/hotel7/default_food.jpg';

const EditDish = ({ item, onClose, onEditItem, onDeleteItem }) => {
  const token = localStorage.getItem('hotelToken');
  const [formData, setFormData] = useState({
    id: item.id,
    title: item.title,
    description: item.description,
    category: item.category,
    price: item.price,
    currency: item.currency,
    photo: item.imageLink,
    availability: item.available,
    recommended: item.recommended,
    originalPhoto: item.imageLink,
    croppedPhoto: item.imageLink,
  });
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const initialClickElement = useRef(null);
  const [isDeleting, setIsDeleting] = useState(false); // New state to prevent duplicate requests
  const [loading, setLoading] = useState(false);
  const [showCropModal, setShowCropModal] = useState(false);
  const [cropImage, setCropImage] = useState(null);
 

  useEffect(() => {
    console.log('FormData changed', formData);
  }, [formData]);


  const handleChange = (e) => {
    const { name, value } = e.target;
   // Limit title to 15 words
  if (name === 'title') {
    const wordCount = value.split(/\s+/).filter(Boolean).length;
    if (wordCount <= 22) {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  }

  // Limit description to 160 characters
  if (name === 'description') {
    if (value.length <= 160) {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  }

  // Handle other inputs normally
  if (name !== 'title' && name !== 'description') {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }
};
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setCropImage(reader.result);
        setShowCropModal(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDeleteImage = () => {
    setFormData((prevData) => ({
      ...prevData,
      photo: null,
      newPhotoFile: null,
    }));
  };

 

 
 

  const handleCropComplete = async (croppedImageUrl, croppedImageBlob) => {
    if (croppedImageBlob) {
      setFormData((prevData) => ({
        ...prevData,
        photo: croppedImageUrl,
        newPhotoBlob: croppedImageBlob,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        photo: image, // If no cropping, keep the original image
      }));
    }
    setShowCropModal(false);
  };
  
  const handleCropCancel = () => {
    setShowCropModal(false);
    setCropImage(null);
  };

  
  const handleEditImage = () => {
    setCropImage(formData.photo);
    setShowCropModal(true);
  };
 
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const updatedItem = {
      id: formData.id,
      title: formData.title,
      description: formData.description,
      category: formData.category,
      price: parseFloat(formData.price),
      currency: formData.currency,
      available: parseInt(formData.availability, 10),
      recommended: parseInt(formData.recommended, 10),
      orderNumber: item.orderNumber,
    };

    const formDataToSend = new FormData();
    formDataToSend.append('foodDto', JSON.stringify(updatedItem));
    if (formData.newPhotoBlob) {
      formDataToSend.append('file', formData.newPhotoBlob, 'image.jpg');
    } else if (formData.photo === null) {
      formDataToSend.append('deleteImage', 'true');
    }else {
      // If no new photo blob, append the current photo link
      formDataToSend.append('file', formData.photo);
    }
    
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/restaurant/update`,
        formDataToSend,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      const updatedImageLink = formData.photo === null ? placeholderImage : (response.data.imageLink || formData.photo);
      onEditItem({ ...updatedItem, imageLink: updatedImageLink });
      onClose();
    } catch (error) {
      console.error('Failed to update food item', error);
    } finally {
      setLoading(false);
    }
  };
  const handleDelete = async () => {
    if (isDeleting) return;
    setIsDeleting(true);
    setLoading(true);

    try {
      await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/restaurant/${formData.id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      onDeleteItem(formData.id);
      onClose();
    } catch (error) {
      console.error('Failed to delete food item', error);
    } finally {
      setIsDeleting(false);
      setLoading(false);
    }
  };
  const handleConfirmDelete = () => {
    console.log('Opening confirm delete modal');
    setShowConfirmModal(true);
  };

  const handleCancelDelete = () => {
    console.log('Cancel delete action');
    setShowConfirmModal(false);
  };

  const handleConfirmYes = () => {
    console.log('Confirming delete action');
    handleDelete();
    setShowConfirmModal(false);
  };
 const handleClick = (inputName) => {
    document.getElementsByName(inputName)[0].focus();
  };

  const handleBackgroundMouseDown = (e) => {
    initialClickElement.current = e.target;
  };

  const handleBackgroundMouseUp = (e) => {
    if (e.target.className === 'main-room' && initialClickElement.current === e.target) {
      onClose();
    }
    initialClickElement.current = null;
  };
  return (
    <div className="main-room" onMouseDown={handleBackgroundMouseDown} onMouseUp={handleBackgroundMouseUp}>
      <div className="add-room-options">
        <div className="added-box">
          <h3>Edit Dish</h3>
          <button className="close" onClick={onClose}>×</button>
        </div>
        <div className="room-form m2 edit">
          <form onSubmit={handleSubmit}>
            <div className="room-input-container">
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleChange}
                required
                style={{ width: '100%' }}
              />
              <label
                className={formData.title ? 'floating' : ''}
                onClick={() => handleClick('title')}
              >
                Title
              </label>
            </div>
            <div className="room-input-container ">
              <input
                type="text"
                name="description"
                value={formData.description}
                onChange={handleChange}
                required
                style={{ width: '100%' }}
              />
              <label
                className={formData.description ? 'floating' : ''}
                onClick={() => handleClick('description')}
              >
                Description
              </label>
            </div>

            <div className="room-input-container ">
              <input
                type="text"
                name="price"
                value={formData.price}
                onChange={handleChange}
                required
                style={{ width: '100%' }}
              />
              <label
                className={formData.price ? 'floating' : ''}
                onClick={() => handleClick('price')}
              >
                Price
              </label>
            </div>

            <div className="room-input-container">
              <select
                name="currency"
                value={formData.currency}
                onChange={handleChange}
                required
              >
                <option value="USD">USD</option>
                <option value="AED">AED</option>
              </select>
              <label
                className={formData.currency ? 'floating' : ''}
                onClick={() => handleClick('currency')}
              >
                Currency
              </label>
            </div>

            <div className="room-input-container">
              <select
                name="category"
                value={formData.category}
                onChange={handleChange}
                required
              >
                <option value="Appetizer">Appetizer</option>
                <option value="Fast Food">Fast Food</option>
                <option value="Drinks">Drinks</option>
                <option value="Alcohol">Alcohol</option>
                <option value="Main Courses">Main Courses</option>
                <option value="Salads">Salads</option>
                <option value="Desserts">Desserts</option>
                <option value="Soups">Soups</option>
              </select>
              <label
                className={formData.category ? 'floating' : ''}
                onClick={() => handleClick('category')}
              >
                Category
              </label>
            </div>

            <div className="room-input-container ">
              <select
                name="recommended"
                value={formData.recommended}
                onChange={handleChange}
                required
              >
                <option value="0">No</option>
                <option value="1">Yes</option>
              </select>
              <label className={formData.recommended !== '' ? 'floating' : ''} onClick={() => handleClick('recommended')}>Recommended Label</label>
            </div>

            <div className="room-input-container">
              <select
                name="availability"
                value={formData.availability}
                onChange={handleChange}
                required
              >
                <option value="1">Available</option>
                <option value="0">Not Available</option>
              </select>
              <label className={formData.availability !== '' ? 'floating' : ''} onClick={() => handleClick('availability')}>Availability</label>
            </div>

            <div className="image-section">
          {formData.photo && (
             <div className="itemcon" style={{ position: 'relative' }}>
              <img src={formData.photo} alt="Dish" onClick={handleEditImage} className='shareimg'/>
              <button
                    type="button"
                    className="delete-image-button"
                    onClick={handleDeleteImage}
                  >
                    &ndash;
                  </button>
              
            </div>
          )}
             <label htmlFor="file-input" className="file-input-label">
                {formData.photo ? 'Change Image' : 'Add Image'}
              </label>
          <input
            type="file"
            onChange={handleFileChange}
            name="photo"
            accept="image/*"
            id="file-input"
            className="file-input"
          />
        </div>
            <hr />
            <div className="button-bar">
              <button type="button" className="delete-button" onClick={handleConfirmDelete}>Delete</button>
              <button type="submit" className='save-btn'>{loading ? 'Saving...' : 'Save'}</button>
            </div>
          </form>
        </div>
      </div>

      {showConfirmModal && (
        <div className="modal" onClick={handleCancelDelete}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h3>Are you sure you want to delete this dish?</h3>
            <div className="button-bar">
              <button onClick={handleConfirmYes} className="delete-button">{loading ? 'Deleting...' : 'Yes'}</button>
              <button onClick={handleCancelDelete} className="save-btn">No</button>
            </div>
          </div>
        </div>
      )}
       {showCropModal && (
        <ImageCropper
          image={cropImage}
          onCropComplete={handleCropComplete}
          onCancel={handleCropCancel}
        />
      )}
    </div> 
  );
};

export default EditDish;
