import React, { useState, useEffect } from 'react';
import './Food.css';
import { useCart } from '../Cart/CartContext.jsx';

const Foods = (props) => {
  const { cart, cartAdd, handleQuantityChange, fetchCart } = useCart();
  const [count, setCount] = useState(props.quantityInCart || 0);
  const [isInCart, setIsInCart] = useState(count > 0);

  useEffect(() => {
    const cartItem = cart.find(item => item.foodDto.id === props.id);
    if (cartItem) {
      setCount(cartItem.itemQuantity);
      setIsInCart(true);
    } else {
      setCount(0);
      setIsInCart(false);
    }
  }, [cart, props.id, props.quantityInCart]);

  const handleAddToCart = async () => {
    const result = await cartAdd({ id: props.id, quantity: 1 });
    if (result) {
      setCount(1);
      setIsInCart(true);
    }
  };

  const handleIncrement = async () => {
    const result = await handleQuantityChange(props.id, 1);
    if (result) {
      await fetchCart();
      const updatedCartItem = cart.find(item => item.foodDto.id === props.id);
      setCount(updatedCartItem ? updatedCartItem.itemQuantity : count + 1);
    }
  };

  const handleDecrement = async () => {
    const result = await handleQuantityChange(props.id, -1);
    if (result) {
      await fetchCart();
      const updatedCartItem = cart.find(item => item.foodDto.id === props.id);
      if (updatedCartItem) {
        setCount(updatedCartItem.itemQuantity);
      } else {
        setCount(0);
        setIsInCart(false);
      }
    }
  };

  return (
    <div className='foo'>
      <div className='food-container'>
        <div key={props.id} className='foodi'>
          <div className="image-container">
            {props.recommended === 1 && (
              <p className="recommended-label">Recommended</p>
            )}
            <img src={props.image} alt={props.title} />
          </div>
          <div className='food-text'>
            <h2>{props.title}</h2>
            <p>{props.des}</p>
            <div className="price-and-button">
              <h3>${props.price}</h3>
              
              {isInCart ? (
                
                <div className='Big-icon'>
                  <button onClick={handleDecrement} className="quantity-button f1">-</button>
                  <span>{count}</span>
                  <button onClick={handleIncrement} className="quantity-button">+</button>
                </div>
              ) : (
                <button onClick={handleAddToCart} className="add-to-cart-button">
                  <span className="circle-plus-icon f1">+</span>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Foods;