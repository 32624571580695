import React, { useState, useEffect } from 'react';
import './Box.css';
import Arrow from '../ArrowB/Arrow';
import { Link } from 'react-router-dom';
import { FaStar } from "react-icons/fa";
import axios from 'axios';
import ContentLoader from 'react-content-loader';

const Loader = () => (
<div className="acti-container" style={{marginTop:"-20px"}}>
    {['Tours', 'Dining', 'Cruises'].map((category, idx) => (
      <div key={idx} className={`category-section ${category === 'Tours' ? 'tours-section' : ''}`}>
        <ContentLoader 
          viewBox="0 0 400 50" 
          height={50} 
          width="100%"
        >
          {/* Category title */}
          <rect x="0" y="10" rx="4" ry="4" width="150" height="20" />
        </ContentLoader>
        
        <div className={`activity-list ${category === 'Tours' ? 'tours-list' : ''}`}>
          {[1].map((item, index) => (
            <div key={index} className="activity-item">
              {category === 'Tours' ? (
                // Tours layout - Side by side
                <ContentLoader 
                  viewBox="0 0 800 200" 
                  height={200} 
                  width="100%"
                  style={{marginTop:"-25px"}}
                >
                  {/* Image placeholder */}
                  <rect x="0" y="0" rx="8" ry="8" width="300" height="200" />
                  
                  {/* Text Container */}
                  <rect x="320" y="0" rx="4" ry="4" width="400" height="24" />
                  <rect x="320" y="50" rx="3" ry="3" width="450" height="16" />
                  <rect x="320" y="75" rx="3" ry="3" width="450" height="16" />
                  <rect x="320" y="100" rx="3" ry="3" width="400" height="16" />
                  
                  {/* Price */}
                  <rect x="320" y="140" rx="4" ry="4" width="120" height="24" />
                  
                  {/* Rating */}
                  <rect x="650" y="140" rx="4" ry="4" width="100" height="24" />
                </ContentLoader>
              ) : (
                // Other categories - Vertical layout
                <ContentLoader 
                  viewBox="0 0 800 500" 
                  height={500} 
                  width="100%"
                >
                   {/* Full width image placeholder with top margin */}
                   <rect x="0" y="40" rx="8" ry="8" width="800" height="300" />
                  
                  {/* Text Container below image */}
                  <rect x="20" y="360" rx="4" ry="4" width="600" height="24" />
                  <rect x="20" y="400" rx="3" ry="3" width="700" height="16" />
                  <rect x="20" y="425" rx="3" ry="3" width="650" height="16" />
                  
                  {/* Price and Rating in bottom row */}
                  <rect x="20" y="460" rx="4" ry="4" width="120" height="24" />
                  <rect x="680" y="460" rx="4" ry="4" width="100" height="24" />
                </ContentLoader>
              )}
            </div>
          ))}
        </div>
      </div>
    ))}
  </div>
);

const Box = () => {
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchActivities = async () => {
      const hotelId = localStorage.getItem('hotelId');
      const token = localStorage.getItem('guestToken');

      if (hotelId && token) {
        try {
          const response = await axios.get(process.env.REACT_APP_API_BASE_URL + `/activity/list`, {
            headers: {
              'Authorization': `Bearer ${token}`,
              hotelId: hotelId,
            },
            params: {
              tag: 'hotel',
              page: 0,
              size: 10
            }
          });

          setActivities(response.data);
        } catch (error) {
          console.error('Error fetching activities:', error);
        }finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
     
    };

    fetchActivities();
  }, []);

  const categoriesOrder = [
    "Tours",
    "Dining",
    "Cruises",
    "Outdoor",
    "Sport",
    "Theme Park",
    "Museum",
    "Cutural Experience",
    "Others"
  ];

  const getMinPrice = (additionalOptions) => {
    const prices = [];
    if (additionalOptions?.price?.private) {
      prices.push(parseFloat(additionalOptions.price.private));
    }
    if (additionalOptions?.price?.pickup) {
      prices.push(parseFloat(additionalOptions.price.pickup));
    }
    return prices.length > 0 ? Math.min(...prices) : 'N/A';
  };

  const sortedActivities = activities.sort((a, b) => {
    const categoryAIndex = categoriesOrder.indexOf(a.category);
    const categoryBIndex = categoriesOrder.indexOf(b.category);
    return categoryAIndex - categoryBIndex;
  });

  const categories = [...new Set(sortedActivities.map(item => item.category))];

  return (
    <div className='box-con'>
      <Link to='/home' style={{ textDecoration: 'none', color: 'var(--primaryColor)' }}><Arrow /></Link>
      <div className='acti-container'>
      {loading ? ( // Show loader if loading
          <Loader />
        ) : (
          categories.map(category => {
            const categoryActivities = sortedActivities.filter(item => item.category === category);
            return (
              <div key={category} className={`category-section ${category === 'Tours' ? 'tours-section' : ''}`}>
              <h2 className='titlet'>{category}</h2>
              <div className={`activity-list ${category === 'Tours' ? 'tours-list' : ''}`}>
               
                  {categoryActivities.map((item) => (
                    <Link
                      to={`/activities/${item.id}`}
                      key={item.id}
                      className='activity-item'
                      style={{ textDecoration: 'none', color: 'var(--primaryColor)' }}
                      state={{ activity: item }}
                    >
                      <img src={item.images[0].url} alt="Activity" />
                      <div className='text-container1'>
                        <h2>{item.title}</h2>
                        <p className='des-acti'>
                          {item.description.length > 40
                            ? `${item.description.substring(0,40)}...`
                            : item.description
                          }
                        </p>
                        <div className='pr'>
                          <p>
                            Start From ${getMinPrice(item.additionalOptions)}
                          </p>
                          <div className='rating-container'>
                            <FaStar className='star' />
                            <span className='pr1'>{item.rating}</span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            );
          })
        )}
      </div>
      <div className='extra'></div>
      <div className='extra'></div>
    </div>
  );
}

export default Box;