import React, { useState } from 'react';
import Swal from 'sweetalert2';
import alarm from './alarmp.png';
import './SerShow.css';
import checked from '../../Assets/All pics/checked_169780 1.png';
import axios from 'axios';
import { useOrderContext } from '../../Cart/OrderContext';

const WakeupService = ({ service, onSave }) => {
  const { handlePlaceOrder } = useOrderContext();
  const [selectedHour, setSelectedHour] = useState(new Date().getHours() % 12 || 12);
  const [selectedMinute, setSelectedMinute] = useState(new Date().getMinutes());
  const [selectedPeriod, setSelectedPeriod] = useState(new Date().getHours() >= 12 ? 'PM' : 'AM');
  const [selectedDate, setSelectedDate] = useState(new Date().toLocaleDateString('en-US', { month: 'numeric', day: 'numeric' }));

  const currentDate = new Date();
  const today = currentDate.toLocaleDateString('en-US', { month: 'numeric', day: 'numeric' });
  const tomorrowDate = new Date(currentDate);
  tomorrowDate.setDate(currentDate.getDate() + 1);
  const tomorrow = tomorrowDate.toLocaleDateString('en-US', { month: 'numeric', day: 'numeric' });

  const convertTo12HourFormat = (hour) => {
    if (hour === 0) return { hour: 12, period: 'AM' };
    if (hour === 12) return { hour: 12, period: 'PM' };
    if (hour > 12) return { hour: hour - 12, period: 'PM' };
    return { hour, period: 'AM' };
  };

  const handleHourChange = (event) => {
    setSelectedHour(parseInt(event.target.value, 10));
  };

  const handleMinuteChange = (event) => {
    setSelectedMinute(parseInt(event.target.value, 10));
  };

  const handlePeriodChange = (event) => {
    setSelectedPeriod(event.target.value);
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const isTimeInPast = (hour, minute, period) => {
    if (selectedDate === tomorrow) return false;

    let selectedHour24 = period === 'PM' && hour !== 12 ? hour + 12 : hour;
    if (period === 'AM' && hour === 12) selectedHour24 = 0;

    if (selectedHour24 < new Date().getHours()) return true;
    if (selectedHour24 === new Date().getHours() && minute < new Date().getMinutes()) return true;

    return false;
  };

  const handleSave = async () => {
    if (isTimeInPast(selectedHour, selectedMinute, selectedPeriod)) {
      Swal.fire({
        title: 'Invalid Time',
        text: 'The selected time is in the past. Please choose a future time.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
      return;
    }

    Swal.fire({
      title: 'Confirm Order',
      text: `Are you sure you want to place the order?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
      customClass: {
        confirmButton: 'custom-confirm-button',
        cancelButton: 'custom-cancel-button',
      },
      buttonsStyling: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const formattedDate = `${new Date().getFullYear()}/${selectedDate}`;
        const formattedTime = `${String(selectedHour).padStart(2, '0')}:${String(selectedMinute).padStart(2, '0')} ${selectedPeriod}`;

        const timeToSend = `${formattedDate} ${formattedTime}`;

        const token = localStorage.getItem('guestToken');
        const hotelId = localStorage.getItem('hotelId');
        const guestId = localStorage.getItem('id');

        try {
          const response = await axios.post(process.env.REACT_APP_API_BASE_URL + '/order/place-service', {
            totalAmount: 0,
            totalQuantity: 1,
            orderStatus: "Pending",
            title: "Wake Up Call",
            additionalOptions: {
              time: timeToSend,
            }
          }, {
            headers: {
              'accept': '*/*',
              'hotelId': hotelId,
              'guestId': guestId,
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          });

          if (response.status === 200) {
            handlePlaceOrder();
            Swal.fire({
              title: 'Order Placed',
              html: `<div>You can track the status of your order in the orders section</div>`,
              confirmButtonText: 'Close',
              iconHtml: `<img src="${checked}" style="width: 60px; height: 60px; border: none; box-shadow: none; border-radius: 0;"/>`,
              customClass: {
                icon: 'custom-icon',
                confirmButton: 'custom-confirm-button',
              },
              buttonsStyling: false,
            }).then(() => {
              setSelectedHour(new Date().getHours() % 12 || 12);
              setSelectedMinute(new Date().getMinutes());
              setSelectedPeriod(new Date().getHours() >= 12 ? 'PM' : 'AM');
              setSelectedDate(new Date().toLocaleDateString('en-US', { month: 'numeric', day: 'numeric' }));
            });
          } else {
            console.error('Error placing order:', response.status, response.statusText);
            Swal.fire({
              title: 'Error',
              text: 'Failed to set wake-up call. Please try again later.',
              icon: 'error',
              confirmButtonText: 'OK',
            });
          }
        } catch (error) {
          console.error('Error placing order:', error);
          Swal.fire({
            title: 'Error',
            text: 'Failed to set wake-up call. Please try again later.',
            icon: 'error',
            confirmButtonText: 'OK',
          });
        }
      }
    });
  };

  const generateHours = () => {
    const hours = [];
    for (let i = 1; i <= 12; i++) {
      const { hour: hour12, period } = convertTo12HourFormat(i);
      if (selectedDate === today) {
        if (period === selectedPeriod && hour12 < selectedHour) continue;
      }
      hours.push(i);
    }
    return hours;
  };

  const generateMinutes = () => {
    const minutes = [];
    for (let i = 0; i < 60; i++) {
      if (selectedDate === today && selectedHour === (new Date().getHours() % 12 || 12) && selectedPeriod === selectedPeriod && i < selectedMinute) {
        continue;
      }
      minutes.push(i);
    }
    return minutes;
  };

  return (
    <div>
      <div className="title-box">
        <h3>{service.name}</h3>
        <div className="center-text">
          <p>Please choose when you want to wake up.</p>
        </div>
      </div>
      <div className="bed-box1">
        <img src={alarm} alt="alarm" className="alarm" />
        <div className="time">
          <div className="time-picker1">
            <label>Select Date:</label>
            <select value={selectedDate} onChange={handleDateChange}>
              <option value="" disabled>Select Date</option>
              <option value={today}>{today}</option>
              <option value={tomorrow}>{tomorrow}</option>
            </select>
          </div>
          <div className="time-picker1">
            <label>Select Time Here:</label>
            <div className="time-picker">
              <select value={selectedHour} onChange={handleHourChange}>
                <option value="" disabled>Hour</option>
                {generateHours().map((hour) => (
                  <option key={hour} value={hour}>
                    {String(hour).padStart(2, '0')}
                  </option>
                ))}
              </select>
              :
              <select value={selectedMinute} onChange={handleMinuteChange}>
                <option value="" disabled>Minute</option>
                {generateMinutes().map((minute) => (
                  <option key={minute} value={minute}>
                    {String(minute).padStart(2, '0')}
                  </option>
                ))}
              </select>
              <select value={selectedPeriod} onChange={handlePeriodChange}>
                <option value="AM">AM</option>
                <option value="PM">PM</option>
              </select>
            </div>
          </div>
        </div>

        <button
          className={`bed-button ${!selectedHour || !selectedMinute || !selectedDate ? '' : 'active'}`}
          disabled={!selectedHour || !selectedMinute || !selectedDate}
          onClick={handleSave}
        >
          Submit For Wake-up Call
        </button>
      </div>
    </div>
  );
};

export default WakeupService;