import React, { createContext, useContext, useState, useCallback, useEffect } from "react";
import { addToCart, getCartItems } from '../Cart/CartService';

const CartContext = createContext(null);

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [count, setCount] = useState(1);
  const [orderPlaced, setOrderPlaced] = useState(false);
  const [orderStatus] = useState('Pending');

  const fetchCart = useCallback(async () => {
    const guestToken = localStorage.getItem('guestToken');
    const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';

    if (guestToken === 'null' || !isLoggedIn) {
      return;
    }

    try {
      const cartData = await getCartItems();
      if (cartData) {
        setCart(cartData.cartItemsDto || []);
        setTotalAmount(cartData.totalAmount || 0);
        setTotalQuantity(cartData.totalQuantity || 0);
      } else {
        setCart([]);
        setTotalAmount(0);
        setTotalQuantity(0);
      }
    } catch (error) {
      console.error('Error fetching cart data:', error);
      setCart([]);
      setTotalAmount(0);
      setTotalQuantity(0);
    }
  }, []);

  useEffect(() => {
    fetchCart();
  }, [fetchCart]);

  const cartAdd = async (food) => {
    try {
      const result = await addToCart(food.id, food.quantity);
      if (result) {
        fetchCart();
      }
      return result;
    } catch (error) {
      console.error('Error adding to cart:', error);
      return null;
    }
  };

  const handleQuantityChange = async (id, amount) => {
    try {
      const result = await addToCart(id, amount);
      if (result) {
        fetchCart();
        return result;
      }
      return null;
    } catch (error) {
      console.error('Error changing quantity:', error);
      return null;
    }
  };

  const removeFromCart = (id) => {
    setCart((prevCart) => prevCart.filter((item) => item.foodDto.id !== id));
    fetchCart();
  };

  const getTotalItems = () => {
    return totalQuantity;
  };

  const getTotalAmount = () => {
    const formattedTotal = totalAmount.toFixed(2);
    const finalTotal = formattedTotal.endsWith('.00') ? formattedTotal.split('.')[0] : formattedTotal;
    return finalTotal;
  };

  const clearCart = () => {
    setCart([]);
    setTotalAmount(0);
    setTotalQuantity(0);
  };

  const handleLastNameSubmit = () => {
    fetchCart();
  };

  return (
    <CartContext.Provider value={{ count, setCount, cart, setCart, totalAmount, totalQuantity, cartAdd, handleQuantityChange, removeFromCart, getTotalItems, getTotalAmount, clearCart, orderPlaced, setOrderPlaced, orderStatus, fetchCart, handleLastNameSubmit }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => useContext(CartContext);