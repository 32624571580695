import React, { useState } from 'react';
import Swal from 'sweetalert2';
import './SerShow.css';
import checked from '../../Assets/All pics/checked_169780 1.png';
import axios from 'axios';
import { useOrderContext } from '../../Cart/OrderContext';

const LaundryService = ({ service, onSave }) => {
  const { handlePlaceOrder } = useOrderContext();
  const [selectedItems, setSelectedItems] = useState({});
  const [totalPrice, setTotalPrice] = useState(0);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedWeight, setSelectedWeight] = useState('');
  const [additionalNotes, setAdditionalNotes] = useState('');

  const handleItemChange = (itemName, operation) => {
    setSelectedItems(prevItems => {
      const currentQty = prevItems[itemName] || 0;
      const newQty = operation === 'increment' ? currentQty + 1 : Math.max(currentQty - 1, 0);
      const itemPrice = service.additionalOptions[itemName].price;
      const newTotalPrice = operation === 'increment' ? totalPrice + itemPrice : totalPrice - itemPrice;
      setTotalPrice(newTotalPrice);

      if (newQty === 0) {
        const { [itemName]: _, ...rest } = prevItems;
        return rest;
      }

      return { ...prevItems, [itemName]: newQty };
    });
  };

  const handleSave = async () => {
    const hasAdditionalItems = Object.keys(selectedItems).length > 0;
    const hasTypes = selectedTypes.length > 0;
    const hasWeight = selectedWeight !== '';

    if (!hasAdditionalItems && !hasTypes && !hasWeight) {
      Swal.fire({
        title: 'Error',
        text: 'Please select at least one option.',
        icon: 'error',
        confirmButtonText: 'Close'
      });
      return;
    }

    let confirmationText = '';
    if (hasTypes) {
      confirmationText += `Types: ${selectedTypes.join(', ')}. `;
    }
    if (hasWeight) {
      confirmationText += `Weight: ${selectedWeight} kg. `;
    }
    if (hasAdditionalItems) {
      const additionalDetails = Object.entries(selectedItems)
        .map(([item, qty]) => `${item}: ${qty}`)
        .join(', ');
      confirmationText += `Additional Items: ${additionalDetails}.`;
    }

    Swal.fire({
      title: 'Confirm Order',
      text: `Are you sure you want to place the order?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
      customClass: {
        confirmButton: 'custom-confirm-button',
        cancelButton: 'custom-cancel-button'
      },
      buttonsStyling: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const placeServiceOrderDto = {
          totalAmount: totalPrice,
          totalQuantity: Object.values(selectedItems).reduce((sum, qty) => sum + qty, 0),
          orderStatus: "Pending",
          title: "Laundry",
          additionalOptions: { 
            clothes: selectedItems,
            notes: additionalNotes 
          }
        };

        const token = localStorage.getItem('guestToken');
        const hotelId = localStorage.getItem('hotelId');
        const guestId = localStorage.getItem('id');

        try {
          const response = await axios.post(process.env.REACT_APP_API_BASE_URL + '/order/place-service', placeServiceOrderDto, { 
            headers: {
              'accept': '*/*',
              'hotelId': hotelId,
              'guestId': guestId,
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          });

          if (response.status === 200) {
            handlePlaceOrder();
            Swal.fire({
              title: 'Order Placed',
              html: `<div>You can track the status of your order in the orders section</div>`,
              confirmButtonText: 'Close',
              iconHtml: `<img src="${checked}" style="width: 60px; height: 60px;"/>`,
              customClass: {
                icon: 'custom-icon',
                confirmButton: 'custom-confirm-button'
              },
              buttonsStyling: false,
            }).then(() => {
              setSelectedItems({});
              setSelectedTypes([]);
              setSelectedWeight('');
              setTotalPrice(0);
              setAdditionalNotes(''); 
            });
          } else {
            console.error('Error placing order:', response.status, response.statusText);
            Swal.fire({
              title: 'Error',
              text: 'Failed to place order. Please try again later.',
              icon: 'error',
              confirmButtonText: 'OK',
            });
          }
        } catch (error) {
          console.error('Error placing order:', error);
          Swal.fire({
            title: 'Error',
            text: 'Failed to place order. Please try again later.',
            icon: 'error',
            confirmButtonText: 'OK',
          });
        }

        onSave(service.id, { types: selectedTypes, weight: selectedWeight, additionalItems: selectedItems });
      }
    });
  };

  const isButtonDisabled = totalPrice === 0 && selectedTypes.length === 0 && selectedWeight === '';

  return (
    <div className='con-lan'>
    
        <h3 className='title-box'>{service.name}</h3>
        <p className='lan-p'>{service.description}</p>


      {service.additionalOptions && (
        <div className='breakfast-con'>
        
          <label className='br-title'>
            <h3>Types of Clothes</h3>
          </label>
          <div className='laundry'>
            {Object.entries(service.additionalOptions).map(([itemName, { price }], index) => (
              <div key={index}>
                <div className='laundry-row'>
                  <div className="item-info">
                    <p>{itemName}</p>
                    <p className='price'>${price}</p>
                  </div>
                  <div className="quantity-control">
                    {selectedItems[itemName] ? (
                      <div className='Big-icon'>
                        <button onClick={() => handleItemChange(itemName, 'decrement')} className="quantity-button">-</button>
                        <span>{selectedItems[itemName]}</span>
                        <button onClick={() => handleItemChange(itemName, 'increment')} className="quantity-button">+</button>
                      </div>
                    ) : (
                      <button onClick={() => handleItemChange(itemName, 'increment')} className="add-to-cart-button">
                        <span className="circle-plus-icon">+</span>
                      </button>
                    )}
                  </div>
                </div>
                <hr />
              </div>
            ))}
          </div>
          <div className='additional-notes'>
            <label htmlFor="notes">Additional Notes:</label>
            <textarea 
              id="notes" 
              placeholder="Enter any additional instructions..."
              value={additionalNotes} 
              onChange={(e) => setAdditionalNotes(e.target.value)} 
            />
             <div className='extra'></div>
             <div className='extra1'></div>
          </div>
         
         
        </div>
      )}

      <div className='overlay-lan'>
        <div className="price-row">
          <p>Total: </p>
          <p>${totalPrice.toFixed(2)}</p>
        </div>
        <div className='button-row'>
          <button className={`bed-button ${isButtonDisabled ? '' : 'active'}`} disabled={isButtonDisabled} onClick={handleSave}>
            Place Order
          </button>
        </div>
      </div>
    </div>
  );
};

export default LaundryService;