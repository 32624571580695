import React, { useState, useEffect, useMemo } from 'react';

const RoomTypeSelect = ({ value, onChange, label }) => {
  const roomTypes = useMemo(() => ['Single', 'Double', 'Twin', 'Triple', 'Quadruple', 'Deluxe',
    'Studio', 'Junior', 'Queen', 'King', 'Executive', 'Presidential', 'Penthouse'], []);
  const [isCustom, setIsCustom] = useState(!roomTypes.includes(value));
  const [customType, setCustomType] = useState(isCustom ? value : '');

  useEffect(() => {
    setIsCustom(!roomTypes.includes(value));
    setCustomType(isCustom ? value : '');
  }, [value, roomTypes, isCustom]);

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    if (selectedValue === 'custom') {
      setIsCustom(true);
      onChange({ target: { name: 'type', value: '' } });
    } else {
      setIsCustom(false);
      onChange({ target: { name: 'type', value: selectedValue } });
    }
  };

  const handleCustomTypeChange = (e) => {
    const customValue = e.target.value;
    setCustomType(customValue);
    onChange({ target: { name: 'type', value: customValue } });
  };

  return (
    <div className="date-picker-container">
      <select
        value={isCustom ? 'custom' : (value || '')}
        onChange={handleSelectChange}
        name="type"
        className="room-type"
      >
        <option value="" disabled>Select Room Type</option>
        {roomTypes.map((type) => (
          <option key={type} value={type}>{type}</option>
        ))}
        <option value="custom">Custom</option>
      </select>
      {isCustom && (
        <div className="room-input-container" style={{  width: '100%', margin:' 5px 0px 10px'}}>
          <input
            style={{padding:'18px'}}
            type="text"
            name="type"
            value={customType}
            onChange={handleCustomTypeChange}
            required
          />
          <label className={customType ? 'floating' : ''}>Custom Room Type</label>
        </div>
      )}
      <label className={value ? 'floating' : ''}>{label}</label>
    </div>
  );
};

export default RoomTypeSelect;