import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useCart } from '../../../../Component/Cart/CartContext.jsx';
import './LoginRoom.css';
import ContentLoader from 'react-content-loader';
const RoomLoginLoader = () => {
  return (
   
      <div className="login-room-container">
      <div className="intro-section show">
        <div className="intro-content">
          <svg className="animate-spin h-12 w-12" viewBox="0 0 24 24">
            <circle 
              className="opacity-25" 
              cx="12" 
              cy="12" 
              r="10" 
              stroke="currentColor" 
              strokeWidth="4"
              fill="none"
            />
            <path 
              className="opacity-75" 
              fill="currentColor" 
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
        </div>
      </div>
    </div>
  );
}
const LoginRoom = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { handleLastNameSubmit: cartHandleLastNameSubmit } = useCart();

  const [roomLoginData, setRoomLoginData] = useState(null);
  const [error, setError] = useState('');
  const [showIntro, setShowIntro] = useState(true);
  const [showWelcome, setShowWelcome] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const roomNumber = params.get('roomNumber');
    const hotelId = params.get('hotelId');

    const storedToken = localStorage.getItem(`roomToken-${roomNumber}`);

    if (storedToken) {
      navigate(`/login-guest?token=${storedToken}`);
    } else if (roomNumber && hotelId) {
      axios.get(process.env.REACT_APP_API_BASE_URL + `/login-room?hotelId=${hotelId}&roomNumber=${roomNumber}`)
        .then(response => {
          setRoomLoginData(response.data);
          setTimeout(() => {
            setShowIntro(false);
            setShowWelcome(true);
            setTimeout(() => {
              handleAutomaticLogin(response.data);
            }, 2000);
          }, 3800);
        })
        .catch(error => {
          console.error('Room authentication failed', error);
          setError('Room authentication failed. Please try again.');
        });
    } else {
      navigate('/login');
    }
  }, [location, navigate]);

  const handleAutomaticLogin = (data) => {
    if (!data) {
      setError('Room login data is not available.');
      return;
    }

    axios.get(process.env.REACT_APP_API_BASE_URL + `/login-guest?token=${data.guestToken}`)
      .then(response => {
        const guestData = response.data;
        localStorage.setItem(`roomToken-${guestData.room}`, data.guestToken);
        localStorage.setItem('guestToken', guestData.guestToken);
        localStorage.setItem('firstName', guestData.firstName);
        localStorage.setItem('lastName', guestData.lastName);
        localStorage.setItem('id', guestData.id);
        localStorage.setItem('hotelId', guestData.hotelId);
        localStorage.setItem('hotelName', guestData.hotelName);
        localStorage.setItem('checkIn', guestData.checkIn);
        localStorage.setItem('checkOut', guestData.checkOut);
        localStorage.setItem('room', guestData.room);
        localStorage.setItem('breakfast', guestData.breakfast);
        localStorage.setItem('guestsNumber', guestData.guestsNumber);
        localStorage.setItem('isLoggedIn', 'true');

        cartHandleLastNameSubmit();

        navigate('/home', { state: { transition: 'slide-right' } }); // Add transition state
      })
      .catch(error => {
        console.error('Guest authentication failed', error);
        setError('Authentication failed. Please try again.');
      });
  };

  if (!roomLoginData) {
    return <RoomLoginLoader />;
  }

  const hiddenLastName = '*'.repeat(roomLoginData.lastName.length);

  return (
    <div className="login-room-container">
      <div className={`intro-section ${showIntro ? 'show' : 'hide'}`}>
        <div className="intro-content">
          <h1 className="luxury-text"> Welcome {roomLoginData.firstName}</h1>
          <p className="luxury-subtext">Experience comfort and style with <br/>{roomLoginData.hotelName}</p>
        </div>
      </div>
    </div>
  );
};

export default LoginRoom;