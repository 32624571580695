import React, { useState, useRef, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { IoCheckmarkDoneOutline } from "react-icons/io5";
import { IoMdCheckmark } from "react-icons/io";
import Arrow from '../../ArrowB/Arrow';
import Image from './message.png';
import { BiSend } from 'react-icons/bi';
import './Message.css';
import chat from './chatstart.png';
import ContentLoader from 'react-content-loader';
const MessageListLoader = () => (
    <div className="messagelist">
    <ContentLoader 
      speed={2}
      width="100%"
      height={600}
      className="messagelist"
    >
      {/* Message container for received messages */}
      <rect x="20" y="20" rx="8" ry="8" width="60%" height="60" className="message-container received" />
      <circle cx="18" cy="100" r="15" className="message-icon" /> 
      
      {/* Message container for sent messages */}
      <rect x="40%" y="140" rx="8" ry="8" width="55%" height="60" className="message-container sent" />
      
      {/* Another received message */}
      <rect x="20" y="220" rx="8" ry="8" width="65%" height="80" className="message-container received" />
      <circle cx="18" cy="320" r="15" className="message-icon" />
      
      {/* Another sent message */}
      <rect x="35%" y="360" rx="8" ry="8" width="60%" height="70" className="message-container sent" />
      
    
    </ContentLoader>
  </div>
);
    
const Message = () => {
    const [messages, setMessages] = useState([]);
    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [newMessage, setNewMessage] = useState('');
    const [isInputFocused, setIsInputFocused] = useState(false);
    const [isUserScrolling, setIsUserScrolling] = useState(false);
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const messageListRef = useRef(null);
    const textareaRef = useRef(null);
    const loadingRef = useRef(false);


    const loadMessages = useCallback(async (initialLoad = false) => {
        if (loadingRef.current || isLoading || !hasMore) return;

        setIsLoading(true);
        loadingRef.current = true;

        const hotelId = localStorage.getItem('hotelId');
        const guestId = localStorage.getItem('id');
        const token = localStorage.getItem('guestToken');

        try {
            const response = await axios.get(process.env.REACT_APP_API_BASE_URL + `/chat/messages`, {
                params: {
                    userType: 'GUEST',
                    page,
                    size: 50,
                },
                headers: {
                    'Authorization': `Bearer ${token}`,
                    hotelId,
                    guestId,
                }
            });

            const newMessages = response.data.content.map(message => ({
                id: message.id,
                text: message.content,
                sent: message.messageSender === 'GUEST',
                senderName: message.senderName,
                readByHotel: message.readByHotel,
                readByGuest: message.readByGuest,
                timestamp: format(parseISO(message.created), 'MM/dd/yy hh:mm a'),
                imageIcon: message.messageSender !== 'GUEST',
            }));

            setMessages(prevMessages => {
                const uniqueMessages = newMessages.filter(
                    newMsg => !prevMessages.some(prevMsg => prevMsg.id === newMsg.id)
                );
                return initialLoad ? uniqueMessages.reverse() : [...uniqueMessages.reverse(), ...prevMessages];
            });

            setHasMore(response.data.totalPages > page + 1);
            setPage(prevPage => prevPage + 1);

            if (initialLoad) {
                setIsInitialLoad(false);
            }
        } catch (error) {
            console.error('Error loading messages:', error);
        } finally {
            setIsLoading(false);
            loadingRef.current = false;
        }
    }, [page, isLoading, hasMore]);

    const handleScroll = useCallback(() => {
        if (messageListRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = messageListRef.current;
            setIsUserScrolling(scrollTop + clientHeight < scrollHeight);

            if (scrollTop < 100 && !isLoading && hasMore) {
                loadMessages();
            }
        }
    }, [isLoading, hasMore, loadMessages]);

    useEffect(() => {
        loadMessages(true);
    }, [loadMessages]);

    useEffect(() => {
        const messageList = messageListRef.current;
        if (messageList) {
            messageList.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (messageList) {
                messageList.removeEventListener('scroll', handleScroll);
            }
        };
    }, [handleScroll]);

    useEffect(() => {
        const textarea = textareaRef.current;
        if (textarea) {
            textarea.style.height = 'auto';
            textarea.style.height = `${textarea.scrollHeight}px`;
        }
    }, [newMessage]);

    useEffect(() => {
        const handleTouchMove = () => {
            if (isInputFocused) {
                document.activeElement.blur();
                setIsInputFocused(false);
            }
        };

        window.addEventListener('touchmove', handleTouchMove);

        return () => {
            window.removeEventListener('touchmove', handleTouchMove);
        };
    }, [isInputFocused]);

    useEffect(() => {
        const handleResize = () => {
            if (messageListRef.current) {
                messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
            }
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (messageListRef.current && !isUserScrolling && !isInitialLoad) {
            messageListRef.current.style.scrollBehavior = 'auto';
            messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
            // Опционально: вернуть плавную прокрутку для пользовательских действий
            setTimeout(() => {
                if (messageListRef.current) {
                    messageListRef.current.style.scrollBehavior = 'smooth';
                }
            }, 100);
        }
    }, [messages, isUserScrolling, isInitialLoad]);

    const handleSendMessage = async () => {
        if (newMessage.trim() === '') return;

        const hotelId = localStorage.getItem('hotelId');
        const guestId = localStorage.getItem('id');
        const token = localStorage.getItem('guestToken');

        try {
            const response = await axios.post(process.env.REACT_APP_API_BASE_URL + `/chat/send`, {
                hotelId,
                guestId,
                content: newMessage,
                userType: 'GUEST'
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });

            const sentMessage = {
                id: response.data.id,
                text: newMessage,
                sent: true,
                senderName: 'You',
                readByHotel: false,
                readByGuest: true,
                timestamp: format(new Date(), 'MM/dd/yy HH:mm'),
            };

            setMessages(prevMessages => [...prevMessages, sentMessage]);
            setNewMessage('');

            if (messageListRef.current) {
                messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
            }

        } catch (error) {
            console.error('Error sending message:', error);
        }
    };
   

    return (
        <div className="hotel-messaging">
            <div className='both-bar'>
                <Link to='/home' style={{ textDecoration: 'none', color: 'var(--primaryColor)' }} >
                    <Arrow />
                </Link>
                <div className="message-bar">
                    <div className="bar-content">
                        <img src={Image} alt="Hotel" />
                        <div className="message-content">
                            <h4>Front Desk</h4>
                            <p>Service 24/7</p>
                        </div>
                    </div>
                </div>
            </div>

            <div
                className={`messagelist ${isInputFocused ? 'input-focused' : ''}`}
                ref={messageListRef}
            >
               {isLoading ? (
                    <div className="loading-indicator">
                        <MessageListLoader />
                    </div>
                ) : messages.length === 0 ? (
                    <div className="default-message">
                        <img src={chat} alt='/' />
                        <p>We are here to assist you 24/7</p>
                    </div>
                ) :  (
                    messages.map((message) => (
                        <div key={message.id} className={`message-container ${message.sent ? 'sent' : 'received'}`}>
                            <div className={`message-item ${message.sent ? 'sent' : 'received'}`}>
                                <div className="message-text">{message.text}</div>
                                <div className="message-timestamp">
                                    {message.timestamp}
                                    {message.sent && (
                                        message.readByHotel ? <IoCheckmarkDoneOutline className='sent-icon' /> : <IoMdCheckmark className="sent-icon" />
                                    )}
                                </div>
                            </div>
                            {!message.sent && message.imageIcon && (
                                <img src={Image} alt="Message icon" className="message-icon" />
                            )}
                        </div>
                    ))
                )}
            </div>

            <div className="messageinput">
                <textarea
                    ref={textareaRef}
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    onFocus={() => setIsInputFocused(true)}
                    onBlur={() => setIsInputFocused(false)}
                    placeholder="Type your message here"
                    className="message-input"
                    rows={1}
        style={{
            height: 'auto',
            overflowY: 'hidden',
            maxHeight: '100px', // Set a max height before scrolling
        }}
                />
                <button onClick={handleSendMessage}>
                    <BiSend className="send-button" />
                </button>
            </div>
        </div>
    );
};

export default Message;