import React, { useState } from 'react';

const AdminWakeUpCall = ({ wakeUpCallData, onWakeUpCallChange, onClose }) => {
  const [wakeUpCallNotice, setWakeUpCallNotice] = useState(wakeUpCallData.notice || '');

  const handleNoticeChange = (e) => {
    setWakeUpCallNotice(e.target.value);
  };

  const handleWakeUpCallSubmit = () => {
    onWakeUpCallChange({
      notice: wakeUpCallNotice
    });
  };

  return (

    <form onSubmit={handleWakeUpCallSubmit} style={{ width: '100%' }}>

      <div className="room-input-container cap1" >
        <input
          type="text"
          style={{ width: '100%' }}
          value={wakeUpCallNotice}
          onChange={handleNoticeChange}
          required
        />
        <label className={wakeUpCallNotice ? 'floating' : ''}>
          Type Start Hour to End Hour of Time
        </label>
      </div>

      <hr />
      <div className="button-bar">
        <button type="submit" onClick={handleWakeUpCallSubmit} className="save-btn">Save</button>
        <button type="button" onClick={onClose} className="delete-button">Cancel</button>
      </div>
    </form>
  );
};

export default AdminWakeUpCall;
