import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import './SerShow.css';
import checked from '../../Assets/All pics/checked_169780 1.png';
import axios from 'axios';
import { useOrderContext } from '../../Cart/OrderContext';

const BreakfastInBedService = ({ service, onSave }) => {
  const { handlePlaceOrder } = useOrderContext();
  const [selectedBreakfastType, setSelectedBreakfastType] = useState('');
  const [selectedItems, setSelectedItems] = useState({});

  useEffect(() => {
    console.log("Selected items updated:", selectedItems);
  }, [selectedItems]);

  const handleBreakfastTypeChange = (event) => {
    setSelectedBreakfastType(event.target.value);
    setSelectedItems({});
    console.log("Breakfast type changed to:", event.target.value);
  };

  const handleItemChange = (categoryName, itemName, max) => {
    console.log(`Attempting to change item: ${itemName} in category: ${categoryName}`);
    setSelectedItems((prevSelectedItems) => {
      const categoryItems = prevSelectedItems[categoryName] || [];
      const isAlreadySelected = categoryItems.includes(itemName);

      let updatedItems;
      if (isAlreadySelected) {
        updatedItems = {
          ...prevSelectedItems,
          [categoryName]: categoryItems.filter((i) => i !== itemName),
        };
      } else if (categoryItems.length < max) {
        updatedItems = {
          ...prevSelectedItems,
          [categoryName]: [...categoryItems, itemName],
        };
      } else {
        console.log(`Max items (${max}) reached for category: ${categoryName}`);
        return prevSelectedItems;
      }

      console.log("Updated selected items:", updatedItems);
      return updatedItems;
    });
  };

  const handleSave = async () => {
    console.log("handleSave called");
    console.log("Current selectedBreakfastType:", selectedBreakfastType);
    console.log("Current selectedItems:", selectedItems);

    const selectedCategories = Object.keys(selectedItems);
    const hasSelectedItems = selectedCategories.some(
      (category) => selectedItems[category].length > 0
    );

    if (!selectedBreakfastType || !hasSelectedItems) {
      console.log("No breakfast type or items selected");
      Swal.fire({
        title: 'Error',
        text: 'Please select a breakfast type and at least one item.',
        icon: 'error',
        confirmButtonText: 'Close',
      });
      return;
    }

    Swal.fire({
      title: 'Confirm Order',
      text: `Are you sure you want to place the order?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
      customClass: {
        confirmButton: 'custom-confirm-button',
        cancelButton: 'custom-cancel-button',
      },
      buttonsStyling: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const additionalOptions = {};
        let totalAmount = 0; // Initialize totalAmount

        if (selectedBreakfastType) {
          console.log("Selected Breakfast Type:", selectedBreakfastType);
          console.log("Service Additional Options:", service.additionalOptions);

          const selectedBreakfastTypeKey = Object.keys(service.additionalOptions).find(
            (key) => service.additionalOptions[key].name === selectedBreakfastType
          );

          console.log("Selected Breakfast Type Key:", selectedBreakfastTypeKey);

          if (selectedBreakfastTypeKey) {
            const selectedBreakfastDetails = service.additionalOptions[selectedBreakfastTypeKey];

            console.log("Selected Breakfast Details:", selectedBreakfastDetails);

            totalAmount = selectedBreakfastDetails.price; // Set totalAmount to selected breakfast price

            additionalOptions[selectedBreakfastTypeKey] = {
              name: selectedBreakfastDetails.name,
              price: selectedBreakfastDetails.price,
              currency: selectedBreakfastDetails.currency,
              categories: {}
            };

            console.log("Selected Items:", selectedItems);

            Object.keys(selectedItems).forEach((categoryName) => {
              const categoryItems = selectedItems[categoryName];
              console.log(`Processing category: ${categoryName}, Items: ${categoryItems}`);

              if (categoryItems.length > 0) {
                const matchingCategoryKey = Object.keys(selectedBreakfastDetails.categories).find(
                  (key) => selectedBreakfastDetails.categories[key].name === categoryName
                );

                console.log(`Matching category key for ${categoryName}: ${matchingCategoryKey}`);

                if (matchingCategoryKey) {
                  const categoryDetails = selectedBreakfastDetails.categories[matchingCategoryKey];
                  additionalOptions[selectedBreakfastTypeKey].categories[categoryName] = {
                    items: categoryItems,
                    optional_max: categoryDetails.optional_max
                  };
                }
              }
            });
          }
        }

        console.log("Final Additional Options:", additionalOptions);

        const token = localStorage.getItem('guestToken');
        const hotelId = localStorage.getItem('hotelId');
        const guestId = localStorage.getItem('id');

        try {
          const response = await axios.post(
            process.env.REACT_APP_API_BASE_URL + '/order/place-service',
            {
              totalAmount: totalAmount, // Use calculated totalAmount
              totalQuantity: 1,
              orderStatus: 'Pending',
              title: service.name,
              additionalOptions: additionalOptions,
            },
            {
              headers: {
                accept: '*/*',
                hotelId: hotelId,
                guestId: guestId,
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
            }
          );

          console.log("Server response:", response.data);

          if (response.status === 200) {
            handlePlaceOrder();
            Swal.fire({
              title: 'Order Placed',
              html: `<div>You can track the status of your order in the orders section</div>`,
              confirmButtonText: 'Close',
              iconHtml: `<img src="${checked}" style="width: 60px; height: 60px; border: none; box-shadow: none; border-radius: 0;"/>`,
              customClass: {
                icon: 'custom-icon',
                confirmButton: 'custom-confirm-button',
              },
              buttonsStyling: false,
            }).then(() => {
              setSelectedBreakfastType('');
              setSelectedItems({});
            });
          } else {
            console.error(
              'Error placing order:',
              response.status,
              response.statusText
            );
            Swal.fire({
              title: 'Error',
              text: 'Failed to place order. Please try again later.',
              icon: 'error',
              confirmButtonText: 'OK',
            });
          }
        } catch (error) {
          console.error('Error placing order:', error);
          Swal.fire({
            title: 'Error',
            text: 'Failed to place order. Please try again later.',
            icon: 'error',
            confirmButtonText: 'OK',
          });
        }
      }
    });
  };

  const isButtonDisabled =
    !selectedBreakfastType ||
    !Object.keys(selectedItems).some(
      (category) => selectedItems[category].length > 0
    );

  const breakfastTypes = Object.keys(service.additionalOptions).filter((key) =>
    key.startsWith('Breakfast Type')
  );

  return (
    <div className="break-con">
      <div className="break-box">
        <h3>{service.name}</h3>
        <div className="breakfast-box">
          <label htmlFor="breakfastType"></label>
          <select
            id="breakfastType"
            value={selectedBreakfastType}
            onChange={handleBreakfastTypeChange}
          >
            <option value="" disabled>
              Select type
            </option>
            {breakfastTypes.map((type) => (
              <option
                key={type}
                value={service.additionalOptions[type].name}
              >
                {service.additionalOptions[type].name} - $
                {service.additionalOptions[type].price}
              </option>
            ))}
          </select>
        </div>
      </div>

      {!selectedBreakfastType && (
        <div className="default-content">
          <p>Please select a breakfast type to see the options.</p>
          <h3>
            Please order between{' '}
            {service.additionalOptions.serviceStartTime}-
            {service.additionalOptions.serviceEndTime}
          </h3>
        </div>
      )}

      <div className="bed-box">
        {selectedBreakfastType && (
          <div className="breakfast-con">
            {breakfastTypes.map((type) => {
              if (
                service.additionalOptions[type].name === selectedBreakfastType
              ) {
                return Object.keys(
                  service.additionalOptions[type].categories
                ).map((categoryName) => (
                  <div key={categoryName}>
                    <label className="br-title">
                      <h3>
                        {
                          service.additionalOptions[type].categories[
                            categoryName
                          ].name
                        }
                      </h3>
                      <p>
                        (Optional, Max{' '}
                        {
                          service.additionalOptions[type].categories[
                            categoryName
                          ].optional_max
                        }
                        )
                      </p>
                    </label>
                    <div className="laundry">
                      {service.additionalOptions[type].categories[categoryName].items.map((item) => (
                        <div key={item.name}>
                          <div className="lan-check">
                            <input
                              type="checkbox"
                              checked={selectedItems[service.additionalOptions[type].categories[categoryName].name]?.includes(item.name) || false}
                              onChange={(e) => {
                                e.stopPropagation();
                                handleItemChange(
                                  service.additionalOptions[type].categories[categoryName].name,
                                  item.name,
                                  service.additionalOptions[type].categories[categoryName].optional_max
                                );
                              }}
                              id={`checkbox-${item.name}`}
                            />
                            <label
                              htmlFor={`checkbox-${item.name}`}
                              onClick={(e) => e.stopPropagation()}
                            >
                              {item.name}
                            </label>
                            <p></p>
                          </div>
                          <hr className="inner-hr" />
                        </div>
                      ))}
                    </div>
                  </div>
                ));
              }
              return null;
            })}
            <div className="extra"></div>
          </div>
        )}
        <div className="overlay-lan">
          <button
            className={`bed-button ${isButtonDisabled ? '' : 'active'}`}
            disabled={isButtonDisabled}
            onClick={handleSave}
          >
            Place Order
          </button>
        </div>
      </div>
    </div>
  );
};

export default BreakfastInBedService;