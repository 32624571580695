import React, { useState, useEffect } from 'react';
import './Restaurant.css';
import Edit from './edit.png';

const FoodItem = ({ item, onEdit, onAvailabilityChange }) => {
  const [imgSrc, setImgSrc] = useState(item.imageLink || 'https://us-cal-sansara-restaurant.s3.us-west-1.amazonaws.com/hotel7/default_food.jpg');
  
  // Используем состояние для управления выбором доступности
  const [available, setAvailable] = useState(item.available === 1 ? 'available' : 'not-available');

  useEffect(() => {
    if (item.imageLink) {
      setImgSrc(item.imageLink);
    }
  }, [item.imageLink]);

  useEffect(() => {
    // Обновляем значение доступности при изменениях item.available
    setAvailable(item.available === 1 ? 'available' : 'not-available');
  }, [item.available]);

  const handleImageError = () => {
    setImgSrc('https://us-cal-sansara-restaurant.s3.us-west-1.amazonaws.com/hotel7/default_food.jpg');
  };

  const handleAvailabilityChange = (e) => {
    e.stopPropagation(); // Останавливаем всплывание события
    const newAvailability = e.target.value === 'available' ? 1 : 0;
    setAvailable(e.target.value);
    onAvailabilityChange(item.id, newAvailability, item.imageLink);
  };
  
  
  return (
    <div className='food-box' onClick={() => onEdit(item)} style={{ cursor: 'pointer' }}>
      <div className="food-item">
        <div className='itemcon'>
          <img src={imgSrc} alt={item.title} onError={handleImageError} className='shareimg'/>
        </div>
        <div className='item-desmain'>
          <p className='item-des'>{item.title}</p>
          <p className='item-des1'>{item.description}</p>
        </div>
        <p className='item-des2'>${item.price} {item.currency}</p>
        <p className='item-des3'>{item.category}</p>
        <div className="available-box">
          <select
            name="available"
            value={available}
            onChange={handleAvailabilityChange}
            onClick={(e) => e.stopPropagation()}
          >
            <option value="available">Available</option>
            <option value="not-available">Not Available</option>
          </select>
        </div>
        <img src={Edit} alt='edit' className='editimg'/>
      </div>
    </div>
  );
};

export default FoodItem;
