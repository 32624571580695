import React, { useState } from 'react';
import axios from 'axios';


const ForgotPassword =({ onBackToLogin }) => {
    const [email, setEmail] = useState('');
    const [responseMessage, setResponseMessage] = useState('');

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const response = await axios.post(process.env.REACT_APP_API_BASE_URL + `/hotel/forgot-password?email=${email}`);
            setResponseMessage('Password reset email sent successfully!');
        } catch (error) {
            console.error('Error sending password reset email:', error);
            setResponseMessage('Error sending password reset email. Please try again.');
        }
    };

    return (
        <div className='form-group'>
           
            <form onSubmit={handleSubmit}>
                <div className="password-input-container">
                 
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={handleEmailChange}
                        required
                    />
                <label className={email ? 'floating' : ''} onClick={() => document.getElementById('email').focus()}>
            Enter Email
          </label>
        </div>
        <button type="submit" className="get">Reset Password </button> {/* Reuse button class */}
      </form>
      {responseMessage && <p>{responseMessage}</p>}
      <p className='toggle-p'>
        Remembered your password?{' '}
        <span className="toggleText"  onClick={onBackToLogin} >
          Login here
        </span> 
        </p>
    </div>
    );
};

export default ForgotPassword;
