import React, { useState, useEffect, useCallback } from 'react';
import AddAdminService from './AddAdminService';
import EditAdminService from './EditAdminService';
import WakeUpCallForm from './WakeUpCallForm';
import LaundryForm from './LaundryForm';
import RoomCleaningForm from './RoomCleaningForm';
import BreakfastInBedForm from './BreakfastInBedForm';
import axios from 'axios';

const AdminService = () => {
  const [services, setServices] = useState([]);
  const [searchQuery] = useState('');
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showWakeUpCallModal, setShowWakeUpCallModal] = useState(false);
  const [showLaundryModal, setShowLaundryModal] = useState(false);
  const [showRoomCleaningModal, setShowRoomCleaningModal] = useState(false);
  const [showBreakfastInBedModal, setShowBreakfastInBedModal] = useState(false);
  const [selectedService, setSelectedService] = useState(null);

  const token = localStorage.getItem('hotelToken');
  const hotelId = localStorage.getItem('hotelId');

  const fetchServices = useCallback(async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_BASE_URL + '/hotel-service/list', {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          hotelId: hotelId,
        },
      });
      setServices(response.data);
    } catch (error) {
      console.error('Error fetching services:', error);
    }
  }, [token, hotelId, setServices]); // Добавили зависимости

  useEffect(() => {
    fetchServices();
  }, [fetchServices]);

  const openEditModal = (service) => {
    setSelectedService(service);
    if (service.name === "Wake Up Call") {
      setShowWakeUpCallModal(true);
    } else if (service.name === "Laundry") {
      setShowLaundryModal(true);
    } else if (service.name === "Room Cleaning") {
      setShowRoomCleaningModal(true);
    } else if (service.name === "Breakfast in Bed") {
      setShowBreakfastInBedModal(true);
    } else {
      setShowEditModal(true);
    }
  };

  const handleAddService = () => {
    setShowAddModal(false);
    fetchServices();
  };

  const handleServiceChange = (name, value) => {
    setSelectedService({
      ...selectedService,
      [name]: value,
    });
  };

  const handleEditService = (updatedService) => {
    setShowEditModal(false);
    setServices(prevServices =>
      prevServices.map(service =>
        service.id === updatedService.id ? updatedService : service
      )
    );
  };

  const handleWakeUpCallSubmit = (updatedService) => {
    setShowWakeUpCallModal(false);
    setServices(prevServices =>
      prevServices.map(service =>
        service.id === updatedService.id ? updatedService : service
      )
    );
  };

  const handleLaundrySubmit = (updatedService) => {
    setShowLaundryModal(false);
    setServices(prevServices =>
      prevServices.map(service =>
        service.id === updatedService.id ? updatedService : service
      )
    );
  };

  const handleRoomCleaningSubmit = (updatedService) => {
    setShowRoomCleaningModal(false);
    setServices(prevServices =>
      prevServices.map(service =>
        service.id === updatedService.id ? updatedService : service
      )
    );
  };

  const handleBreakfastInBedSubmit = (updatedService) => {
    setShowBreakfastInBedModal(false);
    setServices(prevServices =>
      prevServices.map(service =>
        service.id === updatedService.id ? updatedService : service
      )
    );
  };

  const handleDeleteService = (serviceId) => {
    setServices(prevServices => prevServices.filter(service => serviceId !== service.id));
    setShowWakeUpCallModal(false);
    setShowEditModal(false);
    setShowLaundryModal(false);
    setShowRoomCleaningModal(false);
    setShowBreakfastInBedModal(false);
  };

  const toggleServiceStatus = (id) => {
    const updatedServices = services.map((service) => {
      if (service.id === id) {
        return { ...service, available: service.available === 1 ? 0 : 1 };
      }
      return service;
    });
    setServices(updatedServices);
  };

  const filteredActivities = services.filter(service =>
    service.name && service.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className='rooms-container'>
      <div className='room-container'>
        <div className='bar2'>
          <h1>Services</h1>
          <div className="room-controls">
            <button onClick={() => setShowAddModal(true)}>+</button>
          </div>
        </div>
        <ul className='room-list'>
          {filteredActivities.map((service) => (
            <li key={service.id} className='room-list1' onClick={() => openEditModal(service)}>
              <div className="room-info">
                <div className="room-column">
                  <h4>{service.name}</h4>
                  <div
                    className={`activity-status ${service.available === 1 ? 'ON' : 'OFF'}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleServiceStatus(service.id);
                    }}
                  >
                    {service.available === 1 ? 'ON' : 'OFF'}
                  </div>
                </div>
                <div className="room-description">
                  <p style={{ width: '100%', height: '80px' }}>{service.description}</p>
                </div>
              </div>
            </li>
          ))}
        </ul>
        {showAddModal && (
          <AddAdminService onClose={() => setShowAddModal(false)} onSubmit={handleAddService} />
        )}
        {showEditModal && selectedService && (
          <EditAdminService
            serviceData={selectedService}
            onClose={() => setShowEditModal(false)}
            handleSubmit={handleEditService}
            handleChange={handleServiceChange}
            onDeleteService={handleDeleteService}
          />
        )}
        {showWakeUpCallModal && selectedService && (
          <WakeUpCallForm
            serviceId={selectedService.id}
            onClose={() => setShowWakeUpCallModal(false)}
            onSubmit={handleWakeUpCallSubmit}
            isEditing={true}
            initialFormData={selectedService}
            onDeleteService={handleDeleteService}
          />
        )}
        {showLaundryModal && selectedService && (
          <LaundryForm
            serviceId={selectedService.serviceId}
            onClose={() => setShowLaundryModal(false)}
            onSubmit={handleLaundrySubmit}
            isEditing={true}
            initialFormData={selectedService}
            onDeleteService={handleDeleteService}
          />
        )}
        {showRoomCleaningModal && selectedService && (
          <RoomCleaningForm
            serviceId={selectedService.serviceId}
            onClose={() => setShowRoomCleaningModal(false)}
            onSubmit={handleRoomCleaningSubmit}
            isEditing={true}
            initialFormData={selectedService}
            onDeleteService={handleDeleteService}
          />
        )}
        {showBreakfastInBedModal && selectedService && (
          <BreakfastInBedForm
            serviceId={selectedService.id}
            onClose={() => setShowBreakfastInBedModal(false)}
            onSubmit={handleBreakfastInBedSubmit}
            isEditing={true}
            initialFormData={selectedService}
            onDeleteService={handleDeleteService}
          />
        )}
      </div>
    </div>
  );
};

export default AdminService;