import React, { useState, useEffect, useMemo, useRef } from 'react';
import axios from 'axios';
import CustomDatePicker from './CustomDatePicker';
import './Guests.css';
import debounce from 'lodash/debounce';

const EditGuestPage = ({ guest, onClose, onEditGuest, onDeleteGuest }) => {
  const [formData, setFormData] = useState({ ...guest });
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [roomOptions, setRoomOptions] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [originalRoomNumber] = useState(guest.room);
  const initialClickElement = useRef(null);
  const token = localStorage.getItem('hotelToken');

  useEffect(() => {
    setFormData({
      ...guest,
      checkIn: guest.checkIn ? new Date(guest.checkIn) : null,
      checkOut: guest.checkOut ? new Date(guest.checkOut) : null,
      type: guest.type || '',
    });
  }, [guest]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleDateChange = (date, name) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: date,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.room !== originalRoomNumber && !roomOptions.some(option => option.roomNumber === formData.room)) {
      setErrorMessage("This room doesn't exist");
      return;
    }

    const updatedGuest = {
      id: formData.id,
      firstName: formData.firstName,
      lastName: formData.lastName,
      checkIn: formData.checkIn,
      checkOut: formData.checkOut,
      room: formData.room,
      breakfast: formData.breakfast,
      guestsNumber: formData.guestsNumber,
      active: formData.active, // include the active field
    };

    try {
      await axios.put(process.env.REACT_APP_API_BASE_URL + '/guest/edit', updatedGuest, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      onEditGuest(updatedGuest);
      setErrorMessage('');
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setErrorMessage(error.response.data.message);
      } else {
        console.error('Failed to update guest info', error);
      }
    }
  };

  const handleDelete = async () => {
    try {
      await axios.delete(process.env.REACT_APP_API_BASE_URL + `/guest/${guest.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      onDeleteGuest(guest.id);
      onClose(); // Close the modal after deleting
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setErrorMessage(error.response.data.message);
      } else {
        console.error('Failed to delete guest', error);
      }
    }
  };

  const handleBackgroundMouseDown = (e) => {
    initialClickElement.current = e.target;
  };

  const handleBackgroundMouseUp = (e) => {
    if (e.target.className === 'main-room' && initialClickElement.current === e.target) {
      onClose();
    }
    initialClickElement.current = null;
  };

  const handleConfirmDelete = () => {
    setShowConfirmModal(true);
  };

  const handleCancelDelete = () => {
    setShowConfirmModal(false);
  };

  const handleConfirmYes = () => {
    handleDelete();
    setShowConfirmModal(false);
  };

  const debouncedFetchRoomOptions = useMemo(() => debounce(async (value) => { 
    if (value.length > 0) {
      try {
        const response = await axios.get(process.env.REACT_APP_API_BASE_URL + '/room/search', {
          headers: {
            Authorization: `Bearer ${token}`, 
            hotelId: guest.hotelId, // Добавить guest.hotelId в зависимости
          },
          params: {
            search: value,
          },
        });
        setRoomOptions(response.data);
        setShowDropdown(true);
      } catch (error) {
        console.error('Failed to fetch room options', error);
      }
    } else {
      setShowDropdown(false);
    }
  }, 500), [token, guest.hotelId, setRoomOptions, setShowDropdown]);

  const handleRoomNumberChange = (e) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      room: value,
    }));
    debouncedFetchRoomOptions(value);
    setErrorMessage(''); // Clear error message when typing
  };

  const handleRoomSelect = (selectedRoom) => {
    setFormData((prevData) => ({
      ...prevData,
      room: selectedRoom.roomNumber,
      type: selectedRoom.type,
      breakfast: selectedRoom.breakfast,
    }));
    setShowDropdown(false);
    setErrorMessage('');
  };

  if (!guest) {
    return <div>Guest not found</div>;
  }

  return (
    <div className='main-room' onMouseDown={handleBackgroundMouseDown} onMouseUp={handleBackgroundMouseUp}>
      <div className="add-room-options">
        <div className='added-box'>
          <h3>Edit Guest</h3>
          <button className="close" onClick={onClose}>×</button>
        </div>
        <div className="room-form " style={{ paddingTop: '10px' }}>
          <form onSubmit={handleSubmit}>
            <div className='m2 room-multiple'>
              <div className="room-input-container">
                <input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                />
                <label className={formData.firstName ? 'floating' : ''}>First Name</label>
              </div>
              <div className="room-input-container">
                <input
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                />
                <label className={formData.lastName ? 'floating' : ''}>Last Name</label>
              </div>
            </div>

            <div className="room-input-container cap1" style={{ position: 'relative' }}>
              <input
                type="text"
                name="room"
                value={formData.room}
                onChange={handleRoomNumberChange}
                style={{ width: '100%' }}
                required
                onFocus={() => setShowDropdown(true)}
              />
              <label className={formData.room ? 'floating' : ''}>
                Room Number
              </label>
              {showDropdown && roomOptions.length > 0 && (
                <ul className="dropdown-menu">
                  {roomOptions.map((room) => (
                    <li key={room.id} onClick={() => handleRoomSelect(room)}>
                      {room.roomNumber}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div className='m2 room-multiple '>
              <CustomDatePicker
                type='date'
                value={formData.checkIn}
                onChange={handleDateChange}
                name="checkIn"
                label="Check-in Date"
              />
              <CustomDatePicker
                type='date'
                value={formData.checkOut}
                onChange={handleDateChange}
                name="checkOut"
                label="Check-out Date"
              />
            </div>

            <div className='checkbox-container'>
              <input
                type="checkbox"
                id="breakfast"
                name="breakfast"
                checked={formData.breakfast}
                onChange={handleChange}
              />
              <label htmlFor="breakfast">Breakfast</label>
            </div>

            <hr />
            <div className='button-bar'>
              <button type="button" className="delete-button" onClick={handleConfirmDelete}>Delete</button>
              <button type="submit" className='save-btn'>Save</button>
            </div>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
          </form>
        </div>
      </div>

      {showConfirmModal && (
        <div className="modal" onClick={handleCancelDelete}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h3>Are you sure you want to delete?</h3>
            <div className="button-bar">
              <button className="delete-button" onClick={handleConfirmYes}>Yes</button>
              <button className='save-btn' onClick={handleCancelDelete}>No</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditGuestPage;
