import React, { useState } from 'react';
import Swal from 'sweetalert2';

const AdminSpaBooking = ({ spaData, onSpaChange, onClose }) => {
  const [spaOpeningHour, setSpaOpeningHour] = useState(spaData.openingHour || 9);
  const [spaClosingHour, setSpaClosingHour] = useState(spaData.closingHour || 21);

  const handleSpaOpeningHourChange = (e) => {
    setSpaOpeningHour(parseInt(e.target.value, 10));
  };

  const handleSpaClosingHourChange = (e) => {
    setSpaClosingHour(parseInt(e.target.value, 10));
  };

  const handleSpaSubmit = () => {
    if (spaOpeningHour >= spaClosingHour) {
      Swal.fire({
        title: 'Invalid Time',
        text: 'The opening hour must be less than the closing hour.',
        icon: 'error',
        confirmButtonText: 'Close'
      });
      return;
    }

    onSpaChange({
      openingHour: spaOpeningHour,
      closingHour: spaClosingHour
    });

    Swal.fire({
      title: 'Spa Timing Updated',
      text: 'Spa timing has been successfully updated.',
      icon: 'success',
      confirmButtonText: 'Close'
    });
  };
  const handleClick = (inputName) => {
    document.getElementsByName(inputName)[0].focus();
  };

  return (
    <form onSubmit={handleSpaSubmit} style={{ width: '100%', marginTop: '10px' }}>
      <div className='row-box1'>
        <div className="room-input-container cap1">

          <input
            type="text"
            id="spaOpeningHour"
            min="0"
            max="23"
            value={spaOpeningHour}
            onChange={handleSpaOpeningHourChange}
            required
          />
          <label className={spaOpeningHour ? 'floating' : ''} onClick={() => handleClick('spaOpeningHour')}>
            Opening Hour
          </label>

        </div>

        <div className="room-input-container cap1">

          <input
            type="text"
            id="spaClosingHour"
            min="0"
            max="23"
            value={spaClosingHour}
            onChange={handleSpaClosingHourChange}
            required
          />
          <label className={spaClosingHour ? 'floating' : ''} onClick={() => handleClick('spaClosingHour')}>
            Closing Hour
          </label>
        </div>
      </div>
      <hr />

      <div className="button-bar">
        <button type="submit" className="save-btn">Save</button>
        <button type="button" onClick={onClose} className="delete-button">Cancel</button>
      </div>

    </form>
  );
};

export default AdminSpaBooking;
