import React, { useState, useRef ,useEffect} from 'react';
import axios from 'axios';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import ImageCropper from './ImageCropper';
const placeholderImage = 'https://us-cal-sansara-restaurant.s3.us-west-1.amazonaws.com/hotel7/default_food.jpg';

const AddDish = ({ onClose, onAddItem }) => {
  const token = localStorage.getItem('hotelToken');
  const [loading, setLoading] = useState(false);
  const [showCropModal, setShowCropModal] = useState(false);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    availability: 'available',
    recommended: 'recommended',
    price: '',
    currency: 'USD',
    category: '',
    originalPhoto: null,
    croppedPhoto: null,
  });
  const initialClickElement = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    
     // Limit title to 15 words
     if (name === 'title') {
      const wordCount = value.split(/\s+/).filter(Boolean).length;
      if (wordCount <= 22) {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    }

    // Limit description to 160 characters
    if (name === 'description') {
      if (value.length <= 160) {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    }

    // Handle other inputs normally
    if (name !== 'title' && name !== 'description') {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };


  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageDataUrl = URL.createObjectURL(file);
      setFormData((prevData) => ({
        ...prevData,
        originalPhoto: imageDataUrl,
        croppedPhoto: imageDataUrl,
      }));
      setShowCropModal(true);
    }
  };

  const handleCropComplete = (croppedImageUrl) => {
    setFormData((prevData) => ({
      ...prevData,
      croppedPhoto: croppedImageUrl,
    }));
    setShowCropModal(false);
  };

  const handleCancelCrop = () => {
    setShowCropModal(false);
    if (!formData.croppedPhoto) {
      setFormData((prevData) => ({
        ...prevData,
        originalPhoto: null,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const hotelId = localStorage.getItem('hotelId');

    const form = new FormData();
    form.append('foodDto', JSON.stringify({
      title: formData.title,
      description: formData.description || null,
      category: formData.category,
      price: formData.price,
      currency: formData.currency,
      available: formData.availability === 'available' ? 1 : 0,
      recommended: formData.recommended === 'recommended' ? 0 : 1,
    }));

    if (formData.croppedPhoto) {
      const response = await fetch(formData.croppedPhoto);
      const blob = await response.blob();
      form.append('file', blob, 'cropped_image.jpg');
    }

    try {
      console.log('Sending request to backend...');
      setLoading(true);
      const response = await axios.post(process.env.REACT_APP_API_BASE_URL + '/restaurant/create', form, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
          hotelId: hotelId,
        },
      });

      const newItem = {
        id: response.data.id,
        title: response.data.title,
        description: response.data.description,
        category: response.data.category,
        price: response.data.price,
        currency: response.data.currency,
        imageLink: response.data.imageLink || formData.croppedPhoto || placeholderImage,
        available: response.data.available,
        recommended: response.data.recommended,
        orderNumber: response.data.orderNumber,
      };

      onAddItem(newItem);
      onClose();
    } catch (error) {
      console.error('Failed to add dish', error);
    } finally {
      setLoading(false);
    }
  };

  const handleClick = (inputName) => {
    document.getElementsByName(inputName)[0].focus();
  };

  const handleBackgroundMouseDown = (e) => {
    initialClickElement.current = e.target;
  };

  const handleBackgroundMouseUp = (e) => {
    if (e.target.className === 'main-room' && initialClickElement.current === e.target) {
      onClose();
    }
    initialClickElement.current = null;
  };
  return (
    <div className='main-room' onMouseDown={handleBackgroundMouseDown} onMouseUp={handleBackgroundMouseUp}>
      <div className="add-room-options">
        <div className='added-box'>
          <h3>Add Dish</h3>
          <button className="close" onClick={onClose}>×</button>
        </div>
        <div className="room-form m2 edit">
          <form onSubmit={handleSubmit}>
            <div className="room-input-container ">
              <input
                type="text"
                id="title"
                name="title"
                value={formData.title}
                onChange={handleChange}
                required
                style={{ width: '100%' }}
              />
              <label className={formData.title ? 'floating' : ''} onClick={() => handleClick('title')}>Title</label>
            </div>
            <div className="room-input-container">
              <input
                type="text"
                id="description"
                name="description"
                value={formData.description}
                onChange={handleChange}
                style={{ width: '100%' }}
              />
              <label className={formData.description ? 'floating' : ''} onClick={() => handleClick('description')}>Description</label>
            </div>
            <div className="room-input-container down">
              <input
                type="text"
                id="price"
                name="price"
                value={formData.price}
                onChange={handleChange}
                required
                style={{ width: '100%' }}
              />
              <label className={formData.price ? 'floating' : ''} onClick={() => handleClick('price')}>Price</label>
            </div>
            <div className="room-input-container ">
              <select
                id="currency"
                name="currency"
                value={formData.currency}
                onChange={handleChange}
                required
                style={{ width: '100%' }}
              >
                <option value="USD">USD</option>
                <option value="AED">AED</option>
              </select>
              <label className={formData.currency ? 'floating' : ''} onClick={() => handleClick('currency')}>Currency</label>
            </div>
            <div className="room-input-container ">
              <select
                id="category"
                name="category"
                value={formData.category}
                onChange={handleChange}
                required
                style={{ width: '100%' }}
              >
                <option value="" disabled hidden></option>
                <option value="Appetizer">Appetizer</option>
                <option value="Fast Food">Fast Food</option>
                <option value="Drinks">Drinks</option>
                <option value="Alcohol">Alcohol</option>
                <option value="Main Courses">Main Courses</option>
                <option value="Salads">Salads</option>
                <option value="Desserts">Desserts</option>
                <option value="Soups">Soups</option>
              </select>
              <label className={formData.category ? 'floating' : ''} onClick={() => handleClick('category')}>Category</label>
            </div>

            <div className="room-input-container ">
              <select
                name="recommended"
                value={formData.recommended}
                onChange={handleChange}
                required
              >
                <option value="0">No</option>
                <option value="1">Yes</option>
              </select>
              <label
                className={formData.recommended ? 'floating' : ''}
                onClick={() => handleClick('availability')}
              >
                Recommended Label
              </label>
            </div>


            <div className="room-input-container">
              <select
                id="availability"
                name="availability"
                value={formData.availability}
                onChange={handleChange}
                required
              >
                <option value="available">Available</option>
                <option value="not-available">Not Available</option>
              </select>
              <label className={formData.availability ? 'floating' : ''} onClick={() => handleClick('availability')}>Availability</label>
            </div>
            <div className="image-upload-section">
              <label htmlFor="file-input-edit" className="file-input-label">
                {formData.originalPhoto ? '+ Change Image' : '+ Add Image'}
              </label>
              <input
                type="file"
                id="file-input-edit"
                style={{ display: 'none' }}
                onChange={handleFileChange}
                accept="image/*"
              />

              {formData.croppedPhoto && (
                <div className="cropped-image-preview" onClick={() => setShowCropModal(true)}>
                  <h4>Image Preview:</h4>
                  <img src={formData.croppedPhoto} alt="Cropped" className="shareimg" />
                </div>
              )}
            </div>
            
            <hr />
            <div className="button-bar">
              <button type="submit" className="save-btn">{loading ? 'Adding...' : 'Add Dish'}</button>
            </div>
          </form>
        </div>
      </div>

      {showCropModal && (
        <ImageCropper
          image={formData.originalPhoto}
          onCropComplete={handleCropComplete}
          onCancel={handleCancelCrop}
        />
      )}
    </div>
  );
};

export default AddDish;