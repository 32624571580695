import React from 'react'
import ContentLoader from "react-content-loader";

const RenderShimmer = () => (
    <div className="food-ser" style={{ marginTop: "0px" }}>
      {[...Array(6)].map((_, index) => (
     
        <div className="foo" key={index}>
          <div className="food-container">
            <div className="foodi">
              <div className="loader">
                <ContentLoader
                  viewBox="0 0 210 285"
                  height={285}
                  width={210}
                >
               <rect x="0" y="0" rx="8" ry="8"  />
                  <rect x="5" y="200" rx="4" ry="4" width="70" height="20" />
                  <rect x="5" y="230" rx="3" ry="3" width="170" height="25" />
                  <rect x="5" y="275" rx="3" ry="3" width="80" height="20" />
                </ContentLoader>
              </div>
           
            </div>
          </div>
        </div>
      ))}
    </div>
  );

export default RenderShimmer