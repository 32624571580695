import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';

const AddActivity = ({ onClose, onAddActivity }) => {
    const [formData, setFormData] = useState({
        title: '',
        star: '',
        description: '',
        category: '',
        available: 'available',
        tourType: 'private',
        timeType: {
            private: 'fixed',
            pickup: 'fixed',
        },
        fixedTime: {},
        flexibleTimes: {
            private: [],
            pickup: [],
        },
        maxPeople: {
            private: '',
            pickup: '',
        },
        price: {
            private: '',
            pickup: '',
        },
        currency: {
            private: 'USD',
            pickup: 'USD',
        },
        includes: {
            private: '',
            pickup: '',
        },
        notIncludes: {
            private: '',
            pickup: '',
        },
        location: {
            private: '',
            pickup: '',
        },
        additional_info: {
            private: '',
            pickup: '',
        },
        photos: [],
    });

    const [selectedDays, setSelectedDays] = useState({
        private: [],
        pickup: [],
    });
    const [categoryOpen, setCategoryOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleNestedChange = (field, value) => {
        setFormData({
            ...formData,
            [field]: {
                ...formData[field],
                [formData.tourType]: value,
            },
        });
    };

    const handleTourTypeChange = (e) => {
        setFormData({ ...formData, tourType: e.target.value });
    };

    const handleDayChange = (tourType, day) => {
        setSelectedDays((prevDays) => {
            const updatedDays = prevDays[tourType].includes(day)
                ? prevDays[tourType].filter((d) => d !== day)
                : [...prevDays[tourType], day];
            return {
                ...prevDays,
                [tourType]: updatedDays.sort(),
            };
        });
    };

    const handleFlexibleTimeChange = (tourType, index, value) => {
        const newFlexibleTimes = [...formData.flexibleTimes[tourType]];
        newFlexibleTimes[index] = value;
        setFormData((prevData) => ({
            ...prevData,
            flexibleTimes: {
                ...prevData.flexibleTimes,
                [tourType]: newFlexibleTimes,
            },
        }));
    };

    const addFlexibleTime = (tourType) => {
        setFormData((prevData) => ({
            ...prevData,
            flexibleTimes: {
                ...prevData.flexibleTimes,
                [tourType]: [...prevData.flexibleTimes[tourType], ''],
            },
        }));
    };

    const removeFlexibleTime = (tourType, index) => {
        const updatedTimes = [...formData.flexibleTimes[tourType]];
        updatedTimes.splice(index, 1);
        setFormData(prevState => ({
            ...prevState,
            flexibleTimes: {
                ...prevState.flexibleTimes,
                [tourType]: updatedTimes
            }
        }));
    };

    const handlePhotoChange = (e) => {
        const files = Array.from(e.target.files);
        setFormData((prevData) => ({
            ...prevData,
            photos: [...prevData.photos, ...files],
        }));
    };

    const removePhoto = (index) => {
        setFormData((prevData) => ({
            ...prevData,
            photos: prevData.photos.filter((_, i) => i !== index),
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const token = localStorage.getItem('hotelToken');
        const hotelId = localStorage.getItem('hotelId');

        if (!token || !hotelId) {
            console.error('Token or hotelId not found in localStorage');
            return;
        }

        const additionalOptions = {
            maxPeople: formData.maxPeople,
            availableDays: selectedDays,
            timeType: {
                private: formData.timeType.private === 'flexible' ? 'flexible' : 'fixed',
                pickup: formData.timeType.pickup === 'flexible' ? 'flexible' : 'fixed',
            },
            fixedTime: formData.fixedTime,
            flexibleTimes: formData.flexibleTimes,
            price: formData.price,
            currency: formData.currency,
            includes: formData.includes,
            notIncludes: formData.notIncludes,
            location: formData.location,
            additional_info: formData.additional_info,
        };

        const activityDto = {
            hotelId: parseInt(hotelId),
            category: formData.category,
            title: formData.title,
            description: formData.description,
            activityType: formData.tourType,
            additionalOptions: additionalOptions,
            images: [],
            rating: formData.star,
            available: formData.available === 'available' ? 1 : 0,
        };

        const form = new FormData();
        form.append('activityDto', JSON.stringify(activityDto));

        formData.photos.forEach((photo) => {
            form.append('files', photo);
        });

        try {
            setLoading(true);
            const response = await axios.post(process.env.REACT_APP_API_BASE_URL + '/activity/create', form, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                    hotelId: hotelId,
                },
            });

            onAddActivity(response.data);
            onClose();
        } catch (error) {
            console.error('Failed to create activity:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleClick = (inputName) => {
        document.getElementsByName(inputName)[0].focus();
    };

    const tourType = formData.tourType;

    return (
        <div className="main-room">
            <div className="add-room-options">
                <div className="added-box">
                    <h3>Add New Activity</h3>
                    <button className="close" onClick={onClose}>
                        ×
                    </button>
                </div>
                <div className="room-form edit">
                    <form onSubmit={handleSubmit}>
                        <div className='title-box1'>
                            <div className="room-input-container">
                                <input
                                    type="text"
                                    name="title"
                                    value={formData.title}
                                    onChange={handleChange}
                                    required
                                />
                                <label className={formData.title ? 'floating' : ''}>Title</label>
                            </div>
                            <div className="room-input-container">
                                <input
                                    type="number"
                                    name="star"
                                    value={formData.star}
                                    onChange={handleChange}
                                    min="1"
                                    max="5"
                                    step="0.1"
                                />
                                <label className={formData.star ? 'floating' : ''}>Ranking</label>
                            </div>
                        </div>

                        <div className="room-input-container cap1">
                            <textarea
                                name="description"
                                style={{ width: '97%' }}
                                value={formData.description}
                                onChange={handleChange}
                            />
                            <label className={formData.description ? 'floating' : ''}>Description</label>
                        </div>

                        <div className="room-input-container ">
                            <select
                                id="category"
                                name="category"
                                value={formData.category}
                                onChange={handleChange}
                                required
                                style={{ width: '100%' }}
                            >
                                <option value="" disabled hidden></option>
                                <option value="Tours">Tours</option>
                                <option value="Cruises">Cruises</option>
                                <option value="Outdoor">Outdoor</option>
                                <option value="Sport">Sport</option>
                                <option value="Dining">Dining</option>
                                <option value="Theme Parks">Theme Parks</option>
                                <option value="Museums">Museums</option>
                                <option value="Cultural Experiences">Cultural Experiences</option>
                                <option value="Others">Others</option>
                            </select>
                            <label className={formData.category ? 'floating' : ''} onClick={() => handleClick('category')}>Category</label>
                        </div>

                        <div className="room-input-container ">
                            <select
                                id="available"
                                name="available"
                                style={{ width: '100%' }}
                                value={formData.available}
                                onChange={handleChange}
                            >
                                <option value="Available">Available</option>
                                <option value="NotAvailable">Not Available</option>
                            </select>
                            <label className={formData.available ? 'floating' : ''} onClick={() => handleClick('available')}>Availability</label>
                        </div>

                        <div className="form-group">
                            <label>Photos</label>
                            <div className="photo-gallery">
                                {formData.photos.map((photo, index) => (
                                    <div key={index} className="photo-item">
                                        <div className="image-container">
                                            {typeof photo === 'string' ? ( 
                                                <img src={photo} alt={`Activity ${index + 1}`} />
                                            ) : (
                                                <img src={URL.createObjectURL(photo)} alt={`Activity ${index + 1}`} /> 
                                            )}
                                            <div className="room-overlay">
                                                <button type='button' className="remove-btn" onClick={() => removePhoto(index)}>
                                                    –
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="file-input-container">
                                <label htmlFor="file-input" className="file-input-label">
                                    {formData.photos.length > 0 ? 'Add More Photos' : 'Choose Photos'}
                                </label>
                                <input
                                    type="file"
                                    name="photos"
                                    accept="image/*"
                                    id="file-input"
                                    className="file-input"
                                    onChange={handlePhotoChange}
                                    multiple
                                />
                            </div>
                        </div>

                        <div className="room-input-container" style={{ width: '100%' }}>
                            <select
                                name="tourType"
                                value={formData.tourType}
                                onChange={handleTourTypeChange}
                            >
                                <option value="private">Private</option>
                                <option value="pickup">Join-in</option>
                            </select>
                            <label className={formData.tourType ? 'floating' : ''}>Type</label>
                        </div>

                        <div className='scrollable-box'>
                            {formData.tourType && (
                                <>
                                    <div className='form-group-main'>
                                        <div className="room-input-container" style={{ marginTop: '15px' }}>
                                            <input
                                                type="number"
                                                style={{ width: '100%' }}
                                                value={formData.maxPeople[formData.tourType]}
                                                onChange={(e) => handleNestedChange('maxPeople', e.target.value)}
                                                required
                                            />
                                            <label className={formData.maxPeople[formData.tourType] ? 'floating' : ''}>Max Number of People</label>
                                        </div>
                                        <label>Available Days</label>
                                        <div className="date-time-container">
                                            <div className="date-column">
                                                <div className="day-picker">
                                                    {['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((day) => (
                                                        <label key={day} className="day-label">
                                                            <input
                                                                type="checkbox"
                                                                id={day}
                                                                value={day}
                                                                checked={selectedDays[formData.tourType].includes(day)}
                                                                onChange={() => handleDayChange(formData.tourType, day)}
                                                            />
                                                            <label htmlFor={day}>{day.substring(0, 3)}</label>
                                                        </label>
                                                    ))}
                                                </div>
                                            </div>

                                            <div className="time-column">
                                                <div className="form-group-row">
                                                    <div className="room-input-container" style={{ width: '100%' }}>
                                                        <label className={formData.timeType ? 'floating' : ''}>Time Type</label>
                                                        <select
                                                            value={formData.timeType[formData.tourType]}
                                                            onChange={(e) => handleNestedChange('timeType', e.target.value)}
                                                        >
                                                            <option value="flexible">Start Times (e.g. Tours)</option>
                                                            <option value="fixed">Working Hours(e.g. Golf course)</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                {formData.timeType[formData.tourType] === 'fixed' ? (
                                                    <div className="form-group-row">
                                                        <label className={formData.fixedTime ? 'floating' : ''}>Working Hours</label>
                                                        <div className="working-hours">
                                                            <DatePicker
                                                                selected={formData.fixedTime[tourType]?.startTime || null}
                                                                onChange={(date) => handleNestedChange('fixedTime', { ...formData.fixedTime[tourType], startTime: date })}
                                                                showTimeSelect
                                                                showTimeSelectOnly
                                                                timeIntervals={30}
                                                                timeCaption="Start Time"
                                                                dateFormat="h:mm aa"
                                                                placeholderText="Start Time"
                                                                className="time-input"
                                                            />
                                                            <span> - </span>
                                                            <DatePicker
                                                                selected={formData.fixedTime[tourType]?.endTime || null}
                                                                onChange={(date) => handleNestedChange('fixedTime', { ...formData.fixedTime[tourType], endTime: date })}
                                                                showTimeSelect
                                                                showTimeSelectOnly
                                                                timeIntervals={30}
                                                                timeCaption="End Time"
                                                                dateFormat="h:mm aa"
                                                                placeholderText="End Time"
                                                                className="time-input"
                                                            />
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="form-group-row">
                                                        <div className="flexible-times-header">
                                                            <label>Start times</label>
                                                            <button
                                                                type="button"
                                                                className="add-time-btn"
                                                                onClick={() => addFlexibleTime(formData.tourType)}
                                                            >
                                                                +
                                                            </button>
                                                        </div>
                                                        <div className="flexible-times-container">
                                                            {formData.flexibleTimes[formData.tourType].map((time, index) => (
                                                                <div key={index} className="time-box">
                                                                    <DatePicker
                                                                        selected={time}
                                                                        onChange={(date) => handleFlexibleTimeChange(formData.tourType, index, date)}
                                                                        showTimeSelect
                                                                        showTimeSelectOnly
                                                                        timeIntervals={30}
                                                                        timeCaption="Time"
                                                                        dateFormat="h:mm aa"
                                                                    />
                                                                    <div className="room-overlay">
                                                                        <button
                                                                            type="button"
                                                                            className="remove-btn"
                                                                            onClick={() => removeFlexibleTime(formData.tourType, index)}
                                                                        >
                                                                            –
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                )}


                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}

                            <div className="room-input-container" style={{ marginTop: '15px' }}>
                                <input
                                    type="text"
                                    style={{ width: '100%' }}
                                    value={formData.price[formData.tourType]}
                                    onChange={(e) => handleNestedChange('price', e.target.value)}
                                    required
                                />
                                <label className={formData.price[formData.tourType] ? 'floating' : ''}>Price</label>
                            </div>

                            <div className="room-input-container " style={{ marginTop: '15px' }}>
                                <select
                                    id="currency"
                                    name="currency"
                                    value={formData.currency}
                                    onChange={handleChange}
                                    required
                                    style={{ width: '100%' }}
                                >
                                    <option value="USD">USD</option>
                                    <option value="AED">AED</option>
                                </select>
                                <label className={formData.currency ? 'floating' : ''} onClick={() => handleClick('currency')}>Currency</label>
                            </div>

                            <div className="room-input-container" style={{ marginTop: '15px' }}>

                                <textarea
                                    style={{ width: '97%' }}
                                    value={formData.includes[formData.tourType]}
                                    onChange={(e) => handleNestedChange('includes', e.target.value)}

                                />
                                <label className={formData.includes[formData.tourType] ? 'floating' : ''}>
                                    Includes
                                </label>
                            </div>

                            <div className="room-input-container" style={{ marginTop: '15px' }}>

                                <textarea
                                    style={{ width: '97%' }}
                                    value={formData.notIncludes[formData.tourType]}
                                    onChange={(e) => handleNestedChange('notIncludes', e.target.value)}

                                />
                                <label className={formData.notIncludes[formData.tourType] ? 'floating' : ''}>
                                    Not Includes
                                </label>
                            </div>

                            <div className="room-input-container" style={{ marginTop: '15px' }}>
                                <input
                                    type="text"
                                    style={{ width: '100%' }}
                                    value={formData.location[formData.tourType]}
                                    onChange={(e) => handleNestedChange('location', e.target.value)}
                                    required
                                />
                                <label className={formData.location[formData.tourType] ? 'floating' : ''}>Location</label>
                            </div>

                            <div className="room-input-container" style={{ marginTop: '15px' }}>

                                <textarea
                                    style={{ width: '97%' }}
                                    value={formData.additional_info[formData.tourType]}
                                    onChange={(e) => handleNestedChange('additional_info', e.target.value)}

                                />
                                <label className={formData.additional_info[formData.tourType] ? 'floating' : ''}>
                                    Additional Informaion
                                </label>
                            </div>


                        </div>
                        <hr />
                        <div className="button-bar">
                            <button type="submit" className="save-btn">Save</button>
                            <button type="button" onClick={onClose} className="delete-button">
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AddActivity;