import React, { useState, useEffect } from 'react';
import './AdminService.css';

const EditRestaurant = ({ serviceData, onSubmit, onClose }) => {
  const [formData, setFormData] = useState(serviceData || {});
  const [selectedBreakfastType, setSelectedBreakfastType] = useState('');
  const [breakfastCategories, setBreakfastCategories] = useState({});
  const [newBreakfastType, setNewBreakfastType] = useState('');
  const [newCategoryName, setNewCategoryName] = useState('');
  const [newCategoryItems, setNewCategoryItems] = useState('');
  const [newCategoryMax, setNewCategoryMax] = useState(1);
  const [expandedCategories, setExpandedCategories] = useState({});

  useEffect(() => {
    setFormData(serviceData || {});
    if (serviceData && serviceData.breakfastOptions) {
      const categories = serviceData.breakfastOptions.reduce((acc, optionObj) => {
        const option = Object.keys(optionObj)[0];
        acc[option] = optionObj[option];
        return acc;
      }, {});
      setBreakfastCategories(categories);
      setSelectedBreakfastType(Object.keys(categories)[0] || '');
    }
  }, [serviceData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleBreakfastTypeChange = (e) => {
    setSelectedBreakfastType(e.target.value);
  };

  const handleAddBreakfastType = () => {
    if (newBreakfastType) {
      setBreakfastCategories((prevCategories) => ({
        ...prevCategories,
        [newBreakfastType]: {},
      }));
      setNewBreakfastType('');
    }
  };

  const handleAddBreakfastCategory = () => {
    if (selectedBreakfastType && newCategoryName && newCategoryItems && newCategoryMax > 0) {
      setBreakfastCategories((prevCategories) => ({
        ...prevCategories,
        [selectedBreakfastType]: {
          ...prevCategories[selectedBreakfastType],
          [newCategoryName]: {
            items: newCategoryItems.split(',').map(item => item.trim()),
            max: newCategoryMax,
          },
        },
      }));
      setNewCategoryName('');
      setNewCategoryItems('');
      setNewCategoryMax(1);
    }
  };

  const handleCategoryNameChange = (e, category) => {
    const newCategoryName = e.target.value;
    setBreakfastCategories((prevCategories) => {
      const updatedCategories = { ...prevCategories[selectedBreakfastType] };
      const categoryData = updatedCategories[category];
      delete updatedCategories[category];
      updatedCategories[newCategoryName] = categoryData;
      return {
        ...prevCategories,
        [selectedBreakfastType]: updatedCategories,
      };
    });
  };

  const handleMaxChange = (e, category) => {
    const max = parseInt(e.target.value, 10);
    if (max > 0) {
      setBreakfastCategories((prevCategories) => ({
        ...prevCategories,
        [selectedBreakfastType]: {
          ...prevCategories[selectedBreakfastType],
          [category]: {
            ...prevCategories[selectedBreakfastType][category],
            max,
          },
        },
      }));
    }
  };

  const handleOptionChange = (e, category, index) => {
    const newItems = [...breakfastCategories[selectedBreakfastType][category].items];
    newItems[index] = e.target.value;
    setBreakfastCategories((prevCategories) => ({
      ...prevCategories,
      [selectedBreakfastType]: {
        ...prevCategories[selectedBreakfastType],
        [category]: {
          ...prevCategories[selectedBreakfastType][category],
          items: newItems,
        },
      },
    }));
  };


  const handleAddOption = (category) => {
    setBreakfastCategories((prevCategories) => ({
      ...prevCategories,
      [selectedBreakfastType]: {
        ...prevCategories[selectedBreakfastType],
        [category]: {
          ...prevCategories[selectedBreakfastType][category],
          items: [...prevCategories[selectedBreakfastType][category].items, ''],
        },
      },
    }));
  };

  const handleRemoveOption = (category, index) => {
    const newItems = breakfastCategories[selectedBreakfastType][category].items.filter((_, i) => i !== index);
    setBreakfastCategories((prevCategories) => ({
      ...prevCategories,
      [selectedBreakfastType]: {
        ...prevCategories[selectedBreakfastType],
        [category]: {
          ...prevCategories[selectedBreakfastType][category],
          items: newItems,
        },
      },
    }));
  };


  const handleRemoveCategory = (category) => {
    setBreakfastCategories((prevCategories) => {
      const updatedCategories = { ...prevCategories[selectedBreakfastType] };
      delete updatedCategories[category];
      return {
        ...prevCategories,
        [selectedBreakfastType]: updatedCategories,
      };
    });
  };

  const handleToggleExpand = (category) => {
    setExpandedCategories((prevExpanded) => ({
      ...prevExpanded,
      [category]: !prevExpanded[category],
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({
      ...formData,
      breakfastOptions: Object.keys(breakfastCategories).map((option) => ({
        [option]: breakfastCategories[option],
      })),
    });
  };

  const handleClick = (inputName) => {
    document.getElementsByName(inputName)[0].focus();
  };

  return (
    <form onSubmit={handleSubmit} style={{ width: '100%', marginTop: '10px' }} id='rooms-container'>
      <div>
        <div className='row-box1'>
          <div className="room-input-container" style={{ width: '100%' }}>
            <select
              name="selectedBreakfastOption"
              value={selectedBreakfastType}
              onChange={handleBreakfastTypeChange}
              style={{ width: '50%' }}
            >
              <option value="" disabled>Select Option</option>
              {Object.keys(breakfastCategories).map((option, index) => (
                <option key={index} value={option}>{option}</option>
              ))}
            </select>
            <label className={selectedBreakfastType ? 'floating' : ''} onClick={() => handleClick('selectedBreakfastType')}>
              Breakfast Options
            </label>
          </div>
          <div className="edit-service-form">
            <div className="room-input-container" style={{ width: '100%' }}>
              <input
                type="text"
                value={newBreakfastType}
                required
                onChange={(e) => setNewBreakfastType(e.target.value)}
              />
              <label className={newBreakfastType ? 'floating' : ''}>
                New Breakfast Type
              </label>
            </div>
            <button type="button" onClick={handleAddBreakfastType}>+</button>
          </div>
        </div>

        {selectedBreakfastType && (
          <div className='item-box3'>
            <h4>{selectedBreakfastType}</h4>
            <div className='row-box2'>
              {Object.keys(breakfastCategories[selectedBreakfastType]).map((category) => (
                <div key={category} className="category-box">
                  <div className="category-header">
                    <input
                      type="text"
                      name={`${category}-name`}
                      value={category}
                      onChange={(e) => handleCategoryNameChange(e, category)}
                      className="category-name-input"
                      required
                      onFocus={() => handleToggleExpand(category)}
                    />
                    <button className="remove-button" onClick={() => handleRemoveCategory(category)}>−</button>
                  </div>
                  {expandedCategories[category] && (
                    <div>
                      <div className="room-input-container">
                        <input
                          type="number"
                          name={`${category}-max`}
                          value={breakfastCategories[selectedBreakfastType][category].max}
                          onChange={(e) => handleMaxChange(e, category)}
                          className="category-max-input"
                          required
                        />
                        <label className={breakfastCategories[selectedBreakfastType][category].max ? 'floating' : ''}>
                          Max Choose
                        </label>
                      </div>
                      {breakfastCategories[selectedBreakfastType][category].items.map((item, index) => (
                        <div key={index} className="category-header">
                          <input
                            type="text"
                            value={item}
                            onChange={(e) => handleOptionChange(e, category, index)}
                            className="item-input"
                            required
                            onFocus={() => handleToggleExpand(category)}
                          />
                          <button className="remove-button1" onClick={() => handleRemoveOption(category, index)}>−</button>
                        </div>
                      ))}
                      <button type="button" className="add-button" onClick={() => handleAddOption(category)}>+</button>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}

        <div className='item-box3'>
          <h4>Add New Category to {selectedBreakfastType}</h4>
          <div className='row-box2 ' style={{ width: '100%' }}>
            <div className="room-input-container" >
              <input
                type="text"
                value={newCategoryName}
                onChange={(e) => setNewCategoryName(e.target.value)}
                className="new-category-input"
                required
                style={{ width: '100%' }}
              />
              <label className={newCategoryName ? 'floating' : ''}>
                NewCategory
              </label>
            </div>
            <div className="room-input-container" >
              <input
                type="text"
                value={newCategoryItems}
                onChange={(e) => setNewCategoryItems(e.target.value)}
                className="new-category-items-input"
                required
                style={{ width: '100%' }}
              />
              <label className={newCategoryItems ? 'floating' : ''}>
                Items(comma separated)
              </label>
            </div>
            <div className="room-input-container" >
              <input
                type="number"
                placeholder="Max"
                value={newCategoryMax}
                onChange={(e) => setNewCategoryMax(Math.max(1, parseInt(e.target.value, 10) || 1))}
                min="1"
                className="new-category-max-input"
                required
              />
              <label className={newCategoryMax ? 'floating' : ''}>
                Max Choose
              </label>
            </div>
            <button type="button" className="add-button" onClick={handleAddBreakfastCategory}>+</button>
          </div>
        </div>
      </div>
      <hr />
      <div className="button-bar">
        <button type="submit" onClick={handleSubmit} className="save-btn">Save</button>
        <button type="button" onClick={onClose} className="delete-button">Cancel</button>
      </div>
    </form>
  );
};

export default EditRestaurant;
