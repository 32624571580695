import React, { useState, useRef, useEffect } from 'react';
import { IoClose } from "react-icons/io5";
import './SerShow.css'
import Arrow from '../../ArrowB/Arrow';
import { Link } from 'react-router-dom';
import BedService from './BedService';
import RoomCleaningService from './RoomCleaningService';
import LaundryService from './LaundryService';
import BreakfastInBedService from './BreakfastInBedService';
import WakeupService from './WakeupService';
import SpaService from './SpaService';
import axios from 'axios';
import ContentLoader from "react-content-loader"

const Services = () => {
  const [selectedService, setSelectedService] = useState(null);
  const [savedSelections, setSavedSelections] = useState({});
  const [overlay, setOverlay] = useState(false);
  const overlayRef = useRef(null);
  const [startY, setStartY] = useState(0);
  const [translateY, setTranslateY] = useState(0);
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const handleTouchStart = (e) => {
    setStartY(e.touches[0].clientY);
  };

  const handleTouchMove = (e) => {
    const deltaY = e.touches[0].clientY - startY;
    if (deltaY > 0) {
      setTranslateY(deltaY);
    }
  };

  const handleTouchEnd = () => {
    if (translateY > 100) {
      setOverlay(false);
    }
    setTranslateY(0);
  };

  const toggleOverlay = () => {
    setOverlay(!overlay);
  };

  const handleServiceClick = (service) => {
    setSelectedService(service);
    setOverlay(true);
  };

  const handleSave = (serviceId, selectedOption) => {
    setSavedSelections(prev => ({ ...prev, [serviceId]: selectedOption }));
    toggleOverlay();
  };

  const renderServiceDetails = () => {
    if (!selectedService) return null;

    const commonProps = { service: selectedService, onSave: handleSave };

    // Modify this switch statement based on how you want to determine which 
    // component to render (e.g., using the 'name' field instead of 'category')
    switch (selectedService.name) { // Or some other field to determine the component
      case 'Bed Service': // Replace with actual service names from your backend
        return <BedService {...commonProps} />;
      case 'Room Cleaning':
        return <RoomCleaningService {...commonProps} />;
      case 'Laundry':
        return <LaundryService {...commonProps} />;
      case 'Breakfast in Bed':
        return <BreakfastInBedService {...commonProps} />;
      case 'Spa Service':
        return <SpaService {...commonProps} />;
      case 'Wake Up Call':
        return <WakeupService {...commonProps} />;
      default:
        return null;
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (overlayRef.current && !overlayRef.current.contains(event.target)) {
        setOverlay(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [overlayRef]);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const token = localStorage.getItem('guestToken');
        const hotelId = localStorage.getItem('hotelId');

        const response = await axios.get(process.env.REACT_APP_API_BASE_URL + `/hotel-service/list`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            hotelId: hotelId
          }
        });

        if (response.status !== 200) { // Check for HTTP status 200 (OK)
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = response.data; // Access data directly from response.data
        setServices(data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchServices();
  }, []);

  const ServiceItemLoader = () => (
    <ContentLoader 
      speed={2}
      width={300}
      height={80}
      viewBox="0 0 300 80"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <circle cx="40" cy="40" r="25" /> 
      <rect x="80" y="30" rx="3" ry="3" width="180" height="20" /> 
    </ContentLoader>
  )

  if (loading) {
    return (
      <div className='service'>
        <Link to="/home" style={{ textDecoration: 'none', color: 'var(--primaryColor)' }}>
          <Arrow />
        </Link>
        <div className="sershow">
          <div className="ser1">
            {[...Array(4)].map((_, index) => (
              <div key={index} className="service-item">
                <ServiceItemLoader />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className='service'>
      <Link to="/home" style={{ textDecoration: 'none', color: 'var(--primaryColor)' }}><Arrow /></Link>
      <div className='sershow'>
        <div className='ser1'>
          {services.map((item) => (
            <div key={item.id} className='service-item'>
              <div className='inner' onClick={() => handleServiceClick(item)}>
                {/* Assuming your backend provides an 'img' field in the response */}
                <img src={item.imageLink} alt="service" />
                <p>{item.name}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Modal for displaying service details */}
      {overlay && (
        <div className='overlay'>
          <div
            ref={overlayRef}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
            style={{ transform: `translateY(${translateY}px)` }}
            className={`overlay1 ${overlay ? 'visible' : ''}`} onClick={toggleOverlay}>
            <div className='ov-con'>
              <div className='line'></div>
              <button className="close-button " onClick={toggleOverlay}><IoClose /></button>
              <div onClick={(e) => e.stopPropagation()}>
                {renderServiceDetails()}
              </div>
            </div>
          </div>
        </div>
      )}

    </div>
  );
};

export default Services;