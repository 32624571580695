import React, { useState, useRef, useEffect, useCallback } from 'react';
import { BiSend } from 'react-icons/bi';
import { IoCheckmarkDoneOutline } from "react-icons/io5";
import { IoMdCheckmark } from "react-icons/io";
import axios from 'axios';
import { format, parseISO } from 'date-fns';
import './Chat.css'

const FocusedChat = ({ guestId }) => {
    const token = localStorage.getItem('hotelToken');
    const [newMessage, setNewMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [guestDetails, setGuestDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const messageListRef = useRef(null);
    const inputRef = useRef(null);
    const loadingRef = useRef(false);

    const parseGuestDetails = (guestDto) => {
        return {
            roomNumber: guestDto.room,
            name: `${guestDto.firstName} ${guestDto.lastName}`
        };
    };

    const loadMessages = useCallback(async (initialLoad = false) => {
        if (!guestId || loadingRef.current || isLoading) return;

        setIsLoading(true);
        loadingRef.current = true;

        const hotelId = localStorage.getItem('hotelId');

        try {
            const response = await axios.get(process.env.REACT_APP_API_BASE_URL + `/chat/messages`, {
                params: {
                    userType: 'HOTEL',
                    page,
                    size: 50
                },
                headers: {
                    'Authorization': `Bearer ${token}`,
                    hotelId,
                    guestId,
                }
            });

            const newMessages = response.data.content.map(message => {
                return {
                    id: message.id,
                    text: message.content,
                    sent: message.messageSender === 'HOTEL',
                    timestamp: format(parseISO(message.created), 'MM/dd/yyyy hh:mm a'),
                    delivered: message.readByGuest,
                    name: message.messageSender === 'HOTEL' ? message.senderName : message.senderName
                };
            });

            setMessages(prevMessages => {
                const uniqueMessages = newMessages.filter(
                    newMsg => !prevMessages.some(prevMsg => prevMsg.id === newMsg.id)
                );
                return initialLoad ? uniqueMessages.reverse() : [...uniqueMessages.reverse(), ...prevMessages];
            });

            setHasMore(response.data.totalPages > page + 1);
            setPage(prevPage => prevPage + 1);
        } catch (error) {
            console.error('Error loading messages:', error);
        } finally {
            setIsLoading(false);
            loadingRef.current = false;
        }
    }, [guestId, page, isLoading, token]);

    const handleScroll = useCallback(() => {
        if (messageListRef.current && !isLoading && hasMore) {
            const { scrollTop, scrollHeight, clientHeight } = messageListRef.current;
            if (scrollTop === 0) {
                const currentScrollHeight = scrollHeight;
                loadMessages().then(() => {
                    if (messageListRef.current) {
                        messageListRef.current.scrollTop = messageListRef.current.scrollHeight - currentScrollHeight;
                    }
                });
            }
        }
    }, [isLoading, hasMore, loadMessages]);

    useEffect(() => {
        const messageList = messageListRef.current;
        if (messageList) {
            messageList.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (messageList) {
                messageList.removeEventListener('scroll', handleScroll);
            }
        };
    }, [handleScroll]);

    useEffect(() => {
        if (guestId) {
            setMessages([]);
            setPage(0);
            setHasMore(true);
            setGuestDetails(null);
            loadingRef.current = false;
            setIsLoading(false);

            const loadGuestDetails = async () => {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/guests/search/${guestId}`, {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        }
                    });
                    setGuestDetails(parseGuestDetails(response.data));
                } catch (error) {
                    console.error('Error loading guest details:', error);
                }
            };
            loadGuestDetails();
        }
    }, [guestId, token]);

    useEffect(() => {
        if (guestId && page === 0 && !isLoading && !loadingRef.current) {
            loadMessages(true);
        }
    }, [guestId, page, loadMessages, isLoading]);

    useEffect(() => {
        if (messageListRef.current && page === 1) {
            messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
        }
    }, [messages, page]);

    const handleInputChange = (e) => {
        setNewMessage(e.target.value);
    };

    const handleSendClick = async () => {
        if (newMessage.trim() !== '') {
            const hotelId = localStorage.getItem('hotelId');
    
            try {
                const response = await axios.post(process.env.REACT_APP_API_BASE_URL + `/chat/send`, {
                    hotelId,
                    guestId,
                    content: newMessage,
                    userType: 'HOTEL'
                }, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
    
                const sentMessage = {
                    id: response.data.id,
                    text: newMessage,
                    sent: true,
                    timestamp: format(new Date(), 'MM/dd/yyyy hh:mm a'),
                    delivered: false,
                    name: localStorage.getItem('name'),
                };
    
                setMessages(prevMessages => [...prevMessages, sentMessage]);
                setNewMessage(''); // Clear the message input
    
                // Reset the textarea height to auto
                if (inputRef.current) {
                    inputRef.current.style.height = 'auto'; // Reset height to auto
                }
    
                // Scroll to the bottom of the message list
                if (messageListRef.current) {
                    messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
                }
            } catch (error) {
                console.error('Error sending message:', error);
            }
        }
    };
    
    return (
        <div className="focused-chat">
            <div className="chat-header">
                <h2>
                    {guestDetails 
                        ? `${guestDetails.name}, Room ${guestDetails.roomNumber}` 
                        : <span className="loading-indicator">Loading guest details...</span>
                    }
                </h2>
            </div>

            <div className="chat-container">
                <div className="chat-messages" ref={messageListRef}>
                    {isLoading && <div className="loading">Loading messages...</div>}
                    {messages.map((message) => (
                        <div 
                            key={message.id} 
                            className={`message-container ${message.sent ? 'sent' : 'received'}`}
                        >
                            <div className={`message-item ${message.sent ? 'sent' : 'received'}`}>
                                <div className="message-name">{message.name}</div>
                                <div className="message-text">{message.text}</div>
                                <div className="message-timestamp">
                                    {message.timestamp}
                                    {message.sent && (
                                        message.delivered ? <IoCheckmarkDoneOutline className='sent-icon' /> : <IoMdCheckmark className="sent-icon" />
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="chat-input-container">
    <textarea
        value={newMessage}
        onChange={handleInputChange}
        ref={inputRef}
        placeholder="Drop your message here..."
        className="chat-input"
        rows={1}
        style={{
            height: 'auto',
            overflowY: 'auto', // Allow scrolling when content exceeds textarea height
            maxHeight: '150px', // Set a maximum height before it starts scrolling
        }}
        onInput={(e) => {
            e.target.style.height = 'auto'; // Reset height to auto to calculate new height
            e.target.style.height = `${Math.min(e.target.scrollHeight, 150)}px`; // Set new height with a max limit
        }}
    />
    <button onClick={handleSendClick} className="send-button">
        <BiSend />
    </button>
</div>

            </div>
        </div>
    );
};

export default FocusedChat;