import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import WakeUpCallForm from './WakeUpCallForm';
import LaundryForm from './LaundryForm.jsx';
import RoomCleaningForm from './RoomCleaningForm.jsx';
import BreakfastInBedForm from './BreakfastInBedForm.jsx';

const AddAdminService = ({ onSubmit, onClose }) => {
  const [services, setServices] = useState([]);
  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const [showWakeUpCallForm, setShowWakeUpCallForm] = useState(false);
  const [showLaundryForm, setShowLaundryForm] = useState(false);
  const [showRoomCleaningForm, setShowRoomCleaningForm] = useState(false);
  const [showBreakfastInBedForm, setShowBreakfastInBedForm] = useState(false);
  const [showSelectService, setShowSelectService] = useState(true);
  const initialClickElement = useRef(null);

  const token = localStorage.getItem('hotelToken');
  const hotelId = localStorage.getItem('hotelId');

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API_BASE_URL + '/service/list', {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
            hotelId: hotelId,
          },
        });
        setServices(response.data);
      } catch (error) {
        console.error('Error fetching services:', error);
      }
    };
    fetchServices();
  }, [hotelId, token]);

  const handleServiceSelect = (serviceId) => {
    const selectedService = services.find(service => service.id === serviceId);
    if (selectedService) {
      setSelectedServiceId(serviceId);
      setShowSelectService(false);

      if (selectedService.name === 'Wake Up Call') {
        setShowWakeUpCallForm(true);
      } else if (selectedService.name === 'Laundry') {
        setShowLaundryForm(true);
      } else if (selectedService.name === 'Room Cleaning') {
        setShowRoomCleaningForm(true);
      } else if (selectedService.name === 'Breakfast in Bed') {
        setShowBreakfastInBedForm(true);
      }
    }
  };

  const handleBackgroundMouseDown = (e) => {
    initialClickElement.current = e.target;
  };

  const handleBackgroundMouseUp = (e) => {
    if (e.target.className === 'main-room' && initialClickElement.current === e.target) {
      onClose();
    }
    initialClickElement.current = null;
  };

  return (
    <div className='main-room' onMouseDown={handleBackgroundMouseDown} onMouseUp={handleBackgroundMouseUp}>
      {showSelectService && (
        <div className="add-room-options">
          <div className='added-box'>
            <h3>Select a Service</h3>
            <button className="close" onClick={onClose}>×</button>
          </div>
          <div className="room-form m2 edit">
            <div className="services-grid">
              {services.map((service) => (
                <div
                  key={service.id}
                  className={`service-card ${selectedServiceId === service.id ? 'selected' : ''}`}
                  onClick={() => handleServiceSelect(service.id)}
                >
                  <h4>{service.name}</h4>
                  <p>{service.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {showWakeUpCallForm && (
        <WakeUpCallForm
          serviceId={selectedServiceId}
          onSubmit={onSubmit}
          onClose={() => { setShowWakeUpCallForm(false); setShowSelectService(true); }}
        />
      )}

      {showLaundryForm && (
        <LaundryForm
          serviceId={selectedServiceId}
          onSubmit={onSubmit}
          onClose={() => { setShowLaundryForm(false); setShowSelectService(true); }}
        />
      )}

      {showRoomCleaningForm && (
        <RoomCleaningForm
          serviceId={selectedServiceId}
          onSubmit={onSubmit}
          onClose={() => { setShowRoomCleaningForm(false); setShowSelectService(true); }}
        />
      )}

      {showBreakfastInBedForm && (
        <BreakfastInBedForm
          serviceId={selectedServiceId}
          onSubmit={onSubmit}
          onClose={() => { setShowBreakfastInBedForm(false); setShowSelectService(true); }}
        />
      )}
    </div>
  );
};

export default AddAdminService;