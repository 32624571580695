import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import axios from 'axios';

const OrderContext = createContext();

export const useOrders = () => useContext(OrderContext);

export const AdminOrderProvider = ({ children }) => {
  const [orders, setOrders] = useState([]);
  const [notifications, setNotifications] = useState(0);
  const [pendingOrdersCount, setPendingOrdersCount] = useState(0);
  const token = localStorage.getItem('hotelToken');
  const hotelId = localStorage.getItem('hotelId');

  const addNewOrders = (newOrders) => {
    setOrders(prevOrders => [...prevOrders, ...newOrders]);
  };

  const updateOrderStatus = async (orderId, newStatus) => {
    if (!token) {
      throw new Error('No token found');
    }

    const orderDto = {
      orderId: orderId,
      orderStatus: newStatus,
      hotelId: null,
      guestId: null,
      guestName: null,
      roomNumber: null,
      totalAmount: null,
      totalQuantity: null,
      title: null,
      locationType: null,
      created: null,
      orderDetailsDto: null
    };

    try {
      await axios.put(process.env.REACT_APP_API_BASE_URL + `/order/update`, orderDto, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      setOrders(prevOrders => 
        prevOrders.map(order => 
          order.orderId === orderId ? { ...order, orderStatus: newStatus } : order
        )
      );
    } catch (error) {
      console.error('Error updating order status', error);
      throw error;
    }
  };

  const resetNotifications = () => {
    setNotifications(0);
  };

  const fetchPendingOrdersCount = useCallback(async () => {
    if (!token || !hotelId) {
      return;
    }

    try {
      const response = await axios.get(process.env.REACT_APP_API_BASE_URL + `/orders/pending`, {
        headers: {
          Authorization: `Bearer ${token}`,
          hotelId: hotelId
        }
      });
      setPendingOrdersCount(response.data);
    } catch (error) {
      console.error('Error fetching pending orders count:', error);
    }
  }, [token, hotelId]);

  useEffect(() => {
    fetchPendingOrdersCount();
    const intervalId = setInterval(fetchPendingOrdersCount, 60000);
    return () => clearInterval(intervalId);
  }, [fetchPendingOrdersCount]);

  return (
    <OrderContext.Provider value={{
      orders,
      setOrders,
      addNewOrders,
      updateOrderStatus,
      notifications,
      setNotifications,
      resetNotifications,
      pendingOrdersCount,
      fetchPendingOrdersCount
    }}>
      {children}
    </OrderContext.Provider>
  );
};