import React, { useState, useEffect, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CustomInput from './CustomInput';
import Arrow from '../ArrowB/Arrow.jsx';
import './ActivityDetail.css';
import Swal from 'sweetalert2';
import pic from '../Assets/location.png';
import Carousel from './Carousel.jsx';
import { useOrderContext } from '../Cart/OrderContext.jsx';
import axios from 'axios';
import checked from '../Assets/All pics/checked_169780 1.png';

const ActivityDetail = () => {
  const { state } = useLocation();
  const activity = state.activity;
  const { handlePlaceOrder } = useOrderContext();
  const [selectedDateTime, setSelectedDateTime] = useState(null);
  const [numPeople, setNumPeople] = useState('');
  const [isBookingPlaced, setIsBookingPlaced] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);

  const getTomorrowDate = () => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0);
    return tomorrow;
  };

  const availableOptions = useMemo(() => {
    const options = [];
    if (activity?.additionalOptions?.price?.private) options.push('private');
    if (activity?.additionalOptions?.price?.pickup) options.push('pickup');
    return options;
  }, [activity]);

  const findNextAvailableDay = (availableDays) => {
    const tomorrow = getTomorrowDate();
    const tomorrowDay = tomorrow.getDay();
    const daysMap = {
      'Sunday': 0, 'Monday': 1, 'Tuesday': 2, 'Wednesday': 3,
      'Thursday': 4, 'Friday': 5, 'Saturday': 6
    };

    const availableDayNumbers = availableDays.map(day => daysMap[day]);
    const nextAvailableDay = availableDayNumbers.find(day => day >= tomorrowDay) || availableDayNumbers[0];
    const daysToAdd = nextAvailableDay >= tomorrowDay ? nextAvailableDay - tomorrowDay : 7 - tomorrowDay + nextAvailableDay;
    const nextDate = new Date(tomorrow);
    nextDate.setDate(tomorrow.getDate() + daysToAdd);
    return nextDate;
  };

  const getOptionData = (option) => {
    if (!activity?.additionalOptions) return null;
    return {
      price: activity.additionalOptions.price[option],
      currency: activity.additionalOptions.currency[option],
      includes: activity.additionalOptions.includes[option],
      location: activity.additionalOptions.location[option],
      timeType: activity.additionalOptions.timeType[option],
      fixedTime: activity.additionalOptions.fixedTime[option],
      maxPeople: activity.additionalOptions.maxPeople[option],
      notIncludes: activity.additionalOptions.notIncludes[option],
      availableDays: activity.additionalOptions.availableDays[option],
      flexibleTimes: activity.additionalOptions.flexibleTimes[option],
      additional_info: activity.additionalOptions.additional_info[option]
    };
  };

  const optionData = useMemo(() => getOptionData(selectedOption), [selectedOption]);

  const filterAvailableDates = (date) => {
    if (!selectedOption) return false;
    if (!optionData?.availableDays) return false;
    const tomorrow = getTomorrowDate();
    if (date < tomorrow) return false;
    const dayName = date.toLocaleDateString('en-US', { weekday: 'long' });
    return optionData.availableDays.includes(dayName);
  };

  useEffect(() => {
    if (availableOptions.length > 0 && !selectedOption) {
      setSelectedOption(availableOptions[0]);
    }
  }, [availableOptions, selectedOption]);

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    setSelectedDateTime(null);
  };

  const formatTime = (timeString) => {
    if (!timeString) return 'N/A';
    return new Date(timeString).toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });
  };

  const renderListItems = (listString, symbol) => {
    if (!listString) return null;
    return listString.split('\n').map((line, index) => (
      <p key={index}>{symbol} {line}</p>
    ));
  };

  const handlePlaceBooking = async () => {
    Swal.fire({
      title: 'Confirm Booking',
      text: `Are you sure you want to place the booking?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
      customClass: {
        confirmButton: 'custom-confirm-button',
        cancelButton: 'custom-cancel-button'
      },
      buttonsStyling: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsBookingPlaced(true);

        try {
          const hotelId = localStorage.getItem('hotelId');
          const guestId = localStorage.getItem('id');
          const token = localStorage.getItem('guestToken');

          const orderDetails = {
            totalAmount: optionData?.price * parseInt(numPeople),
            totalQuantity: parseInt(numPeople),
            orderStatus: 'Pending',
            title: activity.title,
            additionalOptions: {
              date: selectedDateTime.toISOString().split('T')[0],
              time: selectedDateTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
              quantity: numPeople,
              type: selectedOption,
            }
          };

          const response = await axios.post(process.env.REACT_APP_API_BASE_URL + `/order/place-activity`, orderDetails, {
            headers: {
              'accept': '*/*',
              'hotelId': hotelId,
              'guestId': guestId,
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          });

          if (response.status === 200) {
            handlePlaceOrder();
            Swal.fire({
              title: 'Booking Placed',
              html: `<div>You can track the status of your booking in the orders section</div>`,
              confirmButtonText: 'Close',
              iconHtml: `<img src="${checked}" style="width: 60px; height: 60px;"/>`,
              customClass: {
                icon: 'custom-icon',
                confirmButton: 'custom-confirm-button'
              },
              buttonsStyling: false,
            }).then(() => {
              const price = activity?.additionalOptions?.[selectedOption]?.price || 0;
              const newOrder = {
                id: Date.now(),
                Name: activity?.title || '',
                items: [{
                  id: activity?.id || '',
                  title: activity?.title || '',
                  price: price,
                  quantity: parseInt(numPeople)
                }],
                totalItems: parseInt(numPeople),
                totalAmount: price * parseInt(numPeople),
                orderStatus: 'Pending',
                startDate: selectedDateTime?.toISOString() || new Date().toISOString(),
                selectedOption: selectedOption,
              };
              setSelectedDateTime(null);
              setNumPeople('');
              setSelectedOption(availableOptions[0]);
              setIsBookingPlaced(false);
            });
          } else {
            console.error('Error placing order:', response.data);
            Swal.fire({
              title: 'Error',
              text: 'There was an error placing your booking. Please try again later.',
              icon: 'error'
            });
          }
        } catch (error) {
          console.error('Error placing order:', error);
          Swal.fire({
            title: 'Error',
            text: 'There was an error placing your booking. Please try again later.',
            icon: 'error'
          });
        } finally {
          setIsBookingPlaced(false);
        }
      }
    });
  };

  const isButtonDisabled = !selectedDateTime || numPeople === '';

  const generateTimeOptions = (startTime, endTime, interval) => {
    const options = [];
    const start = new Date(startTime);
    const end = new Date(endTime);

    while (start <= end) {
      options.push(start.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true }));
      start.setMinutes(start.getMinutes() + interval);
    }
    return options;
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setSelectedDateTime(prevDateTime => {
      const newDateTime = new Date(date);
      if (prevDateTime) {
        newDateTime.setHours(prevDateTime.getHours());
        newDateTime.setMinutes(prevDateTime.getMinutes());
      }
      return newDateTime;
    });
  };

  const handleTimeChange = (time) => {
    setSelectedTime(time);
    if (selectedDate) {
      const [hours, minutes, ampm] = time.split(/:| /);
      const hours24 = ampm === 'PM' && parseInt(hours) !== 12 ? parseInt(hours) + 12 :
        ampm === 'AM' && parseInt(hours) === 12 ? 0 : parseInt(hours);
      const newDateTime = new Date(selectedDate);
      newDateTime.setHours(hours24);
      newDateTime.setMinutes(parseInt(minutes));
      setSelectedDateTime(newDateTime);
    }
  };

  const calculatePrice = () => {
    if (selectedOption) {
      const optionPrice = getOptionData(selectedOption).price;
      if (numPeople !== '') {
        return `$${optionPrice * parseInt(numPeople)}`;
      }
      return `$${optionPrice}`;
    }
    return 'N/A';
  };

  return (
    <div className='activity'>
      <Link to='/activities' style={{ textDecoration: 'none', color: 'var(--primaryColor)' }}>
        <Arrow />
      </Link>
      <div className='activity-detail'>
        <div className='activity-info'>
          <Carousel images={activity.images.map(image => image.url)} />
          <h2 className='titlea'>{activity.title}</h2>
          <p className='des'>{activity.description}</p>
        </div>

        <div className="option-buttons">
          {availableOptions.map(option => {
            const label = option === 'private' ? 'Private' : 'Join-In';
            return (
              <button
                key={option}
                onClick={() => handleOptionChange(option)}
                className={selectedOption === option ? 'selected' : ''}
              >
                <h3>{label}</h3>
                <p>Price: ${getOptionData(option).price}</p>
              </button>
            );
          })}
        </div>
 
        <div className="booking-row">
          <div className="booking-item">
           
            <DatePicker
              selected={selectedDate}
              onChange={handleDateChange}
              filterDate={filterAvailableDates}
              customInput={<CustomInput />}
              dateFormat="MMMM d, yyyy"
              className="date-picker"
              placeholderText="Choose Date "
              minDate={getTomorrowDate()}
            />
          </div>
          <div className="booking-item">
           
            <select
              value={selectedTime || ''}
              onChange={(e) => handleTimeChange(e.target.value)}
              className='time-select'
            >
              <option value="" disabled>Choose Time</option>
              {optionData?.timeType === 'fixed'
                ? generateTimeOptions(
                    optionData.fixedTime.startTime,
                    optionData.fixedTime.endTime,
                    60
                  ).map((time) => (
                    <option key={time} value={time}>{time}</option>
                  ))
                : optionData?.flexibleTimes?.map((time) => (
                    <option key={time} value={formatTime(time)}>
                      {formatTime(time)}
                    </option>
                  ))
              }
            </select>
          </div>
       
        
          <div className="booking-item">
      <select
        value={numPeople}
        onChange={(e) => setNumPeople(e.target.value)} 
        className='people-select'
        disabled={isBookingPlaced}
      >
        <option value="" disabled>Number of people</option>
        {[...Array(parseInt(optionData?.maxPeople || 10)).keys()].map(num => (
          <option key={num + 1} value={num + 1}>
            {numPeople === String(num + 1) ? `Number of people: ${num + 1}` : num + 1}
          </option>
        ))}
      </select>
    </div>
          
        </div>

        {selectedOption && (
          <>
            <div className="option-details">
              {selectedOption && optionData && (
                <div className='pri-box'>
                  <h4>Activity Info</h4>
                  <div className='main-include'>
                  
                      <img src={pic} alt='leave' className='leave' />
                     
                          
                            <p>Location: {optionData.location}</p>
                        
                  
                  </div>

                  <div className='include-box'>
                    <h4>Includes</h4>
                    <p>{renderListItems(optionData.includes, '•')}</p>
                    <h4>Does Not Include</h4>
                    <p>{renderListItems(optionData.notIncludes, '•')}</p>
                  </div>
                  


                </div>
              )}
               <p className="additional-info">{activity?.additionalOptions?.additional_info?.[selectedOption]}</p>
            </div>

           



            <div >
             
              <div className='extra'></div>
            <div className='extra'></div>

            </div>
           
             <div className='overlay-lan1 '>
            <div className="price-row">
              <p>
              Total Price: 
              </p>
              <p>{calculatePrice()}</p>
            </div>
            <div className="button-row">
              <button
                onClick={handlePlaceBooking}
                disabled={isButtonDisabled}
                className={`booking-button ${isButtonDisabled ? 'disabled' : ''}`}
              >
                {isBookingPlaced ? "Booking Placed" : "Place Booking"}
              </button>
            </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ActivityDetail;