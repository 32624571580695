import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';

const EditOrderModal = ({ order, onClose, onEditOrder }) => {
    const [editedOrder, setEditedOrder] = useState({ ...order });
    const [, setIsSaving] = useState(false);
    const modalRef = useRef(null);
    const initialClickElement = useRef(null);
    const token = localStorage.getItem('hotelToken');

    useEffect(() => {
        // Set default values when order changes, ensuring totalAmount is never empty
        setEditedOrder({
          ...order,
          totalAmount: order.totalAmount || '0' // Set '0' as default if totalAmount is empty
        });
      }, [order]);
    
      const handleStatusChange = (e) => {
        setEditedOrder({ ...editedOrder, orderStatus: e.target.value });
      };
    
      const handleInputChange = (e) => {
        const { name, value } = e.target;
        // For totalAmount, convert empty string to '0'
        const processedValue = name === 'totalAmount' && value.trim() === '' ? '0' : value;
        setEditedOrder({ ...editedOrder, [name]: processedValue });
      };
    
      // Add blur handler to ensure empty values become '0'
      const handleBlur = (e) => {
        const { name, value } = e.target;
        if (name === 'totalAmount' && value.trim() === '') {
          setEditedOrder(prev => ({
            ...prev,
            totalAmount: '0'
          }));
        }
      };
    const handleSave = async () => {
        setIsSaving(true);

        try {
            if (!token) throw new Error('Token not found');

            await axios.put(process.env.REACT_APP_API_BASE_URL + `/order/update`, editedOrder, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            onEditOrder(editedOrder);
            onClose();
        } catch (error) {
            console.error('Failed to update order:', error);
        } finally {
            setIsSaving(false);
        }
    };

    const handleBackgroundMouseDown = (e) => {
        initialClickElement.current = e.target;
    };

    const handleBackgroundMouseUp = (e) => {
        if (e.target.className === 'main-room' && initialClickElement.current === e.target) {
            onClose();
        }
        initialClickElement.current = null;
    };

    return (
        <div className="main-room" ref={modalRef} onMouseDown={handleBackgroundMouseDown} onMouseUp={handleBackgroundMouseUp}>
            <div className="add-room-options">
                <div className='added-box'>
                    <h3>{order.title} Order</h3>
                    <button className="close" onClick={onClose}>×</button>
                </div>
                <div className="room-form edit">
                    <form onSubmit={(e) => { e.preventDefault(); handleSave(); }}>
                        <div className="room-input-container cap1">
                            <input
                                type="text"
                                name="title"
                                value={editedOrder.title}
                                onChange={handleInputChange}
                                required
                            />
                            <label className={editedOrder.title ? 'floating' : ''}>
                                Title
                            </label>
                        </div>

                        <div className="room-input-container cap1">
                            <input
                                type="text"
                                name="orderType"
                                value={editedOrder.orderType}
                                onChange={handleInputChange}
                                required
                            />
                            <label className={editedOrder.orderType ? 'floating' : ''}>
                                Order Type
                            </label>
                        </div>

                        {editedOrder.title !== 'Activity' && (
                            <>
                                <div className="room-input-container cap1">
                                    <input
                                        type="text"
                                        name="guestName"
                                        value={editedOrder.guestName}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    <label className={editedOrder.guestName ? 'floating' : ''}>
                                        Guest Name
                                    </label>
                                </div>
                                <div className="room-input-container cap1">
                                    <input
                                        type="text"
                                        name="roomNumber"
                                        value={editedOrder.roomNumber}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    <label className={editedOrder.roomNumber ? 'floating' : ''}>
                                        Room Number
                                    </label>
                                </div>
                                <div className="room-input-container cap1">
                                    <input
                                        type="text"
                                        name="totalQuantity"
                                        value={editedOrder.totalQuantity}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    <label className={editedOrder.totalQuantity ? 'floating' : ''}>
                                        Total Items
                                    </label>
                                </div>
                            </>
                        )}

                        {editedOrder.title === 'Activity' && (
                            <>
                                <div className="room-input-container cap1">
                                    <input
                                        type="date"
                                        name="date"
                                        value={editedOrder.date}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    <label className={editedOrder.date ? 'floating' : ''}>
                                        Date
                                    </label>
                                </div>
                                <div className="room-input-container cap1">
                                    <input
                                        type="number"
                                        name="totalPeople"
                                        value={editedOrder.totalPeople}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    <label className={editedOrder.totalPeople ? 'floating' : ''}>
                                        Total People
                                    </label>
                                </div>
                            </>
                        )}

<div className="room-input-container cap1">
      <input
        type="text"
        name="totalAmount"
        value={editedOrder.totalAmount || '0'} // Ensure '0' is shown if value is empty
        onChange={handleInputChange}
        onBlur={handleBlur}
        required
      />
      <label 
        // Modified floating logic to treat '0' as a value
        className={editedOrder.totalAmount || editedOrder.totalAmount === '0' ? 'floating' : ''}
      >
        Total Price
      </label>
    </div>

                        <div className="room-input-container">
                            <select
                                value={editedOrder.orderStatus}
                                onChange={handleStatusChange}
                            >
                                <option value="Pending">Pending</option>
                                <option value="Preparing">Preparing</option>
                                <option value="Approve" style={{ color: '#1A7242' }}>Approve</option>
                                <option value="Ready" style={{ color: '#1A7242' }}>Ready</option>
                                <option value="Declined" style={{ color: '#ff0000' }}>Declined</option>
                            </select>
                            <label className="floating">
                                Status
                            </label>
                        </div>

                        {(editedOrder.orderType === 'Service' || editedOrder.orderType === 'Activity' || editedOrder.orderType === 'Restaurant') && (
                                <>
                                    <h3 style={{ margin: '0' }}>Order Details</h3>
                                    {editedOrder.title === 'Breakfast in Bed' ? (
                                        <div className='item-row'>
                                            {editedOrder.orderDetailsDto.map(item => (
                                                <div key={item.title} className='item-box'>
                                                    <div className="item-content">
                                                        <p
                                                            style={{ textAlign: 'left' }}
                                                            dangerouslySetInnerHTML={{ __html: `${item.title.split('. ').join('.<br />')}` }}
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ) : editedOrder.title === 'Room Cleaning' ? (
                                        <div className='item-row'>
                                            {editedOrder.orderDetailsDto && editedOrder.orderDetailsDto[0] && editedOrder.orderDetailsDto[0].title ? (
                                                <div className='item-box'>
                                                    <div className="item-content">
                                                        <>
                                                        <p><strong>Time:</strong> {editedOrder.orderDetailsDto[0].title.split('Time: ')[1].split(' Services:')[0]}</p>
                                                        <p><strong>Services:</strong> {editedOrder.orderDetailsDto[0].title.split('Services: ')[1].split(' Additional Requests:')[0]}</p>
                                                        <p>{editedOrder.orderDetailsDto[0].title.includes('Additional Requests:') && editedOrder.orderDetailsDto[0].title.split('Additional Requests: ')[1] !== '' ? (
                                                                <>
                                                                    <strong>Additional Requests:</strong> {editedOrder.orderDetailsDto[0].title.split('Additional Requests: ')[1]}
                                                                </>
                                                            ) : null} 
                                                            </p>
                                                        </>
                                                    </div>
                                                </div>
                                            ) : (
                                                <span>Loading...</span>
                                            )}
                                        </div>
                                    ) : editedOrder.title === 'Laundry' ? (
                                        <div className='item-row'>
                                            {editedOrder.orderDetailsDto[0].title.split(', ').map((item, index) => {
                                                const [quantity, name] = item.split(' ');
                                                return (
                                                    <div key={index} className='item-box'>
                                                        <div className="item-content">
                                                            <p>{quantity}x {name}</p>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    ) : editedOrder.orderType === 'Activity' ? (
                                        <div className='item-row'>
                                            {editedOrder.orderDetailsDto.map(item => {
                                                return (
                                                    <div className='item-row'>
                                                      {editedOrder.orderDetailsDto.map(item => {
                                                        const parts = item.title.split('Time:');
                                                        const [typeAndPeople, dateTime] = parts;
                                                        const [typeInfo, peopleInfo] = typeAndPeople.split('Number of People:');
                                                        const type = typeInfo.replace('Type:', '').trim();
                                                        return (
                                                          <div key={item.title} className='item-box'>
                                                            <div className="item-content">
                                                              <div><strong>Type:</strong> {type}</div>
                                                              <div><strong>Number of People:</strong> {peopleInfo.trim()}</div>
                                                              <div><strong>Time:</strong> {dateTime.trim()}</div>
                                                              <div><strong>Total Price:</strong> {item.amount.toFixed(2)} USD</div>
                                                            </div>
                                                          </div>
                                                        );
                                                      })}
                                                    </div>
                                                  );
                                            })}
                                        </div>
                                    ) : ( // Исправлено здесь
                                        <div className='item-row'>
                                            {editedOrder.orderDetailsDto.map(item => (
                                                <div key={item.title} className='item-box'>
                                                    <div className="item-content">
                                                        <p>{item.itemQuantity}x {item.title}</p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </>
                            )}


                    </form>
                </div>
            </div>
        </div>
    );
};

export default EditOrderModal;