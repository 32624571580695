import React, { useState, useEffect, useCallback } from 'react';
import { IoSearchOutline } from "react-icons/io5";
import axios from 'axios';
import { debounce } from 'lodash';
import './AdminAmenity.css';
import AddAmenity from './AddAmenity.jsx';
import EditAmenity from './EditAmenity.jsx';

const placeholderImage = 'https://sansara-restaurant.s3.ap-southeast-1.amazonaws.com/dish-svgrepo-com.png';
const token = localStorage.getItem('hotelToken');

const fetchInitialAmenities = async (hotelId) => {
  if (!token || !hotelId) {
    throw new Error('No token or hotelId found');
  }

  try {
    const response = await axios.get(process.env.REACT_APP_API_BASE_URL + '/amenity/list', {
      headers: {
        Authorization: `Bearer ${token}`,
        hotelId: hotelId,
      },
      params: {
        tag: 'hotel',
        page: 0,
        size: 50,
      },
    });

    return response.data.map(amenity => ({
      id: amenity.id,
      imageLink: amenity.imageLink || placeholderImage,
      title: amenity.title,
      description: amenity.description,
      available: amenity.available === 1 ? 'ON' : 'OFF',
    }));
  } catch (error) {
    console.error('Error fetching amenities', error);
    throw error;
  }
};

const AdminAmenity = () => {
  const [amenities, setAmenities] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editAmenities, setEditAmenities] = useState(null);
  const hotelId = localStorage.getItem('hotelId');

  useEffect(() => {
    fetchInitialAmenities(hotelId).then(setAmenities).catch(console.error);
  }, [hotelId]);

  const handleAddAmenity = (newAmenity) => {
    setAmenities(prevAmenities => [newAmenity, ...prevAmenities]);
    setShowAddModal(false);
  };

  const handleEditAmenity = (updatedAmenity) => {
    setAmenities(prevAmenities =>
      prevAmenities.map(amenity =>
        amenity.id === updatedAmenity.id ? updatedAmenity : amenity
      )
    );
    setShowEditModal(false);
  };

  const openEditModal = (amenity) => {
    setEditAmenities(amenity);
    setShowEditModal(true);
  };

  const debouncedSearch = useCallback(
    async (query) => {
      if (!token || !hotelId) {
        throw new Error('No token or hotelId found');
      }

      try {
        const response = await axios.get(process.env.REACT_APP_API_BASE_URL + '/amenity/search', {
          headers: {
            Authorization: `Bearer ${token}`,
            hotelId: hotelId,
          },
          params: {
            tag: 'hotel',
            search: query,
          },
        });

        const mappedData = response.data.map(amenity => ({
          id: amenity.id,
          imageLink: amenity.imageLink || placeholderImage,
          title: amenity.title,
          description: amenity.description,
          available: amenity.available === 1 ? 'ON' : 'OFF',
        }));

        setAmenities(mappedData);
      } catch (error) {
        console.error('Error fetching amenities by search', error);
        throw error;
      }
    },
    [hotelId] // Добавляем все необходимые зависимости
  );

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    debounce((q) => debouncedSearch(q), 500)(query);
  };

  const handleDeleteAmenity = (amenityId) => {
    setAmenities(prevAmenities => prevAmenities.filter(amenity => amenity.id !== amenityId));
    console.log('Deleted Amenity ID:', amenityId);
  };

  const toggleAmenityAvailable = async (amenityId) => {
    const updatedAmenity = amenities.find(amenity => amenity.id === amenityId);
    const newAvailableValue = updatedAmenity.available === 'ON' ? 'OFF' : 'ON';

    const amenityDto = {
      ...updatedAmenity,
      hotelId: hotelId,
      available: newAvailableValue === 'ON' ? 1 : 0,
    };

    const formDataToSend = new FormData();
    formDataToSend.append('amenityDto', JSON.stringify(amenityDto));

    try {
      await axios.put(process.env.REACT_APP_API_BASE_URL + '/amenity/update', formDataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
          hotelId: hotelId,
        },
      });

      setAmenities(prevAmenities =>
        prevAmenities.map(amenity =>
          amenity.id === amenityId
            ? { ...amenity, available: newAvailableValue }
            : amenity
        )
      );
    } catch (error) {
      console.error('Failed to update amenity', error);
    }
  };

  return (
    <div className='rooms-container' id='rooms-container'>
      <div className='bar2'>
        <h1>Amenities</h1>
        <div className="room-controls">
          <div className='search-br1'>
            <p><IoSearchOutline /></p>
            <input
              type="text"
              placeholder="Type Amenity Title"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>
          <button onClick={() => setShowAddModal(true)}>+</button>
        </div>
      </div>
      <ul className="room-list">
        {amenities.map(amenity => (
          <li key={amenity.id} className='room-list1' onClick={() => openEditModal(amenity)}>
            <div className="room-info">
              <div className="room-column">
                <h4>{amenity.title}</h4>
                <div
                  className={`activity-status ${amenity.available === 'ON' ? 'on' : 'off'}`}
                  onClick={(e) => {
                    e.stopPropagation(); 
                    toggleAmenityAvailable(amenity.id);
                  }}
                >
                  {amenity.available}
                </div>
              </div>
              <div className="room-description amenities">
                <p>{amenity.description}</p>
              </div>
            </div>
          </li>
        ))}
      </ul>

      {showAddModal && (
        <AddAmenity
          onClose={() => setShowAddModal(false)}
          onAddItem={handleAddAmenity}
        />
      )}
      {showEditModal && editAmenities && (
        <EditAmenity
          amenity={editAmenities}
          onClose={() => setShowEditModal(false)}
          onEditAmenity={handleEditAmenity}
          onDeleteAmenity={handleDeleteAmenity}
        />
      )}
    </div>
  );
};

export default AdminAmenity;