import React, { useState, useRef, useEffect } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const ImageCropper = ({ image, onCropComplete, onCancel }) => {
  const [crop, setCrop] = useState(null);
  const [completedCrop, setCompletedCrop] = useState(null);
  const imgRef = useRef(null);

  useEffect(() => {
    if (image) {
      const img = new Image();
      img.onload = () => {
        const aspect = img.width / img.height;

        // Set smaller initial crop size
        let newCrop = { 
          unit: '%', 
          width: 80,   // Set this to your desired width percentage
          height: 80,  // Set this to your desired height percentage
          x: 10,       // Adjust this to center the crop
          y: 8        // Adjust this to center the crop
        };
        
        setCrop(newCrop);
        setCompletedCrop(newCrop);
      };
      img.src = image;
    }
  }, [image]);

  const getCroppedImg = async (image, crop) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        resolve(blob);
      }, 'image/jpeg');
    });
  };

  const handleCropComplete = async () => {
    if (imgRef.current && completedCrop) {
      try {
        const croppedImageBlob = await getCroppedImg(imgRef.current, completedCrop);
        const croppedImageUrl = URL.createObjectURL(croppedImageBlob);
        onCropComplete(croppedImageUrl, croppedImageBlob);
      } catch (error) {
        console.error("Error cropping image:", error);
      }
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content" style={{ maxWidth: '90vw', maxHeight: '90vh' }}>
        <h3>Crop Image</h3>
        {image && (
          <ReactCrop
            src={image}
            crop={crop}
            onImageLoaded={(img) => {
              imgRef.current = img;
            }}
            onChange={(newCrop) => setCrop(newCrop)}
            onComplete={(c) => setCompletedCrop(c)}
            style={{ maxWidth: '100%', maxHeight: 'calc(90vh - 100px)' }}
          >
            <img 
              ref={imgRef} 
              src={image} 
              alt="Crop me" 
              style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} 
            />
          </ReactCrop>
        )}
        <div className="crop-actions">
          <button onClick={handleCropComplete} className="save-crop-btn">
            Save Crop
          </button>
          <button onClick={onCancel} className="cancel-crop-btn">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default ImageCropper;
