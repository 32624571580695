import React, { useState, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import axios from 'axios';
import DatePicker from 'react-datepicker';

const formatDate = (date) => {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
};

const parseDateString = (dateString) => new Date(dateString);

const EditActivityPage = ({ activity, onClose, onSaveActivity, onDeleteActivity }) => {
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const [savingText, setSavingText] = useState('Save');

    const initialPhotos = activity?.images?.map((image) => image.url) || [];
    const [formData, setFormData] = useState({
        id: activity?.id || '',
        title: activity?.title || '',
        star: activity?.rating || '',
        description: activity?.description || '',
        category: activity?.category || '',
        available: activity?.available === 1 ? 'available' : 'NotAvailable',
        tourType: activity?.activityType || 'private',
        timeType: {
            private: activity?.additionalOptions?.timeType?.private || 'fixed',
            pickup: activity?.additionalOptions?.timeType?.pickup || 'fixed',
        },
        fixedTime: {
            private: activity?.additionalOptions?.fixedTime?.private
                ? {
                    startTime: new Date(activity.additionalOptions.fixedTime.private.startTime),
                    endTime: new Date(activity.additionalOptions.fixedTime.private.endTime),
                }
                : { startTime: null, endTime: null },
            pickup: activity?.additionalOptions?.fixedTime?.pickup
                ? {
                    startTime: new Date(activity.additionalOptions.fixedTime.pickup.startTime),
                    endTime: new Date(activity.additionalOptions.fixedTime.pickup.endTime),
                }
                : { startTime: null, endTime: null },
        },
        flexibleTimes: {
            private: activity?.additionalOptions?.flexibleTimes?.private || [],
            pickup: activity?.additionalOptions?.flexibleTimes?.pickup || [],
        },
        maxPeople: {
            private: activity?.additionalOptions?.maxPeople?.private || '',
            pickup: activity?.additionalOptions?.maxPeople?.pickup || '',
        },
        price: {
            private: activity?.additionalOptions?.price?.private || '',
            pickup: activity?.additionalOptions?.price?.pickup || '',
        },
        currency: {
            private: activity?.additionalOptions?.currency?.private || 'USD',
            pickup: activity?.additionalOptions?.currency?.pickup || 'USD',
        },
        includes: {
            private: activity?.additionalOptions?.includes?.private || '',
            pickup: activity?.additionalOptions?.includes?.pickup || '',
        },
        notIncludes: {
            private: activity?.additionalOptions?.notIncludes?.private || '',
            pickup: activity?.additionalOptions?.notIncludes?.pickup || '',
        },
        location: {
            private: activity?.additionalOptions?.location?.private || '',
            pickup: activity?.additionalOptions?.location?.pickup || '',
        },
        additional_info: {
            private: activity?.additionalOptions?.additional_info?.private || '',
            pickup: activity?.additionalOptions?.additional_info?.pickup || '',
        },
        selectedDays: {
            private: activity?.additionalOptions?.availableDays?.private || [],
            pickup: activity?.additionalOptions?.availableDays?.pickup || [],
        },
        photos: initialPhotos,
    });

    const [isStartTimeEmpty, setIsStartTimeEmpty] = useState(false);
    const [isWorkingHoursEmpty, setIsWorkingHoursEmpty] = useState(false);

    useEffect(() => {
        // Проверяем, пустые ли startTime и workingHours при загрузке страницы
        setIsStartTimeEmpty(!activity?.additionalOptions?.flexibleTimes?.[formData.tourType]?.length);
        setIsWorkingHoursEmpty(!activity?.additionalOptions?.fixedTime?.[formData.tourType]?.startTime || !activity?.additionalOptions?.fixedTime?.[formData.tourType]?.endTime);
    }, [activity, formData.tourType]);

    useEffect(() => {
        // Обновляем isStartTimeEmpty при изменении flexibleTimes
        setIsStartTimeEmpty(!formData.flexibleTimes[formData.tourType].length);
    }, [formData.flexibleTimes, formData.tourType]);

    useEffect(() => {
        // Обновляем isWorkingHoursEmpty при изменении fixedTime
        setIsWorkingHoursEmpty(!formData.fixedTime[formData.tourType].startTime || !formData.fixedTime[formData.tourType].endTime);
    }, [formData.fixedTime, formData.tourType]);

    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleNestedChange = (field, value) => {
        setFormData({
            ...formData,
            [field]: {
                ...formData[field],
                [formData.tourType]: value,
            },
        });
    };

    const handleTourTypeChange = (e) => {
        setFormData({ ...formData, tourType: e.target.value });
    };

    const handleDayChange = (tourType, day) => {
        setFormData((prevData) => {
            const updatedDays = prevData.selectedDays[tourType].includes(day)
                ? prevData.selectedDays[tourType].filter((d) => d !== day)
                : [...prevData.selectedDays[tourType], day];
            return {
                ...prevData,
                selectedDays: {
                    ...prevData.selectedDays,
                    [tourType]: updatedDays.sort(),
                },
            };
        });
    };

    const formatFlexibleTimeForSubmission = (date) => {
        if (!date) return null;

        // Если date не является объектом Date, попытаться преобразовать его
        const validDate = date instanceof Date ? date : new Date(date);

        // Проверить, является ли validDate допустимым объектом Date
        if (isNaN(validDate.getTime())) {
            // Если дата некорректна, можно вернуть null или обработать ошибку
            return null;
        }

        return validDate.toISOString(); // Или отформатируйте её согласно требованиям вашего бэкенда
    };

    const handleFlexibleTimeChange = (tourType, index, date) => {
        setFormData((prevData) => {
            const newFlexibleTimes = [...prevData.flexibleTimes[tourType]];
            newFlexibleTimes[index] = date; // Store the Date object directly
            return {
                ...prevData,
                flexibleTimes: {
                    ...prevData.flexibleTimes,
                    [tourType]: newFlexibleTimes,
                },
            };
        });
    };

    const addFlexibleTime = (tourType) => {
        setFormData((prevData) => ({
            ...prevData,
            flexibleTimes: {
                ...prevData.flexibleTimes,
                [tourType]: [...prevData.flexibleTimes[tourType], ''],
            },
        }));
    };

    const removeFlexibleTime = (tourType, index) => {
        const updatedTimes = [...formData.flexibleTimes[tourType]];
        updatedTimes.splice(index, 1);
        setFormData((prevState) => ({
            ...prevState,
            flexibleTimes: {
                ...prevState.flexibleTimes,
                [tourType]: updatedTimes,
            },
        }));
    };

    const handlePhotoChange = (e) => {
        const files = Array.from(e.target.files);
        setFormData((prevData) => ({
            ...prevData,
            photos: [...prevData.photos, ...files], // Добавляем файлы напрямую
        }));
    };

    const removePhoto = (index) => {
        setFormData((prevData) => {
            const updatedPhotos = [...prevData.photos];
            updatedPhotos.splice(index, 1); // Удаляем элемент по индексу
            return {
                ...prevData,
                photos: updatedPhotos,
            };
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSavingText('Save.');

        const token = localStorage.getItem('hotelToken');

        if (!token) {
            console.error('Token not found in localStorage');
            return;
        }

        const formatTimeForSubmission = (date) => {
            if (!date) return null;

            const dateObj = date instanceof Date ? date : new Date(date);
            const today = new Date();
            dateObj.setFullYear(today.getFullYear());
            dateObj.setMonth(today.getMonth());
            dateObj.setDate(today.getDate());

            return dateObj.toISOString();
        };

        const formatFixedTimeForType = (tourType) => {
            if (formData.timeType[tourType] === 'fixed' && formData.fixedTime[tourType]) {
                return {
                    startTime: formatTimeForSubmission(formData.fixedTime[tourType].startTime),
                    endTime: formatTimeForSubmission(formData.fixedTime[tourType].endTime)
                };
            }
            return {};
        };

        const formatFlexibleTimesForType = (tourType) => {
            return formData.flexibleTimes[tourType].map(time => formatFlexibleTimeForSubmission(time));
        };

        // Собираем все опции для обоих типов туров
        const additionalOptions = {
            maxPeople: {
                private: formData.maxPeople.private,
                pickup: formData.maxPeople.pickup
            },
            availableDays: {
                private: formData.selectedDays.private,
                pickup: formData.selectedDays.pickup
            },
            timeType: {
                private: formData.timeType.private,
                pickup: formData.timeType.pickup
            },
            fixedTime: {
                private: formatFixedTimeForType('private'),
                pickup: formatFixedTimeForType('pickup')
            },
            flexibleTimes: {
                private: formatFlexibleTimesForType('private'),
                pickup: formatFlexibleTimesForType('pickup')
            },
            price: {
                private: formData.price.private,
                pickup: formData.price.pickup
            },
            currency: {
                private: formData.currency.private,
                pickup: formData.currency.pickup
            },
            includes: {
                private: formData.includes.private,
                pickup: formData.includes.pickup
            },
            notIncludes: {
                private: formData.notIncludes.private,
                pickup: formData.notIncludes.pickup
            },
            location: {
                private: formData.location.private,
                pickup: formData.location.pickup
            },
            additional_info: {
                private: formData.additional_info.private,
                pickup: formData.additional_info.pickup
            }
        };

        const activityDto = {
            id: formData.id,
            category: formData.category,
            title: formData.title,
            description: formData.description,
            activityType: formData.tourType,
            additionalOptions: additionalOptions,
            images: formData.photos.filter((photo) => typeof photo === 'string').map(url => ({ url })),
            rating: formData.star,
            available: formData.available === 'available' ? 1 : 0,
        };

        const form = new FormData();

        formData.photos
            .filter((photo) => typeof photo !== 'string')
            .forEach((photo) => {
                form.append('files', photo);
            });

        form.append('activityDto', JSON.stringify(activityDto));

        try {
            setLoading(true);
            const response = await axios.put(
                process.env.REACT_APP_API_BASE_URL + '/activity/update',
                form,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            if (response.status === 200) {
                onSaveActivity(activityDto);
                onClose();
            } else {
                console.error('Failed to update activity:', response);
            }
        } catch (error) {
            console.error('Failed to update activity:', error);
        } finally {
            setLoading(false);
            setSavingText('Save');
        }
    };




    useEffect(() => {
        let intervalId;
        if (savingText !== 'Save') {
            intervalId = setInterval(() => {
                setSavingText(prevText => {
                    if (prevText === 'Save...') {
                        return 'Save';
                    } else {
                        return prevText + '.';
                    }
                });
            }, 500);
        }

        return () => clearInterval(intervalId);
    }, [savingText]);


    const handleClick = (inputName) => {
        document.getElementsByName(inputName)[0].focus();
    };

    const handleFixedTimeChange = (field, date) => {
        setFormData((prevData) => ({
            ...prevData,
            fixedTime: {
                ...prevData.fixedTime,
                [prevData.tourType]: {
                    ...prevData.fixedTime[prevData.tourType],
                    [field]: date,
                },
            },
        }));
    };

    const handleDeleteActivity = async () => {
        const token = localStorage.getItem('hotelToken');

        if (!token) {
            console.error('Token not found in localStorage');
            return;
        }

        try {
            const response = await axios.delete(
                `${process.env.REACT_APP_API_BASE_URL}/activity/${activity.id}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.status === 200) {
                onDeleteActivity(activity.id); // Вызываем обработчик удаления из родительского компонента
                onClose(); // Закрываем модальное окно редактирования
                setShowConfirmDeleteModal(false); // Закрываем модальное окно подтверждения 
            } else {
                console.error('Failed to delete activity:', response);
            }
        } catch (error) {
            console.error('Failed to delete activity:', error);
        }
    };

    const handleConfirmDelete = () => {
        setShowConfirmDeleteModal(true);
    };

    const handleCancelDelete = () => {
        setShowConfirmDeleteModal(false);
    };

    const handleConfirmYes = () => {
        handleDeleteActivity();
        setShowConfirmDeleteModal(false);
    };

    return (
        <div className="main-room">
            <div className="add-room-options">
                <div className="added-box">
                    <h3>Edit Activity</h3>
                    <button className="close" onClick={onClose}>
                        ×
                    </button>
                </div>
                <div className="room-form edit">
                    <form onSubmit={handleSubmit}>
                        <div className="title-box1">
                            <div className="room-input-container">
                                <input
                                    type="text"
                                    name="title"
                                    value={formData.title}
                                    onChange={handleChange}
                                    required
                                />
                                <label
                                    className={formData.title ? 'floating' : ''}
                                >
                                    Title
                                </label>
                            </div>
                            <div className="room-input-container">
                                <input
                                    type="number"
                                    name="star"
                                    value={formData.star}
                                    onChange={handleChange}
                                    min="1"
                                    max="5"
                                    step="0.1"
                                />
                                <label
                                    className={formData.star ? 'floating' : ''}
                                >
                                    Ranking
                                </label>
                            </div>
                        </div>

                        <div className="room-input-container cap1">
                            <textarea
                                name="description"
                                style={{ width: '97%' }}
                                value={formData.description}
                                onChange={handleChange}
                            />
                            <label
                                className={
                                    formData.description ? 'floating' : ''
                                }
                            >
                                Description
                            </label>
                        </div>

                        <div className="room-input-container ">
                            <select
                                id="category"
                                name="category"
                                value={formData.category}
                                onChange={handleChange}
                                required
                                style={{ width: '100%' }}
                            >
                                <option value="" disabled hidden></option>
                                <option value="Tours">Tours</option>
                                <option value="Cruises">Cruises</option>
                                <option value="Outdoor">Outdoor</option>
                                <option value="Sport">Sport</option>
                                <option value="Dining">Dining</option>
                                <option value="Theme Parks">Theme Parks</option>
                                <option value="Museums">Museums</option>
                                <option value="Cultural Experiences">
                                    Cultural Experiences
                                </option>
                                <option value="Others">Others</option>
                            </select>
                            <label
                                className={
                                    formData.category ? 'floating' : ''
                                }
                                onClick={() => handleClick('category')}
                            >
                                Category
                            </label>
                        </div>

                        <div className="room-input-container ">
                            <select
                                id="available"
                                name="available"
                                style={{ width: '100%' }}
                                value={formData.available}
                                onChange={handleChange}
                            >
                                <option value="available">Available</option>
                                <option value="NotAvailable">
                                    Not Available
                                </option>
                            </select>
                            <label
                                className={
                                    formData.available ? 'floating' : ''
                                }
                                onClick={() => handleClick('available')}
                            >
                                Availability
                            </label>
                        </div>

                        <div className="form-group">
                            <label>Photos</label>
                            <div className="photo-gallery">
                                {formData.photos.map((photo, index) => (
                                    <div key={index} className="photo-item">
                                        <div className="image-container">
                                            <img
                                                src={typeof photo === 'string' ? photo : URL.createObjectURL(photo)} // Используем URL.createObjectURL только для объектов File
                                                alt={`Activity ${index + 1}`}
                                            />
                                            <div className="room-overlay">
                                                <button type="button" className="remove-btn" onClick={() => removePhoto(index)}>
                                                    –
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="file-input-container">
                                <label
                                    htmlFor="file-input"
                                    className="file-input-label"
                                >
                                    {formData.photos.length > 0
                                        ? 'Add More Photos'
                                        : 'Choose Photos'}
                                </label>
                                <input
                                    type="file"
                                    name="photos"
                                    accept="image/*"
                                    id="file-input"
                                    className="file-input"
                                    onChange={handlePhotoChange}
                                    multiple
                                />
                            </div>
                        </div>

                        <div
                            className="room-input-container"
                            style={{ width: '100%' }}
                        >
                            <select
                                name="tourType"
                                value={formData.tourType}
                                onChange={handleTourTypeChange}
                            >
                                <option value="private" disabled={!formData.price.private}>Private</option>
                                <option value="pickup" disabled={!formData.price.pickup}>Join-in</option>
                            </select>
                            <label
                                className={formData.tourType ? 'floating' : ''}
                            >
                                Type
                            </label>
                        </div>

                        <div className="scrollable-box">
                            {formData.tourType && (
                                <>
                                    <div className="form-group-main">
                                        <div
                                            className="room-input-container"
                                            style={{ marginTop: '15px' }}
                                        >
                                            <input
                                                type="number"
                                                style={{ width: '100%' }}
                                                value={
                                                    formData.maxPeople[
                                                    formData.tourType
                                                    ]
                                                }
                                                onChange={(e) =>
                                                    handleNestedChange(
                                                        'maxPeople',
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            />
                                            <label
                                                className={
                                                    formData.maxPeople[
                                                        formData.tourType
                                                    ]
                                                        ? 'floating'
                                                        : ''
                                                }
                                            >
                                                Max Number of People
                                            </label>
                                        </div>
                                        <label>Available Days</label>
                                        <div className="date-time-container">
                                            <div className="date-column">
                                                <div className="day-picker">
                                                    {[
                                                        'Sunday',
                                                        'Monday',
                                                        'Tuesday',
                                                        'Wednesday',
                                                        'Thursday',
                                                        'Friday',
                                                        'Saturday',
                                                    ].map((day) => (
                                                        <label
                                                            key={day}
                                                            className="day-label"
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                id={day}
                                                                value={day}
                                                                checked={formData.selectedDays[
                                                                    formData
                                                                        .tourType
                                                                ].includes(day)}
                                                                onChange={() =>
                                                                    handleDayChange(
                                                                        formData
                                                                            .tourType,
                                                                        day
                                                                    )
                                                                }
                                                            />
                                                            <label htmlFor={day}>
                                                                {day.substring(
                                                                    0,
                                                                    3
                                                                )}
                                                            </label>
                                                        </label>
                                                    ))}
                                                </div>
                                            </div>

                                            <div className="time-column">
                                                <div className="form-group-row">
                                                    <div className="room-input-container" style={{ width: '100%' }}>
                                                        <label className={formData.timeType ? 'floating' : ''}>Time Type</label>
                                                        <select
                                                            value={formData.timeType[formData.tourType]}
                                                            onChange={(e) => handleNestedChange('timeType', e.target.value)}
                                                            disabled={isStartTimeEmpty || isWorkingHoursEmpty} // Disable if either is empty
                                                        >
                                                            <option value="flexible" disabled={formData.timeType[formData.tourType] === 'fixed'}>
                                                                Start Times (e.g. Tours)
                                                            </option>
                                                            <option value="fixed" disabled={formData.timeType[formData.tourType] === 'flexible'}>
                                                                Working hours(e.g. Golf course)
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>

                                                {formData.timeType[formData.tourType] === 'fixed' ? (
                                                    <div className="form-group-row">
                                                        <label className={formData.fixedTime ? 'floating' : ''}>Working hours</label>
                                                        <div className="working-hours">
                                                            <DatePicker
                                                                selected={formData.fixedTime[formData.tourType].startTime}
                                                                onChange={(date) => handleFixedTimeChange('startTime', date)}
                                                                showTimeSelect
                                                                showTimeSelectOnly
                                                                timeIntervals={30}
                                                                timeCaption="Start Time"
                                                                dateFormat="h:mm aa"
                                                                placeholderText="Start Time"
                                                                className="time-input"
                                                            />
                                                            <span> - </span>
                                                            <DatePicker
                                                                selected={formData.fixedTime[formData.tourType].endTime}
                                                                onChange={(date) => handleFixedTimeChange('endTime', date)}
                                                                showTimeSelect
                                                                showTimeSelectOnly
                                                                timeIntervals={15}
                                                                timeCaption="End Time"
                                                                dateFormat="h:mm aa"
                                                                placeholderText="End Time"
                                                                className="time-input"
                                                            />
                                                        </div>
                                                    </div>
                                                ) : (
                                                    formData.timeType[formData.tourType] === 'flexible' && ( // <-- Only render if timeType is 'flexible'
                                                        <div className="form-group-row">
                                                            <div className="flexible-times-header">
                                                                <label>Start times</label>
                                                                <button
                                                                    type="button"
                                                                    className="add-time-btn"
                                                                    onClick={() => addFlexibleTime(formData.tourType)}
                                                                >
                                                                    +
                                                                </button>
                                                            </div>
                                                            <div className="flexible-times-container">
                                                                {formData.flexibleTimes[formData.tourType].map((time, index) => (
                                                                    <div key={index} className="time-box">
                                                                        <DatePicker
                                                                            selected={time ? new Date(time) : null} // Convert string to Date or default to null
                                                                            onChange={(date) => handleFlexibleTimeChange(formData.tourType, index, date)}
                                                                            showTimeSelect
                                                                            showTimeSelectOnly
                                                                            timeIntervals={30}
                                                                            timeCaption="Time"
                                                                            dateFormat="h:mm aa"
                                                                            placeholderText="HH:MM AM/PM"
                                                                            className="time-input"
                                                                        />
                                                                        <div className="room-overlay">
                                                                            <button
                                                                                type="button"
                                                                                className="remove-btn"
                                                                                onClick={() => removeFlexibleTime(formData.tourType, index)}
                                                                            >
                                                                                –
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}

                            <div
                                className="room-input-container"
                                style={{ marginTop: '15px' }}
                            >
                                <input
                                    type="text"
                                    style={{ width: '100%' }}
                                    value={formData.price[formData.tourType]}
                                    onChange={(e) =>
                                        handleNestedChange(
                                            'price',
                                            e.target.value
                                        )
                                    }
                                    required
                                />
                                <label
                                    className={
                                        formData.price[formData.tourType]
                                            ? 'floating'
                                            : ''
                                    }
                                >
                                    Price
                                </label>
                            </div>

                            <div
                                className="room-input-container "
                                style={{ marginTop: '15px' }}
                            >
                                <select
                                    id="currency"
                                    name="currency"
                                    value={formData.currency[formData.tourType]}
                                    onChange={(e) =>
                                        handleNestedChange(
                                            'currency',
                                            e.target.value
                                        )
                                    }
                                    required
                                    style={{ width: '100%' }}
                                >
                                    <option value="USD">USD</option>
                                    <option value="AED">AED</option>
                                </select>
                                <label
                                    className={
                                        formData.currency[formData.tourType]
                                            ? 'floating'
                                            : ''
                                    }
                                    onClick={() => handleClick('currency')}
                                >
                                    Currency
                                </label>
                            </div>

                            <div
                                className="room-input-container"
                                style={{ marginTop: '15px' }}
                            >
                                <textarea
                                    style={{ width: '97%' }}
                                    value={
                                        formData.includes[formData.tourType]
                                    }
                                    onChange={(e) =>
                                        handleNestedChange(
                                            'includes',
                                            e.target.value
                                        )
                                    }
                                />
                                <label
                                    className={
                                        formData.includes[formData.tourType]
                                            ? 'floating'
                                            : ''
                                    }
                                >
                                    Includes
                                </label>
                            </div>

                            <div
                                className="room-input-container"
                                style={{ marginTop: '15px' }}
                            >
                                <textarea
                                    style={{ width: '97%' }}
                                    value={
                                        formData.notIncludes[formData.tourType]
                                    }
                                    onChange={(e) =>
                                        handleNestedChange(
                                            'notIncludes',
                                            e.target.value
                                        )
                                    }
                                />
                                <label
                                    className={
                                        formData.notIncludes[formData.tourType]
                                            ? 'floating'
                                            : ''
                                    }
                                >
                                    Not Includes
                                </label>
                            </div>

                            <div
                                className="room-input-container"
                                style={{ marginTop: '15px' }}
                            >
                                <input
                                    type="text"
                                    style={{ width: '100%' }}
                                    value={
                                        formData.location[formData.tourType]
                                    }
                                    onChange={(e) =>
                                        handleNestedChange(
                                            'location',
                                            e.target.value
                                        )
                                    }
                                    required
                                />
                                <label
                                    className={
                                        formData.location[formData.tourType]
                                            ? 'floating'
                                            : ''
                                    }
                                >
                                    Location
                                </label>
                            </div>

                            <div
                                className="room-input-container"
                                style={{ marginTop: '15px' }}
                            >
                                <textarea
                                    style={{ width: '97%' }}
                                    value={
                                        formData.additional_info[
                                        formData.tourType
                                        ]
                                    }
                                    onChange={(e) =>
                                        handleNestedChange(
                                            'additional_info',
                                            e.target.value
                                        )
                                    }
                                />
                                <label
                                    className={
                                        formData.additional_info[
                                            formData.tourType
                                        ]
                                            ? 'floating'
                                            : ''
                                    }
                                >
                                    Additional Informaion
                                </label>
                            </div>
                        </div>
                        <hr />
                        <div className="button-bar">
                            <button type="submit" className="save-btn">{savingText}</button>
                            <button
                                type="button"
                                onClick={handleConfirmDelete}
                                className="delete-button"
                            >
                                Delete
                            </button>

                            {showConfirmDeleteModal && (
                                <div className="modal" onClick={handleCancelDelete}>
                                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                                        <h3>Are you sure you want to delete this activity?</h3>
                                        <div className="button-bar">
                                            <button onClick={handleConfirmYes} className="delete-button">Yes</button>
                                            <button onClick={handleCancelDelete} className="save-btn">No</button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default EditActivityPage;
