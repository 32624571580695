import React, { useState, useEffect, useRef, useContext } from 'react';
import './AdminNav.css';
import hotel from '../../../All pics/image1.png';
import { IoMdMenu } from "react-icons/io";
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import logo from '../../../../Assets/logo.png';
import { useNotification } from '../../Chat/NotificationContext';
import { useOrders } from '../../AdminOrders/AdminOrderContext.jsx';
import { AuthContext } from '../../../../Assets/MainAdminLAyout/Authorize/AuthContext.jsx';

const AdminNav = () => {
    const { notificationCount } = useNotification();
    const [dropdown, setDropdown] = useState(false);
    const [dropdownLogo, setDropdownLogo] = useState(false);
    const dropdownLogoRef = useRef(null);
    const dropdownRef = useRef(null);
    const { pendingOrdersCount, resetNotifications, fetchPendingOrdersCount } = useOrders();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 900);
    const { logout } = useContext(AuthContext);
    const location = useLocation();

    const handleDropdown = () => {
        setDropdown(!dropdown);
    };
    const handleDropdownLogo = () => {
        setDropdownLogo(!dropdownLogo);
    };
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdown(false);
        }
        if (dropdownLogoRef.current && !dropdownLogoRef.current.contains(event.target) && !event.target.closest('.main-logo')) {
            setDropdownLogo(false);
        }
    };

    const handleClick = () => {
        resetNotifications();
    };

    const handleLogout = () => {
        logout(); // Call the logout function from AuthContext
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 900);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    
    useEffect(() => {
        fetchPendingOrdersCount();
    }, [location.pathname, fetchPendingOrdersCount]); // Добавили fetchPendingOrdersCount
    
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 900);
        };
        window.addEventListener('resize', handleResize);
    
        // Обновляем счетчик заказов при монтировании компонента и при обновлении страницы
        fetchPendingOrdersCount();
    
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [fetchPendingOrdersCount]); // Добавили fetchPendingOrdersCount

    return (
        <nav className="nav">
            <div className="navbar-logo">
                <img src={logo} alt="Logo" /> {/* Replace with your logo path */}
            </div>
            {!isMobile && (
                <ul className="main-nav">
                    <li>
                        <NavLink to='/dashboard' className={({ isActive }) => isActive ? 'active-link' : ''}>Dashboard</NavLink>
                    </li>
                    <li>
                        <NavLink to='/rooms' className={({ isActive }) => isActive ? 'active-link' : ''}>Rooms</NavLink>
                    </li>
                    <li>
                        <NavLink to='/guests' className={({ isActive }) => isActive ? 'active-link' : ''}>Guests</NavLink>
                    </li>
                    <li>
                        <NavLink to='/restaurant' className={({ isActive }) => isActive ? 'active-link' : ''}>Restaurant</NavLink>
                    </li>
                    <li> <NavLink to='/adminservice' className={({ isActive }) => isActive ? 'active-link' : ''}>Service</NavLink></li>
                    <li>
                        <NavLink to='/chat' className={({ isActive }) => isActive ? 'active-link' : ''}>Chat</NavLink>
                        {notificationCount > 0 && <span className="notification-dot2">{notificationCount}</span>}
                    </li>
                    <li onClick={handleClick}>
                        <NavLink to='/adminorders' className={({ isActive }) => isActive ? 'active-link' : ''}>Orders</NavLink>
                        {pendingOrdersCount > 0 && <span className="notification-dot2">{pendingOrdersCount}</span>}
                    </li>
                </ul>
            )}
            <div className="dropdown">
                <div onClick={handleDropdown} ref={dropdownRef}>
                    <IoMdMenu className='menu-icon' />
                    <div className={`dropdown-content ${dropdown ? 'show' : ''}`}>
                        {isMobile && (
                            <>
                                <NavLink to='/dashboard' className={({ isActive }) => isActive ? 'active-link' : ''}>Dashboard</NavLink>
                                <NavLink to='/rooms' className={({ isActive }) => isActive ? 'active-link' : ''}>Rooms</NavLink>
                                <NavLink to='/guests' className={({ isActive }) => isActive ? 'active-link' : ''}>Guests</NavLink>
                                <NavLink to='/restaurant' className={({ isActive }) => isActive ? 'active-link' : ''}>Restaurant</NavLink>
                                <NavLink to='/adminservice' className={({ isActive }) => isActive ? 'active-link' : ''}>Service</NavLink>
                                <NavLink to='/chat' className={({ isActive }) => isActive ? 'active-link' : ''}>Chat</NavLink>
                                <NavLink to='/adminorders' className={({ isActive }) => isActive ? 'active-link' : ''}>Orders</NavLink>
                            </>
                        )}
                        <NavLink to='/mainactivities' className={({ isActive }) => isActive ? 'active-link' : ''}>Activities</NavLink>
                        <NavLink to='/amenities' className={({ isActive }) => isActive ? 'active-link' : ''}>Amenities</NavLink>
                    </div>
                </div>
                <div className="main-logo" onClick={handleDropdownLogo} ref={dropdownLogoRef}>
                    <img src={hotel} alt="Logo" />

                    <div className={`dropdown-content ${dropdownLogo ? 'show' : ''}`} >
                        <NavLink to='/hotel-profile' className={({ isActive }) => isActive ? 'active-link' : ''}>Profile</NavLink>
                        <NavLink to='/faq' className={({ isActive }) => isActive ? 'active-link' : ''}>FAQ</NavLink>
                        <a href="#" onClick={handleLogout}>Logout</a>
                    </div>
                </div>

            </div>
        </nav>
    );
};

export default AdminNav;

