import React, { useContext, useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate, Navigate } from 'react-router-dom';
import { AuthProvider, AuthContext } from './Component/Assets/MainAdminLAyout/Authorize/AuthContext.jsx';
import NavBar from './Component/Nav/NavBar.jsx';
import Home from './Component/Home/Home.jsx';
import ResB from './Component/Pages/ResB.jsx';
import ActivityDetail from './Component/ADetail/ActivityDetail.jsx';
import Menucategory from './Component/MenuCatgory/Menucategory.jsx';
import Cart from './Component/Cart/Cart.jsx';
import Services from './Component/Res/SerShow/Services.jsx';
import Facilities from './Component/Pages/Faci/Facilities.jsx';
import Search from './Component/Pages/Search.jsx';
import Near from './Component/Pages/Near/Near.jsx';
import Message from './Component/Pages/Message/Message.jsx';
import Orders from './Component/Cart/Orders.jsx';
import Activities from './Component/Assets/Activities.js';
import Box from './Component/Box/Box.jsx';
import Service from './Component/Assets/Service.js';
import Authorize from './Component/Assets/MainAdminLAyout/Authorize/Authorize.jsx';
import MainAdmin from './Component/Assets/MainAdminLAyout/Authorize/MainAdmin/MainAdmin.jsx';
import Rooms from './Component/Assets/MainAdminLAyout/Room/Rooms.jsx';
import EditRoomPage from './Component/Assets/MainAdminLAyout/AdminLayout/EditRoomPage.jsx';
import AddRoom from './Component/Assets/MainAdminLAyout/AdminLayout/AddRoom.jsx';
import AdminNav from './Component/Assets/MainAdminLAyout/Authorize/AdminNav/AdminNav.jsx';
import Guests from './Component/Assets/MainAdminLAyout/Guests/Guests.jsx';
import Restaurant from './Component/Assets/MainAdminLAyout/Restaurant/Restaurant.jsx';
import Chat from './Component/Assets/MainAdminLAyout/Chat/Chat.jsx';
import AdminOrders from './Component/Assets/MainAdminLAyout/AdminOrders/AdminOrders.jsx';
import MainActivities from './Component/Assets/MainAdminLAyout/MainActivities/MainActivities.jsx';
import AdminService from './Component/Assets/MainAdminLAyout/AdminService/AdminService.jsx';
import AdminAmenity from './Component/Assets/MainAdminLAyout/AdminAmenity/AdminAmenity.jsx';
import HotelProfile from './Component/Assets/MainAdminLAyout/HotelProfile/HotelProfile.jsx';
import FAQ from './Component/Assets/MainAdminLAyout/FAQ/FAQ.jsx';
import LoginRoom from './Component/Assets/MainAdminLAyout/AuthGuest/LoginRoom.jsx';
import LoginGuest from './Component/Assets/MainAdminLAyout/AuthGuest/LoginGuest.jsx';
import Dashboard from './Component/Assets/Dashboard/Dashboard.jsx';
import MailNew from './Component/Assets/Landing/MailNew.jsx';

const showNavBarRoutes = [
  '/home',
  '/cart',
  '/service',
  '/nearus',
  '/orders',

  // Add other routes where NavBar should be shown
];

const adminNavRoutes = [
  '/dashboard',
  '/rooms',
  '/guests',
  '/restaurant',
  '/chat',
  '/adminorders',
  '/mainactivities',
  '/adminservice',
  '/amenities',
  '/hotel-profile',
  '/faq',
];

const shouldShowNavBar = (pathname) => {
  return showNavBarRoutes.includes(pathname);
};

const showadminNavBar = (pathname) => {
  return adminNavRoutes.some(route => pathname.startsWith(route));
};

const ProtectedRoute = ({ element, ...rest }) => {
  const { isAuthenticated } = useContext(AuthContext);
  return isAuthenticated() ? element : <Navigate to="/authorize" />;
};

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isLoggedIn, loading, login } = useContext(AuthContext);
  const [showNavbar, setShowNavbar] = useState(true);

  useEffect(() => {
    console.log("Current pathname: ", location.pathname);
    setShowNavbar(!showNavBarRoutes.includes(location.pathname));
    setShowNavbar(!adminNavRoutes.includes(location.pathname));
  }, [location]);

  const handleLogin = async (email, password) => {
    try {
      const response = await fetch(process.env.REACT_APP_API_BASE_URL + `/login-hotel`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password: Array.from(password) })
      });
  
      if (response.ok) {
        const data = await response.json();
        localStorage.setItem('name', data.name);
        localStorage.setItem('linkToMap', data.linkToMap);
        localStorage.setItem('numberOfRooms', data.numberOfRooms);
        localStorage.setItem('description', data.description);
        localStorage.setItem('phoneNumber', data.phoneNumber);
        localStorage.setItem('email', data.email);
        localStorage.setItem('website', data.website);
        console.log("Login response data: ", data);
        login(null, data.hotelToken, data.id); // Передаем null, token и hotelId
        navigate('/dashboard'); // Navigate only on successful login
      } else {
        const errorData = await response.json();
        alert(errorData.message);
      }
    } catch (error) {
      console.error('Login error', error);
      alert('Login failed');
    }
  };

  const handleSignup = async (hotelName, email, password) => {
    try {
      const response = await fetch(process.env.REACT_APP_API_BASE_URL + `/register-hotel`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ name: hotelName, email, password })
      });
      const data = await response.json();
      if (response.ok) {
        localStorage.setItem('name', data.name);
        localStorage.setItem('linkToMap', data.linkToMap);
        localStorage.setItem('numberOfRooms', data.numberOfRooms);
        localStorage.setItem('description', data.description);
        localStorage.setItem('phoneNumber', data.phoneNumber);
        localStorage.setItem('email', data.email);
        localStorage.setItem('website', data.website);
        console.log("Signup response data: ", data);
        login(null, data.hotelToken, data.id); // Передаем null, token и hotelId
        navigate('/dashboard'); // Navigate only on successful login
      } else {
        alert(data.message);
      }
    } catch (error) {
      console.error('Signup error', error);
      alert('Signup failed');
    }
  };

  if (loading) {
    return <div>Loading...</div>; // Show a loading indicator while checking auth state
  }

  return (
    <div className="App">
      {shouldShowNavBar(location.pathname) && <NavBar />}
      {showadminNavBar(location.pathname) && <AdminNav />}
      <Routes>
        <Route path="/home" element={<ProtectedRoute element={<Home />} />} />
        <Route path="/activities" element={<Box activities={Activities} />} />
        <Route path="/activities/:id" element={<ActivityDetail activities={Activities} />} />
        <Route path="/basket" element={<ResB />} />
        <Route path="/facilities" element={<Facilities />} />
        <Route path='/nearus' element={<Near />} />
        <Route path='/messages' element={<Message />} />
        <Route path="/orders" element={<Orders />} />
        <Route path='/appetizer' element={<Menucategory category="appetizer" />} />
        <Route path='/fast-food' element={<Menucategory category="fast food" />} />
        <Route path='/drinks' element={<Menucategory category="drinks" />} />
        <Route path='/alcohol' element={<Menucategory category="alcohol" />} />
        <Route path='/main-dishes' element={<Menucategory category="Main Courses" />} />
        <Route path='/salads' element={<Menucategory category="salads" />} />
        <Route path='/desserts' element={<Menucategory category="desserts" />} />
        <Route path='/soups' element={<Menucategory category="soups" />} />
        <Route path='/all' element={<Menucategory category="all" />} />
        <Route path='/search' element={<Search />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/authorize" element={<Authorize onLogin={handleLogin} onSignup={handleSignup} />} />
        <Route path='/service' element={<Services />} />
        <Route path="/dashboard" element={isLoggedIn ? <Dashboard /> : <Navigate to="/authorize" />} />
        <Route path="/rooms" element={isLoggedIn ? <Rooms /> : <Navigate to="/authorize" />} />
        <Route path="/guests" element={isLoggedIn ? <Guests /> : <Navigate to="/authorize" />} />
        <Route path="/restaurant" element={isLoggedIn ? <Restaurant /> : <Navigate to="/authorize" />} />
        <Route path='/chat' element={isLoggedIn ? <Chat /> : <Navigate to="/authorize" />} />
        <Route path='/chat/:guestId' element={isLoggedIn ? <Chat /> : <Navigate to="/authorize" />} />
        <Route path='/adminorders' element={isLoggedIn ? <AdminOrders /> : <Navigate to="/authorize" />} />
        <Route path='/mainactivities' element={isLoggedIn ? <MainActivities /> : <Navigate to="/authorize" />} />
        <Route path='/adminservice' element={isLoggedIn ? <AdminService /> : <Navigate to="/authorize" />} />
        <Route path='/amenities' element={isLoggedIn ? <AdminAmenity /> : <Navigate to="/authorize" />} />
        <Route path='/hotel-profile' element={isLoggedIn ? <HotelProfile /> : <Navigate to="/authorize" />} />
        <Route path='/faq' element={isLoggedIn ? <FAQ /> : <Navigate to="/authorize" />} />
        <Route path="/login-room" element={<LoginRoom/>} />
        <Route path="/login-guest" element={<LoginGuest/>} />
        <Route path="/mail" element={<MailNew/>} />
        
      </Routes>
    </div>
  );
};

const AppWrapper = () => (
  <AuthProvider>
    <App />
  </AuthProvider>
);

export default AppWrapper;
