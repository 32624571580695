import React, { useState } from 'react';
import Swal from 'sweetalert2';
import './SerShow.css';
import checked from '../../Assets/All pics/checked_169780 1.png'

const SpaService = ({ service, onSave }) => {
  const [bookingTime, setBookingTime] = useState('');
  const [bookingName, setBookingName] = useState('');
  const [isBookingOpen, setIsBookingOpen] = useState(false);
  const [bookings, setBookings] = useState([]);

  const handleBookingTimeChange = (event) => {
    setBookingTime(event.target.value);
  };

  const handleBookingNameChange = (event) => {
    setBookingName(event.target.value);
  };

  const handleBookAppointment = () => {
    if (!bookingTime || !bookingName) {
      Swal.fire({
        title: 'Error',
        text: 'Please enter both booking time and name.',
        icon: 'error',
        confirmButtonText: 'Close'
      });
      return;
    }

    const newBooking = { time: bookingTime, name: bookingName };
    setBookings([...bookings, newBooking]);

    Swal.fire({
      title: 'Booking Successful',
      html: `<div>Your booking for ${bookingTime} has been confirmed for ${bookingName}.</div>`,
      confirmButtonText: 'Close',
      iconHtml: `<img src="${checked}" style="width: 60px; height: 60px; border: none; box-shadow: none; border-radius: 0;"/>`,
      customClass: {
        icon: 'custom-icon',
        confirmButton: 'custom-confirm-button'
      },
      buttonsStyling: false,
    }).then(() => {
      setBookingTime('');
      setBookingName('');
    });
  };

  const handleOpenBooking = () => {
    const currentTime = new Date().getHours();
    if (currentTime < 9 || currentTime >= 21) {
      Swal.fire({
        title: 'Spa is Closed',
        text: 'Spa opens at 9 AM and closes at 9 PM.',
        icon: 'warning',
        confirmButtonText: 'Close'
      });
      return;
    }

    if (bookings.length >= 10) { // Adjust the number based on actual capacity
      Swal.fire({
        title: 'Fully Booked',
        text: 'Our spa is fully booked at the moment.',
        icon: 'info',
        confirmButtonText: 'Close'
      });
      return;
    }

    setIsBookingOpen(true);
  };

  const isButtonDisabled = !bookingTime || !bookingName;

  return (
    <div className='spa-main' >
      <label>
        <h3 className='title-box'>{service.title}</h3>
        <p className='lan-p'>(Open time between 9am to 8pm)</p>
      </label>
      <div >
        {isBookingOpen ? (
          <div >
            <label>Select Booking Time:</label>
            <input
              type="text"
              value={bookingTime}
              onChange={handleBookingTimeChange}
              placeholder="HH:MM AM/PM"
              className="enter-name"
            />
            <label>Enter Your Name:</label>
            <input
              type="text"
              value={bookingName}
              onChange={handleBookingNameChange}
              placeholder="Your Name"
              className="enter-name"
            />
            <button
              className={`bed-button ${isButtonDisabled ? '' : 'active'}`}
              disabled={isButtonDisabled}
              onClick={handleBookAppointment}
            >
              Book Appointment
            </button>
          </div>
        ) : (
          <button onClick={handleOpenBooking} className='bed-button' >Open Booking</button>
        )}
      </div>
    </div>
  );
};

export default SpaService;
