import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import BarA from '../Pages/BarA.jsx';
import Foods from '../Pages/Foods.jsx';
import Resbar from '../Pages/Resbar.jsx';
import Arrow from '../ArrowB/Arrow.jsx';
import Searchbar from '../Pages/Searchbar.jsx';
import { getCartItems } from '../Cart/CartService';
import './Menucategory.css';
import ContentLoader from "react-content-loader";

const Menucategory = ({ category }) => {
  const [foods, setFoods] = useState([]);
  const [page, setPage] = useState(0);
  const [size] = useState(50);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [, setCart] = useState(null);
  const observer = useRef();
  const prevCategoryRef = useRef();

  const token = localStorage.getItem('guestToken');
  const hotelId = localStorage.getItem('hotelId');
  const guestId = localStorage.getItem('id');

  const saveFoodIdsToLocalStorage = (foods) => {
    const foodIds = foods.map(food => food.id);
    localStorage.setItem('foodIds', JSON.stringify(foodIds));
  };

  const fetchFoods = useCallback(async () => {
    if (loading || !hasMore) return;

    setLoading(true);
    try {
      const response = await fetch(process.env.REACT_APP_API_BASE_URL + `/restaurant/${category}?tag=guest&page=${page}&size=${size}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'hotelId': hotelId,
          'guestId': guestId
        },
      });
      const data = await response.json();
      saveFoodIdsToLocalStorage(data);

      if (data.length > 0) {
        setFoods(prevFoods => (page === 0 ? data : [...prevFoods, ...data]));
        setPage(prevPage => prevPage + 1);
      }

      if (data.length < size) {
        setHasMore(false);
      }
    } catch (error) {
      console.error('Error fetching food data:', error);
    } finally {
      setLoading(false);
    }
  }, [category, page, size, token, hotelId, guestId, loading, hasMore]);

  const fetchCart = useCallback(async () => {
    const cartData = await getCartItems();
    if (cartData) {
      setCart(cartData);
    } else {
      setCart(null);
    }
  }, []);

  useEffect(() => {
    if (category !== prevCategoryRef.current) {
      setFoods([]);
      setPage(0);
      setHasMore(true);
      prevCategoryRef.current = category;
    }
    fetchCart();
    fetchFoods();
  }, [category, fetchCart, fetchFoods]);

  const lastFoodElementRef = useCallback(node => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        fetchFoods();
      }
    });
    if (node) observer.current.observe(node);
  }, [loading, hasMore, fetchFoods]);

  const renderShimmer = () => (
    <div className="mainp" style={{ marginTop: "0px" }}>
      {[...Array(6)].map((_, index) => (
     
        <div className="foo" key={index}>
          <div className="food-container">
            <div className="foodi">
              <div className="loader">
                <ContentLoader
                  viewBox="0 0 210 285"
                  height={285}
                  width={210}
                >
                  <rect x="0" y="0" rx="8" ry="8"  />
                  <rect x="5" y="200" rx="4" ry="4" width="70" height="20" />
                  <rect x="5" y="230" rx="3" ry="3" width="120" height="25" />
                  <rect x="5" y="265" rx="3" ry="3" width="80" height="10" />
                </ContentLoader>
              </div>
           
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <div className='menu-category'>
      <Link to="/home" style={{ textDecoration: 'none', color: 'var(--primaryColor)' }}><Arrow /></Link>
      <Searchbar />
      <div className='mainpage'>
        <Resbar />
        <div className='mainp'>
          {foods.map((item, index) => {
            const uniqueKey = `${item.id}-${category}-${index}`;
            if (foods.length === index + 1) {
              return (
                <div ref={lastFoodElementRef} key={uniqueKey}>
                  <Foods
                    id={item.id}
                    image={item.imageLink}
                    title={item.title}
                    des={item.description}
                    recommended={item.recommended}
                    price={item.price.toFixed(2)}
                    quantityInCart={item.quantityInCart}
                  />
                </div>
              );
            } else {
              return (
                <Foods
                  key={uniqueKey}
                  id={item.id}
                  image={item.imageLink}
                  title={item.title}
                  des={item.description}
                  recommended={item.recommended}
                  price={item.price.toFixed(2)}
                  quantityInCart={item.quantityInCart}
                />
                
              );
            }
          })}
        
         
        </div>
        
        {loading && renderShimmer()}
        <div className='extra'></div>
        <div className='extra'></div>
        <div className='extra'></div>
       
      </div>
    </div>
  );
};

export default Menucategory;
