import React, { useState, useEffect } from 'react';
import EditActivityPage from './EditActivityPage';
import { IoSearchOutline } from "react-icons/io5";
import '../MainActivities/MainActivities.css'
import AddActivity from './AddActivity.jsx';
import axios from 'axios';

const MainActivities = () => {
    const [activities, setActivities] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [editActivity, setEditActivity] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const token = localStorage.getItem('hotelToken');
    const hotelId = localStorage.getItem('hotelId');

    useEffect(() => {
        const fetchActivities = async () => {
            try {
                const response = await axios.get(process.env.REACT_APP_API_BASE_URL + `/activity/list`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        hotelId: hotelId,
                    },
                    params: {
                        tag: 'hotel',
                        page: 0,
                        size: 10
                    }
                });
                setActivities(response.data);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchActivities();
    }, [token, hotelId]);

    const initialDates = activities.reduce((acc, activity) => {
        if (Array.isArray(activity.availableDates)) {
            return [...acc, ...activity.availableDates];
        }
        return acc;
    }, []);

    const handleSaveDates = (dates) => {
        console.log('Saved dates:', dates);
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleAddActivitySubmit = (newActivity) => {
        setActivities([...activities, newActivity]);
        setShowAddModal(false);
    };

    const handleEditActivitySubmit = (updatedActivity) => {
        setActivities(prevActivities =>
            prevActivities.map(activity =>
                activity.id === updatedActivity.id ? updatedActivity : activity
            )
        );
        setShowEditModal(false); // Закрываем модальное окно после обновления
    };

    const handleDeleteActivity = (activityId) => {
        setActivities(prevActivities => prevActivities.filter(activity => activity.id !== activityId));
    };


    const toggleActivityStatus = async (activityId) => {
        try {
            const activityToUpdate = activities.find(activity => activity.id === activityId);
            const updatedAvailable = activityToUpdate.available === 1 ? 0 : 1;
            const response = await axios.put(`/activity/update/${activityId}`, { ...activityToUpdate, available: updatedAvailable }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setActivities(activities.map(activity => activity.id === activityId ? response.data : activity));
        } catch (error) {
            setError(error);
        }
    };

    const openEditModal = (activity) => {
        setEditActivity(activity);
        setShowEditModal(true);
    };

    const filteredActivities = activities.filter(activity =>
        activity.title.toLowerCase().includes(searchQuery.toLowerCase())
    );

    if (loading) {
        return <div>Loading activities...</div>;
    }

    if (error) {
        return <div>Error fetching activities: {error.message}</div>;
    }

    return (
        <div className='rooms-container'>
            <div className='room-container'>
                <div className='bar2'>
                    <h1>Activities</h1>
                    <div className="room-controls">
                        <div className='search-br1'>
                            <p><IoSearchOutline /></p>
                            <input
                                type="text"
                                placeholder="Type Activity Title"
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                        </div>
                        <button onClick={() => setShowAddModal(true)}>+</button>
                    </div>
                </div>

                <ul className="room-list">
                    {filteredActivities.map(activity => (
                        <li key={activity.id} className='room-list1' onClick={() => openEditModal(activity)}>
                            <div className="room-info">
                                <div className="room-column">
                                    <h4>{activity.title}</h4>
                                    <div
                                        className={`activity-status ${activity.available ? 'on' : 'off'}`}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            toggleActivityStatus(activity.id);
                                        }}
                                    >
                                        {activity.available ? 'ON' : 'OFF'}
                                    </div>
                                </div>
                                <div className="room-description">
                                    <p style={{ width: '100%', height: '80px' }}>{activity.description.length > 80 ?
                                        `${activity.description.substring(0, 80)}...` :
                                        activity.description
                                    }</p>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>

                {showAddModal && (
                    <AddActivity
                        onClose={() => setShowAddModal(false)}
                        onAddActivity={handleAddActivitySubmit}
                        initialDates={initialDates}
                        onSaveDates={handleSaveDates}
                    />
                )}
                {showEditModal && editActivity && (
                    <EditActivityPage
                        activity={editActivity}
                        onClose={() => setShowEditModal(false)}
                        onSaveActivity={handleEditActivitySubmit}
                        onDeleteActivity={handleDeleteActivity}
                        initialDates={initialDates}
                        onSaveDates={handleSaveDates}
                    />
                )}
            </div>
        </div>
    );
};

export default MainActivities;